import { Yup } from 'cng-web-lib'
import ValidationMessageTranslationText from './ValidationMessageTranslationText'

function useComplianceInvoiceValidationSchema() {
    const validationMessageTranslation = ValidationMessageTranslationText();

    return Yup.object({
        shippingInfoComplianceInvoices: Yup.array(
            Yup.object({
                invNo: Yup.string()
                    .required(validationMessageTranslation.requiredMessage)
                    .nullable(),
                invDate: Yup.date()
                    .required(validationMessageTranslation.requiredMessage)
                    .nullable(),
                supMfrPersonName1: Yup.string()
                    .required(validationMessageTranslation.requiredMessage)
                    .nullable(),
                totInvAmt: Yup.number()
                    .typeError(validationMessageTranslation.chargeAmountTypeError)
                    .nullable()
                    .test(
                        'bothTotInvFieldsRequired',
                        validationMessageTranslation.requiredAmountAndCurrency,
                        function (value) {
                            const totInvAmtCurrCode = this.parent.totInvAmtCurrCode
                            return (value && totInvAmtCurrCode) || (!value && !totInvAmtCurrCode)
                        }
                    ),
                totInvAmtCurrCode: Yup.string()
                    .nullable()
                    .test(
                        'bothTotInvFieldsRequired',
                        validationMessageTranslation.requiredAmountAndCurrency,
                        function (value) {
                            const totInvAmt = this.parent.totInvAmt
                            return (value && totInvAmt) || (!value && !totInvAmt)
                        }
                    ),
                frcChargeAmt: Yup.number()
                    .typeError(validationMessageTranslation.chargeAmountTypeError)
                    .nullable()
                    .test(
                        'bothFrcFieldsRequired',
                        validationMessageTranslation.requiredAmountAndCurrency,
                        function (value) {
                            const frcChargeCurrCode = this.parent.frcChargeCurrCode
                            return (value && frcChargeCurrCode) || (!value && frcChargeCurrCode) || (!value && !frcChargeCurrCode)
                        }
                    ),
                frcChargeCurrCode: Yup.string()
                    .nullable()
                    .test(
                        'bothFrcFieldsRequired',
                        validationMessageTranslation.requiredAmountAndCurrency,
                        function (value) {
                            const frcChargeAmt = this.parent.frcChargeAmt
                            const frcChargePercent = this.parent.frcChargePercent

                            // Check if both amount and currency code are required together
                            if ((frcChargeAmt && !frcChargePercent) || (!frcChargeAmt && frcChargePercent)) {
                                return value ? true : false // If either amount or percent is provided, currency code is required.
                            }

                            return true // Currency code is optional if both amount and percent are not provided.    
                        }
                    ),
                insChargeAmt: Yup.number()
                    .typeError(validationMessageTranslation.chargeAmountTypeError)
                    .nullable()
                    .test(
                        'bothInsFieldsRequired',
                        validationMessageTranslation.requiredAmountAndCurrency,
                        function (value) {
                            const insChargeCurrCode = this.parent.insChargeCurrCode
                            return (value && insChargeCurrCode) || (!value && insChargeCurrCode) || (!value && !insChargeCurrCode)
                        }
                    ),
                insChargeCurrCode: Yup.string()
                    .nullable()
                    .test(
                        'bothInsFieldsRequired',
                        validationMessageTranslation.requiredAmountAndCurrency,
                        function (value) {
                            const insChargeAmt = this.parent.insChargeAmt
                            const insChargePercent = this.parent.insChargePercent

                            // Check if both amount and currency code are required together
                            if ((insChargeAmt && !insChargePercent) || (!insChargeAmt && insChargePercent)) {
                                return value ? true : false // If either amount or percent is provided, currency code is required.
                            }

                            return true // Currency code is optional if both amount and percent are not provided.    
                        }
                    ),
                othChargeAmt: Yup.number()
                    .typeError(validationMessageTranslation.chargeAmountTypeError)
                    .nullable()
                    .test(
                        'bothOthFieldsRequired',
                        validationMessageTranslation.requiredAmountAndCurrency,
                        function (value) {
                            const othChargeCurrCode = this.parent.othChargeCurrCode
                            return (value && othChargeCurrCode) || (!value && othChargeCurrCode) || (!value && !othChargeCurrCode)
                        }
                    ),
                othChargeCurrCode: Yup.string()
                    .nullable()
                    .test(
                        'bothOthFieldsRequired',
                        validationMessageTranslation.requiredAmountAndCurrency,
                        function (value) {
                            const othChargeAmt = this.parent.othChargeAmt
                            const othChargePercent = this.parent.othChargePercent

                            // Check if both amount and currency code are required together
                            if ((othChargeAmt && !othChargePercent) || (!othChargeAmt && othChargePercent)) {
                                return value ? true : false // If either amount or percent is provided, currency code is required.
                            }

                            return true // Currency code is optional if both amount and percent are not provided.             
                        }
                    ),
                }
            )
        ),
    })
}

export default useComplianceInvoiceValidationSchema