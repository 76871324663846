import React, { useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Card } from '@material-ui/core'
import { Grid } from '@mui/material'
import { components, constants } from 'cng-web-lib'
import AccordionHeaderComponent from '../../../../common/ui/AccordionHeaderComponent'
import SCOAdminApiUrls from '../../../../../apiUrls/SCOAdminApiUrls'
import ShipmentProviderUrls from '../../../../../apiUrls/cec-admin/shipment-provider/ShipmentProviderUrls'
import { useFormContext } from 'react-hook-form'
import {
  getRenderOptionForOptionsHavingNameAndCode,
  getTextFieldPropsForOptionsHavingNameAndCode,
  preventAddingNonNumeric
} from '../util/Utils'
import AccordionFooterControlComponent from '../../../../common/ui/AccordionFooterControlComponet'


PackageAndInvoiceDetails.propTypes = {}

const {
  form: {
    field: {
      CngTextField, CngLookupAutocompleteField, CngDateField
    }
  },
  CngGridItem
} = components

const {
  filter: {
    EQUAL
  }
} = constants


function PackageAndInvoiceDetails(props) {

  const { actionType } = props
  const { getValues, watch } = useFormContext()
  const [isExpanded, setExpanded] = useState(actionType === 'add' ? true : false)

  const handleAccordionExpand = () => {
    setExpanded(!isExpanded)
  }

  return (
    <Card>


      <Accordion expanded={isExpanded}>

        <AccordionSummary
          aria-controls='panel1a-content'
          id='panel1a-header'
          style={{ backgroundColor: 'white' }}
        >

          <Grid container spacing={1} sx={{ margin: '0px' }}>

            <CngGridItem xs={12} sm={12}>
              <AccordionHeaderComponent
                title={'Package & invoice'}
                hideMandatory />
            </CngGridItem>

            <CngGridItem xs={12} sm={12}>
              <CngLookupAutocompleteField
                key={'packageType' + watch('shipmentProvider')}
                name='packageType'
                disabled={!watch('shipmentProvider')}
                label={'Package type'}
                initFetch={true}
                lookupProps={{
                  url: ShipmentProviderUrls.SHIPMENT_PROVIDERS + '/' + watch('shipmentProvider') + '/packageTypes',
                  label: 'name',
                  value: 'code'
                }}
              />
            </CngGridItem>

          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1} sx={{ margin: '0px' }}>

            <CngGridItem xs={12} sm={6}>
              <CngTextField
                name='invoiceNo'
                label={'Invoice no.'}
              />
            </CngGridItem>

            <CngGridItem xs={12} sm={6}>
              <CngDateField
                name='invoiceDate'
                label={'Invoice date'}
                format='YYYY-MM-DD'
                toServerDataFormat='YYYY-MM-DD'
              />
            </CngGridItem>

            <CngGridItem xs={12} sm={3}>
              <CngTextField
                type='number'
                name='length'
                onKeyDown={preventAddingNonNumeric()}
                label={'Length'}
              />
            </CngGridItem>

            <CngGridItem xs={12} sm={3}>
              <CngTextField
                type='number'
                name='width'
                onKeyDown={preventAddingNonNumeric()}
                label={'Width'}
              />
            </CngGridItem>

            <CngGridItem xs={12} sm={3}>
              <CngTextField
                type='number'
                name='height'
                onKeyDown={preventAddingNonNumeric()}
                label={'Height'}
              />
            </CngGridItem>

            <CngGridItem xs={12} sm={3}>
              <CngLookupAutocompleteField
                name='dimensionUOM'
                label={'Dimension UOM'}
                renderOption={getRenderOptionForOptionsHavingNameAndCode()}
                textFieldProps={getTextFieldPropsForOptionsHavingNameAndCode(getValues('dimensionUOM'))}
                initFetch={true}
                lookupProps={{
                  url: SCOAdminApiUrls.GET_CODE_MASTER_LIST,
                  label: 'name',
                  value: 'code',
                  filters: [
                    {
                      field: 'codeType',
                      operator: EQUAL,
                      value: 'COM_UOM_DIMN'
                    }
                  ]
                }}
              />
            </CngGridItem>

            <CngGridItem xs={12} sm={3}>
              <CngTextField
                type='number'
                name='weight'
                onKeyDown={preventAddingNonNumeric()}
                label={'Weight'}
              />
            </CngGridItem>

            <CngGridItem xs={12} sm={3}>
              <CngLookupAutocompleteField
                name='weightUOM'
                label={'Weight UOM'}
                initFetch={true}
                renderOption={getRenderOptionForOptionsHavingNameAndCode()}
                textFieldProps={getTextFieldPropsForOptionsHavingNameAndCode(getValues('weightUOM'))}
                lookupProps={{
                  url: SCOAdminApiUrls.GET_CODE_MASTER_LIST,
                  label: 'name',
                  value: 'code',
                  filters: [
                    {
                      field: 'codeType',
                      operator: EQUAL,
                      value: 'COM_UOM_WEGH'
                    }
                  ]
                }}
              />
            </CngGridItem>

            <CngGridItem xs={12} sm={3}>
              <CngTextField
                type='number'
                name='volWeight'
                onKeyDown={preventAddingNonNumeric()}
                label={'Volumetric weight'}
              />
            </CngGridItem>

            <CngGridItem xs={12} sm={3}>
              <CngLookupAutocompleteField
                name='volWeightUOM'
                label={'Volumetric weight UOM'}
                initFetch={true}
                renderOption={getRenderOptionForOptionsHavingNameAndCode()}
                textFieldProps={getTextFieldPropsForOptionsHavingNameAndCode(getValues('volWeightUOM'))}
                lookupProps={{
                  url: SCOAdminApiUrls.GET_CODE_MASTER_LIST,
                  label: 'name',
                  value: 'code',
                  filters: [
                    {
                      field: 'codeType',
                      operator: EQUAL,
                      value: 'COM_UOM_VOLW'
                    }
                  ]
                }}
              />
            </CngGridItem>

          </Grid>
        </AccordionDetails>

      </Accordion>

      <AccordionFooterControlComponent
        style={{ marginBottom: 20 }}
        handleAccordionExpand={handleAccordionExpand}
        expanded={isExpanded}
        footerText={'Other details'}
      />
    </Card>
  )
}

export default PackageAndInvoiceDetails
