import React from 'react'
import { FormHelperText, Grid, RadioGroup } from '@material-ui/core'
import RadioToggleButton from './RadioToggleButton'
import { components } from 'cng-web-lib'
import { Controller } from 'react-hook-form'

const {
  form: {
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    },
    field: { CngTextField }
  }
} = components

function RadioToggleButtonGroup(props) {
  const { error, gridItemProps, options, ...rest } = props
  const { control } = useFormikContext()
  return (
    <Controller
      control={control}
      name={rest.name}
      disabled={rest.disabled}
      defaultValue={rest.defaultValue}
      render={({ field: { onChange, onBlur, value } }) => (
        <>
          <RadioGroup onChange={onChange} {...rest} disabled={rest.disabled}>
            <Grid container spacing={1}>
              {options.map((option) => (
                <Grid key={rest.name + option.value} item xs={12} sm={6}  {...gridItemProps}>
                  <RadioToggleButton value={option.value} label={option.label}
                                     disabled={rest.disabled} />
                </Grid>
              ))}
            </Grid>
          </RadioGroup>
          <FormHelperText error>{error}</FormHelperText>
        </>)
      }
    />
  )
}

export default RadioToggleButtonGroup
