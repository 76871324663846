import { Card, CardContent, Grid, makeStyles, Box, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { components } from 'cng-web-lib'
import React, { useCallback, useRef, useState } from 'react'
import DeliveryOrderApiUrls from 'src/apiUrls/ecommerce/delivery-order/DeliveryOrderApiUrls'
import TranslationText from './TranslatedText'
import Utils from 'src/views/common/utils/Utils'
import TablePagination from './TablePagination'
import CustomToolbar from './CustomToolbar'

// Font Awesome imports.
import { fal as FontAwesomeLight } from '@fortawesome/pro-light-svg-icons'
import { library as FontAwesomeLibrary } from '@fortawesome/fontawesome-svg-core'

FontAwesomeLibrary.add(FontAwesomeLight)

const {
      table: {
            CngCrudTable,
            useDefaultNotification
      },
      CngMoreActionsMenu

} = components

const moreActionsList = [
      {
            action: 'download',
            name: 'Download',
            icon: ['fal', 'download']
      }
]

export const useStyles = makeStyles((theme) => ({
      table: {
            '& thead th': {
                  backgroundColor: theme.palette.component.cngMuiTable.tableHeaderCellFill,
                  textTransform: 'uppercase',
                  fontWeight: theme.typography.fontWeightBold,
                  fontSize: '.75rem',
                  color: '#8181A5',
                  lineHeight: 1.2,
                  padding: theme.spacing(1, 2),
                  '&:not(:first-child):not(:last-child)': {
                        padding: theme.spacing()
                  }
            },
            '& tbody td': {
                  fontSize: '.75rem',
                  color: theme.palette.text.gray900,
                  '&.filter': {
                        backgroundColor: theme.palette.component.cngMuiTable.tableHeaderCellFill
                  },
                  padding: theme.spacing(1, 2),
                  '&:not(:first-child):not(:last-child)': {
                        padding: theme.spacing()
                  }
            },
            '& td, & th': {
                  borderColor: theme.palette.background.ctaBtnActiveBorder
            },
            '& tbody tr:hover': {
                  backgroundColor: theme.palette.component.cngMuiTable.tableHeaderCellFill
            }
      },
      action: {
            color: theme.palette.text.textSecondary,
            borderColor: theme.palette.background.ctaBtnActiveBorder,
            borderStyle: 'solid',
            borderWidth: 1,
            borderRadius: theme.shape.borderRadius * 2,
            height: 36, width: 36,
            fontSize: '.875rem'
      },
      boldText: {
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '.875rem'
      },
      primaryText: {
            fontSize: '.875rem'
      },
      secondaryText: {
            fontSize: '.75rem',
            color: theme.palette.text.textSecondary
      },
      limitedText: {
            maxWidth: '16em',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
      },
      colorBlue: {
            color: theme.palette.primary.main
      },
      colorGreen: {
            color: theme.palette.success.main
      },
      colorRed: {
            color: theme.palette.text.error
      },
      colorOrange: {
            color: theme.palette.warning.main
      }

}))

function LabelPage(props) {
      const {
            showNotification,
      } = props

      const classes = useStyles()
      const translatedTextObject = TranslationText()
      const notification = useDefaultNotification(showNotification)

      const tableRef = useRef()
      const inputRef = useRef()

      const [pageSize] = useState(5)
      const [searchText, setSearchText] = useState('')


      const tableRefStateCallback = useCallback(() => {
            return tableRef.current?.state
      }, [])

      const onApplyClicked = () => {
            tableRef.current.onQueryChange({
                  filters: [
                        {
                              column: {
                                    field: 'fileName',
                                    type: 'AND' // not used, but required, can just put a random type first
                              },
                              value: searchText
                        }
                  ],
                  page: 0
            })
            return (false);
      }

      const downloadUploadedFile = (row) => {
            let blob = new Blob(base64toBlob(row.fileContent), {
                  type: 'application/zip'
            })
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = row.fileName
            link.click()
      }

      function base64toBlob(base64Data) {
            let sliceSize = 1024
            let byteCharacters = atob(base64Data)
            let bytesLength = byteCharacters.length
            let slicesCount = Math.ceil(bytesLength / sliceSize)
            let byteArrays = new Array(slicesCount)

            for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
                  let begin = sliceIndex * sliceSize
                  let end = Math.min(begin + sliceSize, bytesLength)

                  let bytes = new Array(end - begin)
                  for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
                        bytes[i] = byteCharacters[offset].charCodeAt(0)
                  }
                  byteArrays[sliceIndex] = new Uint8Array(bytes)
            }
            return byteArrays
      }

      const columns = [
            {
                  field: "fileName",
                  title: translatedTextObject.filename + ' / ' + translatedTextObject.lastMileProvider,
                  filtering: false,
                  render: rowData =>
                        <Box>
                              <Typography
                                    className={clsx(classes.boldText, classes.limitedText)}>{rowData.fileName}</Typography>
                              <Typography className={clsx(classes.secondaryText, classes.limitedText)}>{rowData.shipmentProvider}</Typography>
                        </Box>
            },
            {
                  field: "createdDate",
                  title: translatedTextObject.createdOn,
                  type: "date",
                  defaultSort: 'desc',
                  sorting: true,
                  filtering: false,
                  render: rowData => Utils.formatDate(rowData.createdDate, 'DD MMM YYYY , HH:mm', ' ')

            },
            {
                  field: "noOfTransactions",
                  title: translatedTextObject.noOfDO,
                  filtering: false
            },
            {
                  field: "additionalInfo",
                  title: translatedTextObject.systemResponse,
                  filtering: false,
                  width: '25%'
            },
            {
                  field: "labelStatus",
                  title: translatedTextObject.status,
                  filtering: false,
                  render: (rowData) => {
                        let cssClassName = ''

                        switch (rowData.labelStatus) {
                              case 'In Progress':
                                    cssClassName = 'colorBlue'
                                    break
                              case 'Failed':
                                    cssClassName = 'colorRed'
                                    break
                              case 'Success':
                                    cssClassName = 'colorGreen'
                                    break
                              case 'Partially Successful':
                                    cssClassName = 'colorOrange'
                                    break
                        }
                        return <Typography
                              className={clsx(classes.boldText, classes[cssClassName])}>{rowData.labelStatus}</Typography>
                  }
            }
            ,
            {
                  title: translatedTextObject.action, field: 'id',
                  sorting: false,
                  filtering: false,
                  render: rowData => (
                        (rowData.labelStatus === 'Success' || rowData.labelStatus === 'Partially Successful') ?
                              <CngMoreActionsMenu
                                    type='secondary'
                                    className={classes.action}
                                    menuItems={moreActionsList}
                                    iconDirection='horizontal'
                                    data={rowData}
                                    onActionSelect={downloadUploadedFile}

                              />
                              :
                              <></>
                  )
            }
      ]

      let bottomPaginationBar = (props) => (
            <>
                  <Box mt={2}>
                        <TablePagination  {...props} />
                  </Box>
            </>
      )
      let customToolBar = (props) => (
            <>
                  <CustomToolbar
                        inputRef={inputRef}
                        page={tableRefStateCallback()?.query?.page || 0}
                        pageSize={tableRefStateCallback()?.pageSize || 0}
                        totalResult={tableRefStateCallback()?.query?.totalCount || 0}
                        placeholderText={translatedTextObject.searchFileMessage}
                        searchText={searchText}
                        setSearchText={setSearchText}
                        onApplyClicked={onApplyClicked}
                  />

            </>
      )


      return (
            <Grid>
                  <Grid item xs={12} sm={12}>
                        <Box component={Card} className={classes.table}>
                              <CngCrudTable
                                    {...props}
                                    fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
                                    columns={columns}
                                    pageSize={pageSize}
                                    components={{
                                          Pagination: bottomPaginationBar
                                    }}
                                    fetch={{
                                          url: DeliveryOrderApiUrls.GET_MASS_PRINT
                                    }}
                                    idAccessor="id"
                                    notification={notification}
                                    tableRef={(ref) => {
                                          if (ref === null) {
                                                return
                                          }
                                          tableRef.current = ref
                                    }}
                                    customToolbar={customToolBar}
                              />
                        </Box>
                  </Grid>
            </Grid>
      )


}

export default LabelPage