import React from 'react'
import PropTypes from 'prop-types'
import { Box, Card, CardContent, Chip, Typography } from '@material-ui/core'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineDot from '@material-ui/lab/TimelineDot'
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import Moment from 'moment'
import { components } from 'cng-web-lib'


EventDetails.propTypes = {
  deliveryEvents: PropTypes.array
}

const {
  CngGridItem
} = components

function EventDetails(props) {

  const { deliveryEvents } = props

  return (

    <CngGridItem xs={12} sm={12}>
      <Card>
        <CardContent>
          <Typography className={'boldLabel'} style={{ fontSize: 16 }}>
            Delivery event
          </Typography>

          <Timeline position='alternate'>
            {Object.entries(deliveryEvents.reverse()).map(([key, value]) => {
              return (<TimelineItem key={value.uuid}>
                <TimelineOppositeContent style={{ fontSize: 14 }}>
                  <b>{value.eventLocation ? value.eventLocation.toUpperCase() : null}</b>
                  <br />
                  <b>{value.detailedEventDescription ? value.detailedEventDescription : null}</b>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                  {deliveryEvents.length - 1 != key ? <TimelineConnector /> : null}
                </TimelineSeparator>
                <TimelineContent style={{ fontSize: 14 }}>
                  {value.eventDescription ? <Chip label={value.eventDescription} /> : null}
                  <br />
                  <AccessTimeIcon
                    style={{ fontSize: 12 }} /> {(value.eventTimestamp) ? Moment(value.eventTimestamp.substring(0, 19)).format('DD MMM YYYY , HH:mm') + ' ( ' + value.eventTimestamp.substring(19) + ' ) ' : null}
                </TimelineContent>
              </TimelineItem>)
            })}
          </Timeline>

          {!(deliveryEvents && deliveryEvents.length > 0) ?
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              minHeight='10vh'
            >
              <Typography className={'greyLabel'} style={{ fontSize: 12 }}>
                {'No events to display'}
              </Typography>
            </Box> : null}


        </CardContent>
      </Card>
    </CngGridItem>
  )
}

export default EventDetails
