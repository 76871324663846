import React from 'react'
import {
      Grid, TextField, Box, Typography, makeStyles, IconButton, InputAdornment
} from '@material-ui/core'
// Font Awesome imports.
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fal as FontAwesomeLight } from '@fortawesome/pro-light-svg-icons'
import { library as FontAwesomeLibrary } from '@fortawesome/fontawesome-svg-core'

FontAwesomeLibrary.add(FontAwesomeLight)


export const useStyles = makeStyles((theme) => ({

      searchBar: {
            position: 'relative',
            marginRight: '1em',
            padding: '1px !important',
            '& .Mui-focused': {
                  border: '1px #5E81F4 solid !important',
                  backgroundColor: '#5e81f400 !important',
                  borderRadius: theme.shape.borderRadius
            },
            '& .MuiFormControl-root': {
                  border: `1px solid ${theme.palette.color.lightGrey}`,
                  borderRadius: theme.shape.borderRadius,
                  width: '-webkit-fill-available'
            },
            '& > *:nth-child(1)': {
                  '& .MuiInputBase-root': {
                        flexGrow: 1,
                        height: '100%',
                        backgroundColor: 'transparent !important',
                        '& > input.MuiInputBase-input': {
                              padding: theme.spacing(1, 2) + ' !important',
                        }
                  }

            }

      }
}))

function CustomToolbar({
      inputRef,
      page,
      pageSize,
      totalResult,
      placeholderText,
      searchText,
      setSearchText,
      onApplyClicked = (event) => { /* Do nothing */ }
}) {
      const classes = useStyles()

      return (
            <Box display='flex' alignItems='center' style={{ height: '20px' }}>
                  <Box pl='18px'>
                        <Typography variant='body2'>{`${Math.min(
                              page * pageSize + 1,
                              totalResult
                        )}-${Math.min(
                              (page + 1) * pageSize,
                              totalResult
                        )} of ${totalResult}`}</Typography>
                  </Box>
                  <div className={'flex-grow'}></div>
                  <Box flexDirection='row-reverse' flexGrow={0.5}>
                        <Grid container spacing={1} alignItems='center' wrap='nowrap'>
                              <Grid item xs={12} className={classes.searchBar}>
                                    <TextField
                                          inputRef={inputRef}
                                          autoFocus={inputRef.current === document.activeElement}
                                          InputProps={{
                                                disableUnderline: true,
                                                endAdornment:
                                                      <InputAdornment position="end">
                                                            <IconButton
                                                                  aria-label="search"
                                                                  color="primary"
                                                                  onClick={(event) => {
                                                                        onApplyClicked()
                                                                  }}
                                                            >
                                                                  <FontAwesomeIcon icon={['fal', 'search']}
                                                                        size="xs"
                                                                  />
                                                            </IconButton>
                                                      </InputAdornment>
                                          }}
                                          placeholder={placeholderText}
                                          value={searchText}
                                          onKeyDown={(event) => {
                                                if (event.key === "Enter") {
                                                      onApplyClicked()
                                                }
                                          }}
                                          onChange={(event) => {
                                                let value = event.target.value
                                                setSearchText(value)
                                          }}
                                    />
                              </Grid>

                        </Grid>
                  </Box>
            </Box>
      )

      //* End of function --------------------------------------------------------
}

export default CustomToolbar
