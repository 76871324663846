import React from 'react'
import { components, constants } from 'cng-web-lib'
import ValidationSchema from './ValidationSchemaForItems'
import { Box, Card, Grid } from '@material-ui/core'
import {
  getRenderOptionForOptionsHavingNameAndCode,
  getTextFieldPropsForOptionsHavingNameAndCode,
  preventAddingNonNumeric
} from '../../../util/Utils'
import SCOAdminApiUrls from '../../../../../../../apiUrls/SCOAdminApiUrls'
import { useFormContext } from 'react-hook-form'


const {
  filter: {
    EQUAL
  }
} = constants

const {
  form: {
    field: {
      CngTextField, CngLookupAutocompleteField
    }
  },
  CngGridItem, button: { CngPrimaryButton, CngSecondaryButton }
} = components

function Fields({ ...props }) {

  const { closeDialog, deliveryOrderItems, itemData, deliveryOrderActionType } = props
  const { getValues, setError, clearErrors } = useFormContext()

  const onBlurOfRefNo = (event) => {
    clearErrors('refNo')
    const newValue = event.target.value
    if (newValue.trim() !== '') {
      if (itemData) {
        // edit item
        if ([...deliveryOrderItems.filter(item => (item.refNo !== itemData.refNo && (item.delete === undefined || item.delete === false))).map(item => item.refNo)].filter(refNo => refNo === newValue).length > 1) {
          setError('refNo', { type: 'manual', message: 'Duplicated Item Ref No', shouldFocus: true })
        }
      } else {
        // add item
        if (deliveryOrderItems.filter(item => (item.refNo === newValue && (item.delete === undefined || item.delete === false))).length > 0) {
          setError('refNo', { type: 'manual', message: 'Duplicated Item Ref No', shouldFocus: true })
        }
      }
    } else {
      setError('refNo', { type: 'manual', message: 'Required/Mandatory', shouldFocus: true })
    }
  }

  return (
    <Grid container xs={12} sm={12} spacing={3}>
      <CngGridItem xs={12} sm={12}>
        <Card style={{ margin: '1rem' }}>
          <Box className='view-card-header-no-bg' style={{ padding: '-1rem !important' }}>
            <Grid container xs={12} sm={12}>
              <CngGridItem xs={12} sm={4}>
                {'Product Information'}
              </CngGridItem>
            </Grid>
          </Box>
          <Box>
            <Grid container xs={12} sm={12}>
              <CngGridItem xs={12} sm={4} />
              <CngGridItem xs={12} sm={8}>
                <Grid container xs={12} sm={12} spacing={2}>
                  <CngGridItem xs={12} sm={12}>
                    <CngTextField
                      isRequired
                      name='refNo'
                      label={'Item ref. no.'}
                      inputProps={{
                        onBlur: onBlurOfRefNo
                      }}
                      disabled={deliveryOrderActionType === 'edit' && itemData != null}
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={12}>
                    <CngTextField
                      name='productName'
                      label={'Product name / description'}
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6}>
                    <CngTextField
                      type='number'
                      name='quantity'
                      onKeyDown={preventAddingNonNumeric()}
                      label={'Quantity'}
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6}>
                    <CngLookupAutocompleteField
                      name='uom'
                      label={'Quantity UOM'}
                      renderOption={getRenderOptionForOptionsHavingNameAndCode()}
                      textFieldProps={getTextFieldPropsForOptionsHavingNameAndCode(getValues('uom'))}
                      initFetch={true}
                      lookupProps={{
                        url: SCOAdminApiUrls.GET_CODE_MASTER_LIST,
                        label: 'name',
                        value: 'code',
                        filters: [
                          {
                            field: 'codeType',
                            operator: EQUAL,
                            value: 'COM_UOM_ITEM'
                          }
                        ]
                      }}
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6}>
                    <CngTextField
                      type='number'
                      name='price'
                      onKeyDown={preventAddingNonNumeric()}
                      label={'Unit Price'}
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6}>
                    <CngTextField
                      type='number'
                      name='declaredPrice'
                      onKeyDown={preventAddingNonNumeric()}
                      label={'Declared price'}
                    />
                  </CngGridItem>
                </Grid>
              </CngGridItem>

            </Grid>
          </Box>
          <Box className='view-card-header-no-bg' style={{ padding: '-1rem !important' }}>
            <Grid container xs={12} sm={12}>
              <CngGridItem xs={12} sm={12}>
                <hr style={{ borderTop: '1px solid #f4f4fc' }} />
                {'Other Information'}
              </CngGridItem>
            </Grid>
          </Box>
          <Box>
            <Grid container xs={12} sm={12} spacing={2}>
              <CngGridItem xs={12} sm={4} />
              <CngGridItem xs={12} sm={8}>
                <Grid container xs={12} sm={12} spacing={2}>
                  <CngGridItem xs={12} sm={12}>
                    <CngTextField
                      name='salesOrderItemNo'
                      label={'Sales order item no.'}
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={12}>
                    <CngTextField
                      name='internationalHSCode'
                      label={'HS code ( 6 digits)'}
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6}>
                    <CngTextField
                      name='exportHSCode'
                      label={'Destination HS code'}
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6}>
                    <CngTextField
                      name='exportCAProductCode'
                      label={'Destination CA product code'}
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6}>
                    <CngTextField
                      name='importHSCode'
                      label={'Origin HS code'}
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6}>
                    <CngTextField
                      name='importCAProductCode'
                      label={'Origin CA product code'}
                    />
                  </CngGridItem>
                </Grid>
              </CngGridItem>
              <CngGridItem xs={12} sm={12} />
            </Grid>
          </Box>
        </Card>
      </CngGridItem>
      <Grid container xs={12} sm={12} style={{ textAlign: 'right' }} spacing={3}>
        <CngGridItem xs={12} sm={12} style={{ textAlign: 'right', marginBottom: '2rem' }}>
          <hr style={{ borderTop: '1px solid #f4f4fc', width: '100%', marginLeft: '1rem' }} />
          <CngSecondaryButton
            onClick={closeDialog}>
            Discard
          </CngSecondaryButton>
          <CngPrimaryButton
            style={{ marginLeft: '1rem' }}
            type={'submit'}
          >
            Save
          </CngPrimaryButton>
        </CngGridItem>
      </Grid>
    </Grid>
  )

}


const DEFAULT_INITIAL_VALUES = {
  refNo: '',
  productName: '',
  quantity: '',
  uom: '',
  price: '',
  declaredPrice: '',
  salesOrderItemNo: '',
  internationalHSCode: '',
  exportHSCode: '',
  exportCAProductCode: '',
  importHSCode: '',
  importCAProductCode: ''
}

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: ValidationSchema
}

const ItemFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})


export default ItemFormProperties
