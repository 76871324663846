import React from 'react'
import MainOrderDetails from '../view/MainOrderDetails'
import PackageAndInvoiceDetails from '../view/PackageAndInvoiceDetails'
import { Grid } from '@mui/material'
import ItemsDetails from '../view/ItemsDetails'
import AddressDetails from '../view/AddressDetails'

ViewDetailedPanel.propTypes = {}

function ViewDetailedPanel(props) {

  const { deliveryOrder, salesOrderCurrency, partyTemplates } = props

  return (

    <Grid container spacing={2}>

      <MainOrderDetails deliveryOrder={deliveryOrder} showErrorNotification={null}
                        salesOrderCurrency={salesOrderCurrency} actionType={'add'} />

      <PackageAndInvoiceDetails showNotification={null} invoice={deliveryOrder.invoice}
                                packageDetails={deliveryOrder.packages} />

      <AddressDetails deliveryPartyType={'shipper'} address={deliveryOrder.shipper}
                      template={partyTemplates.shipper.code}
                      showErrorNotification={null} />

      <AddressDetails deliveryPartyType={'consignee'} address={deliveryOrder.consignee}
                      showErrorNotification={null} />

      <ItemsDetails
        showErrorNotification={null}
        deliveryOrderItems={(deliveryOrder.packages && deliveryOrder.packages.item) ? deliveryOrder.packages.item : []}
        salesOrderCurrency={salesOrderCurrency} />

    </Grid>

  )
}

export default ViewDetailedPanel
