import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import { Accordion, AccordionDetails, AccordionSummary, Card } from '@material-ui/core'
import { components, constants } from 'cng-web-lib'
import AccordionHeaderComponent from '../../../../common/ui/AccordionHeaderComponent'
import ShipmentProviderUrls from '../../../../../apiUrls/cec-admin/shipment-provider/ShipmentProviderUrls'
import SCORateMgmtApiUrls from '../../../../../apiUrls/SCORateMgmtApiUrls'
import SCOAdminApiUrls from '../../../../../apiUrls/SCOAdminApiUrls'
import CountrySelectAutoComplete from '../../../../common/ui/CountrySelectAutoComplete'
import { useFormContext } from 'react-hook-form'
import {
  getRenderOptionForOptionsHavingNameAndCode,
  getTextFieldPropsForOptionsHavingNameAndCode,
  preventAddingNonNumeric
} from '../util/Utils'
import Autocomplete from '@material-ui/lab/Autocomplete'
import AccordionFooterControlComponent from '../../../../common/ui/AccordionFooterControlComponet'

MainOrderDetails.propTypes = {
  deliveryOrder: PropTypes.object,
  showNotification: PropTypes.func
}

const {
  form: {
    field: {
      CngTextField, CngLookupAutocompleteField
    }
  },
  CngGridItem
} = components

const {
  filter: {
    EQUAL
  }, NotificationType
} = constants

function MainOrderDetails(props) {

  const { actionType, fetchCurrencyDetails, showNotification } = props
  const { getValues, setValue, setFocus, watch } = useFormContext()
  const [otherRefValue, setOtherRefValue] = useState(watch('otherRef') || [])
  const [isExpanded, setExpanded] = useState(actionType === 'add' ? true : false)

  useEffect(() => {
    if (actionType === 'add') {
      setFocus('deliveryOrderRefNo')
    }
  }, [])


  useEffect(() => {
    setValue('otherRef', otherRefValue)
  }, [otherRefValue])


  function addNewOtherRef(event) {
    const newValue = event.target.value.trim()
    if (otherRefValue.length < 5 && event.target.value.length > 0) {
      let arrayValue = []
      if (event.target.value.length > 0) {
        arrayValue = [...otherRefValue, newValue]
        setOtherRefValue(arrayValue)
      }
      setValue('otherRef', arrayValue)
    } else {
      if (newValue) {
        showNotification(NotificationType.ERROR, 'You can only store up to 5 other reference numbers.')
      }
    }

  }

  const handleKeyDownForOtherRef = event => {
    switch (event.key) {
      case 'Enter':
      case ',': {
        event.preventDefault()
        event.stopPropagation()
        addNewOtherRef(event)
        break
      }
      default:
    }
  }

  const handleOnBlurForOtherRef = event => {
    addNewOtherRef(event)
  }

  const handleAccordionExpand = () => {
    setExpanded(!isExpanded)
  }


  const onOtherRefChange = (_event, newValue) => {
    setOtherRefValue(newValue)
  }

  return (
    <Card>

      <Accordion expanded={isExpanded}>

        <AccordionSummary
          aria-controls='panel1a-content'
          id='panel1a-header'
          style={{ backgroundColor: 'white' }}
        >

          <Grid container spacing={1} sx={{ margin: '0px' }}>

            <CngGridItem xs={12} sm={12}>
              <AccordionHeaderComponent
                title={'Order'} />
            </CngGridItem>
            <CngGridItem xs={12} sm={3}>
              <CngTextField
                isRequired
                disabled={actionType !== 'add'}
                name='deliveryOrderRefNo'
                label={'Delivery order ref. no.'}
              />
            </CngGridItem>

            <CngGridItem xs={12} sm={3}>
              <CngLookupAutocompleteField
                initFetch={true}
                name='shipmentProvider'
                label={'Last mile provider'}
                lookupProps={{
                  url: ShipmentProviderUrls.GET_FOR_USER_PARTY,
                  label: 'name',
                  value: 'name'
                }}
                onChange={(value) => {
                  setValue('packageType', '')
                }}
              />
            </CngGridItem>

            <CngGridItem xs={12} sm={3}>
              <CngLookupAutocompleteField
                name='currency'
                label={'Total shipment currency'}
                initFetch={true}
                lookupProps={{
                  url: SCORateMgmtApiUrls.GET_CURRENCY,
                  label: 'name',
                  value: 'code',
                  filters: [
                    {
                      field: 'status',
                      operator: EQUAL,
                      value: true
                    }
                  ]
                }}
                onChange={(value) => {
                  fetchCurrencyDetails(value)
                }}
                renderOption={getRenderOptionForOptionsHavingNameAndCode()}
                textFieldProps={getTextFieldPropsForOptionsHavingNameAndCode(getValues('currency'))}
              >
              </CngLookupAutocompleteField>
            </CngGridItem>

            <CngGridItem xs={12} sm={3}>
              <CngLookupAutocompleteField
                name='deliveryOrderStatus'
                label={'Delivery Order Status'}
                isRequired
                initFetch={true}
                disabled={actionType === 'add'}
                lookupProps={{
                  url: SCOAdminApiUrls.GET_CODE_MASTER_LIST,
                  label: 'name',
                  value: 'name',
                  filters: [
                    {
                      field: 'codeType',
                      operator: EQUAL,
                      value: 'CEC_DELIVERY_ORDER_STATUS'
                    }
                  ]
                }}
              >
              </CngLookupAutocompleteField>
            </CngGridItem>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1} sx={{ margin: '0px' }}>
            <CngGridItem xs={12} sm={3}>
              <CngTextField
                type='number'
                name='grandTotal'
                onKeyDown={preventAddingNonNumeric()}
                label={'Total shipment value'}
              />
            </CngGridItem>

            <CngGridItem xs={12} sm={3}>
              <CngTextField
                type='number'
                name='declaredAmount'
                onKeyDown={preventAddingNonNumeric()}
                label={'Declared amount'}
              />
            </CngGridItem>

            <CngGridItem xs={12} sm={3}>
              <CngTextField
                name='trackingNo'
                label={'Tracking no.'}
              />
            </CngGridItem>

            <CngGridItem xs={12} sm={3}>
              <CngTextField
                name='salesOrderNo'
                label={'Sales order no.'}
              />
            </CngGridItem>

            <CngGridItem xs={12} sm={12}>
              <Autocomplete
                name='otherRef'
                multiple
                freeSolo
                ChipProps={{ variant: 'outlined', size: 'small' }}
                options={[]}
                value={otherRefValue}
                onChange={onOtherRefChange}
                renderInput={params => {
                  params.inputProps.onKeyDown = handleKeyDownForOtherRef
                  params.inputProps.onBlur = handleOnBlurForOtherRef
                  return (
                    <CngTextField
                      {...params}
                      label='Other reference nos. ( maximum of 5 items )'
                      margin='normal'
                      fullWidth
                    />
                  )
                }}
              />
            </CngGridItem>

            <CngGridItem xs={12} sm={6}>
              <CountrySelectAutoComplete
                name='originCountry'
                label={'Country origin'}
              />
            </CngGridItem>

            <CngGridItem xs={12} sm={6}>
              <CountrySelectAutoComplete
                name='destinationCountry'
                label={'Country destination'}
              />
            </CngGridItem>
          </Grid>
        </AccordionDetails>

      </Accordion>

      <AccordionFooterControlComponent
        style={{ marginBottom: 20 }}
        handleAccordionExpand={handleAccordionExpand}
        expanded={isExpanded}
        footerText={'Other details'}
      />
    </Card>
  )
}

export default MainOrderDetails
