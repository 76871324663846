import React from 'react'
import PropTypes from 'prop-types'
import { Card, CardContent, Chip, Typography } from '@material-ui/core'
import { Grid } from '@mui/material'
import { components, constants } from 'cng-web-lib'
import EmptyBox from './EmptyBox'
import CountryLabel from './CountryLabel'

MainOrderDetails.propTypes = {
  deliveryOrder: PropTypes.object,
  showErrorNotification: PropTypes.func
}

const {
  filter: { EQUAL }
} = constants

const {
  CngGridItem
} = components

function MainOrderDetails(props) {

  const { deliveryOrder, showErrorNotification, salesOrderCurrency, actionType } = props
  const currencySymbolPrefix = salesOrderCurrency && salesOrderCurrency.symbol ? salesOrderCurrency.symbol + ' ' : ''

  return (
    <Grid item xs={12}>
      <Card>
        <CardContent>

          <Typography className={'boldLabel'} style={{ fontSize: 16, marginBottom: '1rem' }}>
            Order
          </Typography>

          <Grid container spacing={1}>

            <CngGridItem xs={12} sm={3}>
              <Typography variant='caption' color='textSecondary'>Delivery order ref. no.</Typography>
              <Typography variant='body2' noWrap={true}>{deliveryOrder.deliveryOrderRefNo}</Typography>
            </CngGridItem>

            <CngGridItem xs={12} sm={3}>
              <Typography variant='caption' color='textSecondary'>Last mile provider</Typography>
              <Typography variant='body2'
                          noWrap={true}>{deliveryOrder.shipmentProvider ? deliveryOrder.shipmentProvider :
                <EmptyBox />}</Typography>
            </CngGridItem>

            <CngGridItem xs={12} sm={3}>
              <Typography variant='caption' color='textSecondary'>Total shipment currency</Typography>
              <Typography variant='body2'>
                {salesOrderCurrency ?
                  <> {salesOrderCurrency.name}
                    <Chip label={salesOrderCurrency.code} size='small' variant='outlined'
                          style={{ marginLeft: '0.5rem' }} />
                  </> : <EmptyBox />}
              </Typography>
            </CngGridItem>

            <CngGridItem xs={12} sm={3} shouldHide={actionType === 'view'}>
              <Typography variant='caption' color='textSecondary'>Delivery order status</Typography>
              <Typography variant='body2'>{deliveryOrder.deliveryOrderStatus}</Typography>
            </CngGridItem>

            <CngGridItem xs={12} sm={3}>
              <Typography variant='caption' color='textSecondary'>Total shipment value</Typography>
              <Typography
                variant='body2'> {deliveryOrder.salesOrder.grandTotal ? currencySymbolPrefix + Number(deliveryOrder.salesOrder.grandTotal).toFixed(2) :
                <EmptyBox />}</Typography>
            </CngGridItem>

            <CngGridItem xs={12} sm={3}>
              <Typography variant='caption' color='textSecondary'>Declared amount</Typography>
              <Typography
                variant='body2'>{deliveryOrder.salesOrder.declaredAmount ? currencySymbolPrefix + Number(deliveryOrder.salesOrder.declaredAmount).toFixed(2) :
                <EmptyBox />}</Typography>
            </CngGridItem>

            <CngGridItem xs={12} sm={3}>
              <Typography variant='caption' color='textSecondary'>Tracking no.</Typography>
              <Typography variant='body2'
                          noWrap={true}>{deliveryOrder.trackingNo ? deliveryOrder.trackingNo :
                <EmptyBox />}</Typography>
            </CngGridItem>

            <CngGridItem xs={12} sm={3}>
              <Typography variant='caption' color='textSecondary'>Sales order no.</Typography>
              <Typography
                variant='body2'
                noWrap={true}>{deliveryOrder.salesOrder.salesOrderNo ? deliveryOrder.salesOrder.salesOrderNo :
                <EmptyBox />}</Typography>
            </CngGridItem>

            <CngGridItem xs={12} sm={12}>
              <Typography variant='caption' color='textSecondary'>Other reference nos. (maximum of 5
                items)</Typography>
              <Typography
                variant='body2'> {deliveryOrder.otherRef ? deliveryOrder.otherRef.map((ref, index) =>
                <Chip
                  label={ref} key={ref}
                  size='small'
                  style={{ marginRight: '1rem' }} />) : <EmptyBox />}</Typography>
            </CngGridItem>

            <CngGridItem xs={12} sm={3}>
              <Typography variant='caption' color='textSecondary'>Country origin</Typography>
              <Typography variant='body2'><CountryLabel countryCode={deliveryOrder.originCountry}
                                                        showErrorNotification={showErrorNotification} /></Typography>
            </CngGridItem>

            <CngGridItem xs={12} sm={3}>
              <Typography variant='caption' color='textSecondary'>Country destination</Typography>
              <Typography variant='body2'><CountryLabel countryCode={deliveryOrder.destinationCountry}
                                                        showErrorNotification={showErrorNotification} /></Typography>
            </CngGridItem>

          </Grid>

        </CardContent>
      </Card>
    </Grid>
  )
}

export default MainOrderDetails
