import React from 'react'
import PropTypes from 'prop-types'
import { Button, Paper, Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Stack from '@mui/material/Stack'
import { components } from 'cng-web-lib'
import { useHistory } from 'react-router-dom'
import pathMap from '../../../../../paths/PathMap_eCommerce'


DiscardDialog.propTypes = {
  isDialogOpen: PropTypes.bool,
  closeDialog: PropTypes.func
}

const { CngDialog } = components

function DiscardDialog(props) {

  const history = useHistory()
  const {
    isDialogOpen,
    closeDialog
  } = props

  return (
    <CngDialog
      customDialogContent={
        <Paper style={{ borderRadius: '0px' }}>
          <Box m={2} mt={1}>
            <Box mt={0.5}>
              <Typography variant='body2' style={{ maxHeight: '60vh', overflow: 'auto' }}>All progress in this session
                will be lost and can't be restored.
                Are you sure about this?</Typography>
            </Box>
            <Box display='flex' flexDirection='row-reverse' mt={2}>
              <Stack direction='row' spacing={2}>
                <Button size='small' color='primary' onClick={closeDialog} className='originalText'>No,
                  take me
                  back</Button>
                <Button
                  variant='contained'
                  className='button-blue originalText'
                  onClick={() => {
                    history.push(pathMap.CEC_DELIVERY_ORDER_LIST_VIEW)
                    closeDialog()
                  }}
                  type='button'
                  style={{ borderRadius: '8px' }}
                >Yes, discard</Button>
              </Stack>
              <Box flexGrow={1}></Box>
            </Box>
          </Box>
        </Paper>
      }
      dialogTitle={
        <>
          {'Discard'}
        </>
      }
      open={isDialogOpen}
    />
  )
}

export default DiscardDialog
