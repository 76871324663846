import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Button, IconButton, makeStyles, Typography } from '@material-ui/core'
import { Grid } from '@mui/material'
import clsx from 'clsx'
import MaterialTable, { MTableToolbar } from 'material-table'
import SCOAdminApiUrls from '../../../../../../apiUrls/SCOAdminApiUrls'
import { components, constants, useServices } from 'cng-web-lib'
import ViewItemDialog from '../../view/ViewItemDialog'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import AddEditItemDialog from './dialogs/AddEditItemDialog'
import { useFormContext } from 'react-hook-form'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined'
import DeleteItemDialog from './dialogs/DeleteItemDialog'
import { tableIcons } from '../../util/Utils'
import _ from 'lodash'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import ClearIcon from '@material-ui/icons/Clear'

ItemsDetails.propTypes = {
  deliveryOrderItems: PropTypes.array, showNotification: PropTypes.func
}

const {
  filter: { EQUAL }
} = constants


const {
  button: { CngSecondaryButton }, table: { useDefaultNotification }
} = components


export const useDeliveryOrderStyles = makeStyles((theme) => ({
  table: {
    '& thead th': {
      backgroundColor: theme.palette.component.cngMuiTable.tableHeaderCellFill,
      textTransform: 'uppercase',
      fontWeight: theme.typography.fontWeightBold,
      fontSize: '.75rem',
      color: '#8181A5',
      lineHeight: 1.2,
      padding: theme.spacing(1, 2),
      '&:not(:first-child):not(:last-child)': {
        padding: theme.spacing()
      }
    }, '& tbody td': {
      fontSize: '.75rem', color: theme.palette.text.gray900, '&.filter': {
        backgroundColor: theme.palette.component.cngMuiTable.tableHeaderCellFill
      }, padding: theme.spacing(1, 2), '&:not(:first-child):not(:last-child)': {
        padding: theme.spacing()
      }
    }, '& td, & th': {
      borderColor: theme.palette.background.ctaBtnActiveBorder
    }, '& tbody tr:hover': {
      backgroundColor: theme.palette.component.cngMuiTable.tableHeaderCellFill, padding: theme.spacing(1, 2)
    }
  }, limitedText: {
    maxWidth: '16em', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'
  }, action: {
    color: theme.palette.text.textSecondary,
    borderColor: theme.palette.background.ctaBtnActiveBorder,
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: theme.shape.borderRadius * 2,
    height: 36,
    width: 36,
    fontSize: '.875rem'
  }, boldText: {
    fontWeight: theme.typography.fontWeightBold, fontSize: '.875rem'
  }, primaryText: {
    fontSize: '.875rem'
  }, secondaryText: {
    fontSize: '.75rem', color: theme.palette.text.textSecondary
  }, colorBlue: {
    color: theme.palette.primary.main
  }, colorGreen: {
    color: theme.palette.success.main
  }, colorOrange: {
    color: '#C48E1F'
  }, startIcon: {
    margin: '0px !important', color: '#8e98b0 !important'
  }
}))

function ItemsDetails(props) {

  const { deliveryOrderItems, setDeliveryOrderItems, showNotification, salesOrderCurrency, actionType } = props
  const {
    error: showErrorNotification
  } = useDefaultNotification(showNotification)
  const currencySymbolPrefix = salesOrderCurrency && salesOrderCurrency.symbol ? salesOrderCurrency.symbol + ' ' : ''
  const classes = useDeliveryOrderStyles()
  const tableRef = useRef()
  const { fetchRecords } = useServices()
  const [itemUOMs, setItemUOMs] = useState([])
  const { setValue } = useFormContext()

  const [isViewDialogOpened, setViewDialogOpened] = useState(false)
  const [itemForView, setItemForView] = useState()

  const [isAddEditDialogOpened, setAddEditDialogOpened] = useState(false)
  const [itemForEdit, setItemToEdit] = useState()

  const [isDeleteDialogOpened, setDeleteDialogOpened] = useState(false)
  const [itemsToDelete, setItemsToDelete] = useState()

  const clearSelection = () => {
    if (tableRef.current) {
      tableRef.current.onAllSelected(false)
    }
  }

  const openDeleteDialog = () => {
    setDeleteDialogOpened(true)
  }
  const closeDeleteDialog = () => {
    setDeleteDialogOpened(false)
  }

  const openViewDialog = () => {
    setViewDialogOpened(true)
  }
  const closeViewDialog = () => {
    setViewDialogOpened(false)
  }

  const openAddEditDialog = () => {
    setAddEditDialogOpened(true)
  }
  const closeAddEditDialog = () => {
    setAddEditDialogOpened(false)
  }

  useEffect(() => {
    setValue('item', deliveryOrderItems)
  }, [deliveryOrderItems])


  useEffect(() => {
    fetchRecords.execute(SCOAdminApiUrls.GET_CODE_MASTER_LIST, {
      filters: [{
        field: 'codeType', operatorType: EQUAL, value: 'COM_UOM_ITEM'
      }]
    }, (data) => {
      if (data.content) {
        setItemUOMs(data.content)
      }
    }, () => {
      showErrorNotification('Error while retrieving item UOM.')
    })
  }, [])

  const onRowClick = (event, rowData) => {
    if (document.getSelection() && document.getSelection().type !== 'Range' && !(['button'].includes(event.target.nodeName.toLowerCase()) || event.target.nodeName.toLowerCase() === 'path' || event.target.nodeName.toLowerCase() === 'svg')) {
      setItemForView(rowData)
      openViewDialog()
    }
  }


  const columns = [{
    title: 'Quantity & UOM / Product Name Or Description', field: 'productName', render: rowData => <Typography
      className={clsx(classes.primaryText, classes.limitedText)}>
      {(rowData.quantity === null ? ' ' : rowData.quantity) + ' ' + (_.isEmpty(itemUOMs.filter(uom => uom['code'] === rowData.uom).map(uom => uom['name'])) ? ' ' : itemUOMs.filter(uom => uom['code'] === rowData.uom).map(uom => uom['name'])) + ' - ' + (_.isEmpty(rowData.productName) ? ' ' : rowData.productName)}
    </Typography>, defaultSort: 'asc', customSort: (a, b) => {
      const productName1 = a.productName ? a.productName.toLowerCase() : ''
      const productName2 = b.productName ? b.productName.toLowerCase() : ''
      return productName1.localeCompare(productName2) > 0 ? 1 : -1
    }
  }, {
    title: 'Unit Price', field: 'price', render: rowData => <Typography
      className={clsx(classes.primaryText)}>{rowData.declaredPrice && currencySymbolPrefix + rowData.price}</Typography>
  }, {
    title: 'Declared Price', field: 'declaredPrice', render: rowData => <Typography
      className={clsx(classes.primaryText)}>{rowData.declaredPrice && currencySymbolPrefix + rowData.declaredPrice}</Typography>
  }, {
    title: 'Action', field: 'last_name', sorting: false, render: rowData => <>

      <Button
        style={{
          maxWidth: '20px', minWidth: '20px', marginLeft: '.5rem'
        }}
        onClick={() => {
          setItemToEdit(rowData)
          openAddEditDialog()
        }}
        classes={{ startIcon: classes.startIcon }}
        variant='outlined'
        startIcon={<CreateOutlinedIcon />}
      ></Button>
      <Button
        style={{
          maxWidth: '25px', minWidth: '25px', marginLeft: '.5rem'
        }}
        onClick={() => {
          setItemsToDelete([rowData])
          openDeleteDialog()
        }}
        classes={{ startIcon: classes.startIcon }}
        variant='outlined'
        startIcon={<DeleteIcon />}
      ></Button>
    </>
  }]

  let customToolbar = props => (<div>
    {props.selectedRows.length > 0 ?

      <div className='MuiToolbar-root MuiToolbar-regular MuiToolbar-gutters'>
        <div>
          <Typography
            className={clsx(classes.colorBlue, classes.secondaryText)}>{Number(props.selectedRows.length) + (props.selectedRows.length === 1 ? ' item ' : ' items ') + 'selected'} </Typography>
          <Typography
            className={classes.secondaryText}> {(Number(props.dataManager.currentPage) * Number(props.dataManager.pageSize) + 1) + '-' + (Number(props.dataManager.currentPage) * Number(props.dataManager.pageSize) + Number(props.renderData.length)) + ' of ' + Number(props.data.length) + ' items'} </Typography>
        </div>
        <div style={{ flex: '1 1 10%' }} />
        <Button variant='outlined' startIcon={<DeleteOutline />}
                style={{
                  color: '#e91e63',
                  textTransform: 'none'
                }}
                onClick={() => {
                  setItemsToDelete(props.selectedRows)
                  openDeleteDialog()
                }}>
          Delete
        </Button>
        <IconButton aria-label='delete' onClick={clearSelection}>
          <ClearIcon />
        </IconButton>
      </div>

      :

      <div className='MuiToolbar-root MuiToolbar-regular MuiToolbar-gutters' style={{ flex: '1 1 10%' }}>
        <Typography className={'boldLabel'} style={{ fontSize: 16, paddingRight: 8 }}>
          Items
        </Typography>
        <Typography className={'greyLabel'}
                    style={{ fontSize: 12 }}> / {props.data.length} Entries </Typography>
        <div style={{ flex: '1 1 10%' }} />
        <MTableToolbar {...props} />
        <CngSecondaryButton startIcon={<AddCircleOutlineIcon />} size='small'
                            onClick={() => {
                              setItemToEdit(null)
                              openAddEditDialog()
                            }}>
          Add Item
        </CngSecondaryButton>
      </div>
    }
  </div>)
  return (<Grid item xs={12}>
    <Box className={classes.table}>
      <MaterialTable
        title=''
        tableRef={tableRef}
        icons={tableIcons}
        columns={columns}
        onRowClick={onRowClick}
        options={{
          search: true,
          paging: true,
          sorting: true,
          emptyRowsWhenPaging: false,
          padding: 'default',
          selection: true
        }}
        localization={{
          toolbar: { searchPlaceholder: 'Search in your entries' }
        }}
        data={deliveryOrderItems.filter(item => item.delete === undefined || item.delete === false).map(item => ({ ...item }))}
        components={{
          Toolbar: customToolbar
        }}
      />
    </Box>
    <ViewItemDialog isDialogOpen={isViewDialogOpened} closeDialog={closeViewDialog} itemData={itemForView}
                    salesOrderCurrency={salesOrderCurrency} itemUOMs={itemUOMs} />
    <AddEditItemDialog isDialogOpen={isAddEditDialogOpened} closeDialog={closeAddEditDialog}
                       itemData={itemForEdit} deliveryOrderItems={deliveryOrderItems}
                       showNotification={showNotification} deliveryOrderActionType={actionType}
                       setDeliveryOrderItems={setDeliveryOrderItems} />
    <DeleteItemDialog isDialogOpen={isDeleteDialogOpened} closeDialog={closeDeleteDialog}
                      itemsToDelete={itemsToDelete}
                      deliveryOrderActionType={actionType}
                      deliveryOrderItems={deliveryOrderItems}
                      setDeliveryOrderItems={setDeliveryOrderItems} />
  </Grid>)
}

export default ItemsDetails
