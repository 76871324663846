import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { components } from 'cng-web-lib'
import { Button, Chip, CircularProgress, makeStyles, Tooltip, Typography } from '@material-ui/core'
import Moment from 'moment'
import PropTypes from 'prop-types'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import PrinterIcon from '@material-ui/icons/LocalPrintshopOutlined'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import clsx from 'clsx'
import { getStatusByName } from '../util/Utils'


const headerChipTheme = createMuiTheme({
  overrides: {
    MuiChip: {
      root: {
        width: '100%',
        height: '2.5rem', margin: '.5rem 1rem 1rem .5rem ', justifyContent: 'flex-start',
        borderRadius: '.5rem'
      },
      label: {
        width: '100%'
      }
    }
  }
})


ViewHeader.propTypes = {
  deliveryOrder: PropTypes.object
}

const {
  CngGridItem,
  button: { CngButton, CngIconButton }
} = components

function ViewHeader(props) {

  const { deliveryOrder, singlePrint, isLoading } = props
  const [isPrintLabelVisible, setPrintLabelVisible] = useState()
  const [isPrintButtonVisible, setPrintButtonVisible] = useState(true)
  const useStyles = makeStyles((theme) => ({
    startIcon: {
      margin: '0px !important',
      color: '#8e98b0 !important'
    }
  }))
  const classes = useStyles()

  useEffect(() => {
    setPrintLabelVisible(deliveryOrder.packages && deliveryOrder.packages.shippingLabel && deliveryOrder.packages.shippingLabel.printed)
  }, [deliveryOrder])

  useEffect(() => {
    setPrintButtonVisible(['Cancelled', 'Created', 'Delivered', 'Ready'].includes(deliveryOrder.deliveryOrderStatus))
  }, [deliveryOrder.deliveryOrderStatus])

  return (
    <>
      <Grid container spacing={1}>
        <CngGridItem item xs={12} sm={12}>
          <ThemeProvider theme={headerChipTheme}>
            <Chip
              style={{
                backgroundColor: getStatusByName(deliveryOrder.deliveryOrderStatus).color
              }}
              label={
                <Grid container style={{ width: '100%' }}>
                  <CngGridItem item xs={4}>
                    <Tooltip
                      title={'Delivery Order Status: ' + deliveryOrder.deliveryOrderStatus}>
                      <Typography className={clsx('text-upper', 'boldLabel')}
                                  style={{ width: 'fit-content', marginTop: '.2rem' }}>
                        {deliveryOrder.deliveryOrderStatus}
                      </Typography>
                    </Tooltip>
                  </CngGridItem>
                  <CngGridItem item xs={8}>

                    <Typography className={clsx('alignRight', 'info-label')}>
                      {isPrintLabelVisible ? <><CheckCircleIcon fontSize='small' /> Label
                        printed </> : null}
                      {isPrintLabelVisible && isPrintButtonVisible ? <> | </> : null}
                      {isPrintButtonVisible ? <>  {
                        isLoading ? (
                          <>
                            Print label &nbsp;  <CircularProgress color='inherit'
                                                                  size={12} />
                          </>
                        ) : <>Print label</>
                      }
                        <Button
                          style={{
                            maxWidth: '36px',
                            minWidth: '36px',
                            backgroundColor: 'whitesmoke',
                            marginLeft: '.5rem'
                          }}
                          onClick={() => singlePrint(deliveryOrder.deliveryOrderRefNo)}
                          disabled={isLoading}
                          classes={{ startIcon: classes.startIcon }}
                          variant='outlined'
                          startIcon={<PrinterIcon />}
                        >

                        </Button>
                      </> : null}
                    </Typography>

                  </CngGridItem>
                </Grid>
              }
            />
          </ThemeProvider>

        </CngGridItem>

      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography className={'boldLabel'} style={{ fontSize: 18 }}>
          Delivery order system no.: {deliveryOrder.deliveryOrderSystemNo}
        </Typography>
        <Typography className={'greyLabel'} style={{ fontSize: 14 }}>
          The following order was created
          on <b> {Moment(deliveryOrder.createdAt).format('dddd, DD MMM YYYY , HH:mm')} </b>
        </Typography>
      </Grid>
    </>
  )
}

export default ViewHeader
