import CommonUtils from 'src/views/common/utils/Utils'

const partyType = {
  declarant: 'Declarant',
  declaringAgent: 'Declaring Agent',
  freightForwarder: 'Freight Forwarder',
  inCarrierAgent: 'Incarrier Agent',
  importer: 'Importer',
  outCarrierAgent: 'Outcarrier Agent',
  exporter: 'Exporter',
  consignee: 'Consignee',
  endUser: 'End User',
  manufacturer: 'Manufacturer',
  claimant: 'Claimant'
}

const DEFAULT_ITEM_UOM = 'PKG'
const DEFAULT_ITEM_WEIGHT_UNIT = 'TNE'
function constructPartyObject(data) {
  let shippingInfoComplianceParty = {}

  let partyList = data.shippingInfoCompliancePartyList
  if (CommonUtils.isNotEmpty(partyList)) {
    partyList.forEach((party) => {
      console.log(party)
      //declarant
      if (party.partyType == partyType.declarant) {
        shippingInfoComplianceParty.declarantPersonName = party.name
        shippingInfoComplianceParty.declarantPersonCode = party.crno
        shippingInfoComplianceParty.declarantPersonPhone = party.phone
      }
      //declarent agent
      if (party.partyType == partyType.declaringAgent) {
        shippingInfoComplianceParty.declaringAgentCrNo = party.crno
        shippingInfoComplianceParty.declaringAgentName1 = party.name
        shippingInfoComplianceParty.declaringAgentName2 = party.name2
      }
      //freight forwarder
      if (party.partyType == partyType.freightForwarder) {
        shippingInfoComplianceParty.freightForwarderCrNo = party.crno
        shippingInfoComplianceParty.freightForwarderName1 = party.name
        shippingInfoComplianceParty.freightForwarderName2 = party.name2
      }
      //in carriage agent
      if (party.partyType == partyType.inCarrierAgent) {
        shippingInfoComplianceParty.inCarrierAgentCrNo = party.crno
        shippingInfoComplianceParty.inCarrierAgentName1 = party.name
        shippingInfoComplianceParty.inCarrierAgentName2 = party.name2
      }
      //importer
      if (party.partyType == partyType.importer) {
        shippingInfoComplianceParty.importerCrNo = party.crno
        shippingInfoComplianceParty.importerName1 = party.name
        shippingInfoComplianceParty.importerName2 = party.name2
      }
      //out carriage agent
      if (party.partyType == partyType.outCarrierAgent) {
        shippingInfoComplianceParty.outCarrierAgentCrNo = party.crno
        shippingInfoComplianceParty.outCarrierAgentName1 = party.name
        shippingInfoComplianceParty.outCarrierAgentName2 = party.name2
      }
      //exporter
      if (party.partyType == partyType.exporter) {
        shippingInfoComplianceParty.exporterCrNo = party.crno
        shippingInfoComplianceParty.exporterName1 = party.name
        shippingInfoComplianceParty.exporterAddress1 = party.address
        shippingInfoComplianceParty.exporterName2 = party.name2
        shippingInfoComplianceParty.exporterAddress2 = party.address2
        
        shippingInfoComplianceParty.exporterCity = party.city
        shippingInfoComplianceParty.exporterPstCode = party.postalCode
        shippingInfoComplianceParty.exporterCtyCode = party.country
      }

      //consigenee
      if (party.partyType == partyType.consignee) {
        shippingInfoComplianceParty.consigneeName1 = party.name
        shippingInfoComplianceParty.consigneeAddress1 = party.address
        shippingInfoComplianceParty.consigneeName2 = party.name2
        shippingInfoComplianceParty.consigneeAddress2 = party.address2

        shippingInfoComplianceParty.consigneeCity = party.city
        shippingInfoComplianceParty.consigneePstCode = party.postalCode
        shippingInfoComplianceParty.consigneeCtyCode = party.country
      }

      //end user
      if (party.partyType == partyType.endUser) {
        shippingInfoComplianceParty.endUserName1 = party.name
        shippingInfoComplianceParty.endUserAddress1 = party.address
        shippingInfoComplianceParty.endUserName2 = party.name2
        shippingInfoComplianceParty.endUserAddress2 = party.address2

        shippingInfoComplianceParty.endUserCity = party.city
        shippingInfoComplianceParty.endUserPstCode = party.postalCode
        shippingInfoComplianceParty.endUserCtyCode = party.country
      }

      //manufacturer
      if (party.partyType == partyType.manufacturer) {
        shippingInfoComplianceParty.manufacturerCrNo = party.crno
        shippingInfoComplianceParty.manufacturerName1 = party.name
        shippingInfoComplianceParty.manufacturerAddress1 = party.address
        shippingInfoComplianceParty.manufacturerName2 = party.name2
        shippingInfoComplianceParty.manufacturerAddress2 = party.address2

        shippingInfoComplianceParty.manufacturerCity = party.city
        shippingInfoComplianceParty.manufacturerPstCode = party.postalCode
        shippingInfoComplianceParty.manufacturerCtyCode = party.country
      }
    })
  }

  data.shippingInfoComplianceParty = shippingInfoComplianceParty
  return data
}

function constructPartyList(data){

  let partyList = []
  let shippingInfoComplianceParty = data.shippingInfoComplianceParty

  if (CommonUtils.isNotEmpty(shippingInfoComplianceParty)) {
    
    //Declarant
    if(shippingInfoComplianceParty.declarantPersonName !== null){
      let party={
        partyType: partyType.declarant,
        name: shippingInfoComplianceParty.declarantPersonName,
        crno: shippingInfoComplianceParty.declarantPersonCode,
        phone: shippingInfoComplianceParty.declarantPersonPhone
      }
      partyList.push(party)
    }
    //Declaring Agent
    if (shippingInfoComplianceParty.declaringAgentName1 !== null) {
      let party={
        partyType: partyType.declaringAgent,
        crno: shippingInfoComplianceParty.declaringAgentCrNo,
        name: shippingInfoComplianceParty.declaringAgentName1,
        name2: shippingInfoComplianceParty.declaringAgentName2,
      }
      partyList.push(party)
    }
    //Freight forwarder
    if (shippingInfoComplianceParty.freightForwarderName1 !== null) {
      let party={
        partyType: partyType.freightForwarder,
        crno: shippingInfoComplianceParty.freightForwarderCrNo,
        name: shippingInfoComplianceParty.freightForwarderName1,
        name2: shippingInfoComplianceParty.freightForwarderName2,
      }
      partyList.push(party)
    }
    //In carriage agent
    if (shippingInfoComplianceParty.inCarrierAgentName1 !== null) {
      let party={
        partyType: partyType.inCarrierAgent,
        crno: shippingInfoComplianceParty.inCarrierAgentCrNo,
        name: shippingInfoComplianceParty.inCarrierAgentName1,
        name2: shippingInfoComplianceParty.inCarrierAgentName2,
      }
      partyList.push(party)
    }
    //Importer
    if (shippingInfoComplianceParty.importerName1 !== null) {
      let party={
        partyType: partyType.importer,
        crno: shippingInfoComplianceParty.importerCrNo,
        name: shippingInfoComplianceParty.importerName1,
        name2: shippingInfoComplianceParty.importerName2,
      }
      partyList.push(party)
    }
    //Out carriage agent
    if (shippingInfoComplianceParty.outCarrierAgentName1 !== null) {
      let party={
        partyType: partyType.outCarrierAgent,
        crno: shippingInfoComplianceParty.outCarrierAgentCrNo,
        name: shippingInfoComplianceParty.outCarrierAgentName1,
        name2: shippingInfoComplianceParty.outCarrierAgentName2,
      }
      partyList.push(party)
    }
    //Exporter
    if (shippingInfoComplianceParty.exporterName1 !== null) {
      let party={
        partyType: partyType.exporter,
        crno: shippingInfoComplianceParty.exporterCrNo,
        name: shippingInfoComplianceParty.exporterName1,
        address: shippingInfoComplianceParty.exporterAddress1,
        name2: shippingInfoComplianceParty.outCarrierAgentName2,
        address2: shippingInfoComplianceParty.exporterAddress2,

        city: shippingInfoComplianceParty.exporterCity,
        postalCode: shippingInfoComplianceParty.exporterPstCode,
        country: shippingInfoComplianceParty.exporterCtyCode
      }
      partyList.push(party)
    }
    //Consignee
    if (shippingInfoComplianceParty.consigneeName1 !== null) {
      let party={
        partyType: partyType.consignee,
        name: shippingInfoComplianceParty.consigneeName1,
        address: shippingInfoComplianceParty.consigneeAddress1,
        name2: shippingInfoComplianceParty.consigneeName2,
        address2: shippingInfoComplianceParty.consigneeAddress2,

        city: shippingInfoComplianceParty.consigneeCity,
        postalCode: shippingInfoComplianceParty.consigneePstCode,
        country: shippingInfoComplianceParty.consigneeCtyCode
      }
      partyList.push(party)
    }
    //End user
    if (shippingInfoComplianceParty.endUserName1 !== null) {
      let party={
        partyType: partyType.endUser,
        name: shippingInfoComplianceParty.endUserName1,
        address: shippingInfoComplianceParty.endUserAddress1,
        name2: shippingInfoComplianceParty.endUserName2,
        address2: shippingInfoComplianceParty.endUserAddress2,

        city: shippingInfoComplianceParty.endUserCity,
        postalCode: shippingInfoComplianceParty.endUserPstCode,
        country: shippingInfoComplianceParty.endUserCtyCode
      }
      partyList.push(party)
    }
    //manufacturer
    if (shippingInfoComplianceParty.manufacturerName1 !== null) {
      let party={
        partyType: partyType.manufacturer,
        crno: shippingInfoComplianceParty.manufacturerCrNo,
        name: shippingInfoComplianceParty.manufacturerName1,
        address: shippingInfoComplianceParty.manufacturerAddress1,
        name2: shippingInfoComplianceParty.manufacturerName2,
        address2: shippingInfoComplianceParty.manufacturerAddress2,

        city: shippingInfoComplianceParty.manufacturerCity,
        postalCode: shippingInfoComplianceParty.manufacturerPstCode,
        country: shippingInfoComplianceParty.manufacturerCtyCode
      }
      partyList.push(party)
    }
    //claimant
    if (shippingInfoComplianceParty.claimantName1 !== null) {
      let party={
        partyType: partyType.claimant,
        crno: shippingInfoComplianceParty.claimantCRNo,
        name: shippingInfoComplianceParty.claimantName1,
        name2: shippingInfoComplianceParty.claimantName2,

        claimantPersonCode: shippingInfoComplianceParty.claimantPersonCode, //maybe no need to transform
        claimantPersonName: shippingInfoComplianceParty.claimantPersonName
      }
      partyList.push(party)
    }
  }

  return partyList
}

function getOutPackUom(packageUOM) {
  return packageUOM.every((val, i, arr) => val === arr[0])
    ? packageUOM[0]
    : DEFAULT_ITEM_UOM
}

function isEmpty(obj) {
  return Object.keys(obj).length === 0
}

function recalculateItemSummary(itemList) {
  let shippingInfoComplianceSummary = {}
  if (CommonUtils.isNotEmpty(itemList)) {

    let totalAmount = 0
    let totalQty = 0
    let totalWeight = 0
    let packageUOM = []
    itemList.forEach((elem) => {
      totalAmount += CommonUtils.parseNumber(elem.cifFobAmt)
      totalQty += CommonUtils.parseNumber(elem.hsQtyValue)
      totalWeight += CommonUtils.parseNumber(elem.grossWeight)
      packageUOM.push(elem.hsQtyUom)
    })

    shippingInfoComplianceSummary.totalItems = itemList.length
    shippingInfoComplianceSummary.totalCifFobAmount = totalAmount
    shippingInfoComplianceSummary.totalOutPackQuantityValue = totalQty
    shippingInfoComplianceSummary.totalOutPackQuantityUom =
      getOutPackUom(packageUOM)
    shippingInfoComplianceSummary.totalGrossWeightQuantityValue = totalWeight
    shippingInfoComplianceSummary.totalGrossWeightQuantityUom =
      DEFAULT_ITEM_WEIGHT_UNIT
   
  }
  return shippingInfoComplianceSummary
}

const Utils = Object.freeze({
  partyType,
  constructPartyObject,
  getOutPackUom,
  recalculateItemSummary,
  isEmpty,
  constructPartyList
})
export default Utils
