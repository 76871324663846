import React from 'react'
import { InputAdornment } from '@mui/material'
import { components } from 'cng-web-lib'
import { IconButton } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function SearchBar() {

  const {
    form: {
      field: { CngTextField }
    }
  } = components

  return (

    <CngTextField name='searchValue' size='small'
                  placeholder={'Search for party template ID,first name, last name or street'}
                  fullWidth={false}
                  InputProps={{
                    endAdornment: <InputAdornment position='end'>
                      <IconButton
                        aria-label='search'
                        color='primary'
                      >
                        <FontAwesomeIcon icon={['fal', 'search']}
                                         size='xs' color='primary'
                        />
                      </IconButton>
                    </InputAdornment>
                  }}
    />)
}

export default SearchBar
