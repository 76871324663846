import ComplianceApiUrls from 'src/apiUrls/ComplianceApiUrls.js'

function fetchSortingType(fetchRecords, onSuccess, onError) {
  fetchRecords.execute(
    ComplianceApiUrls.SORTING_TYPE,
    {},
    (data) => {
      console.log('fetchSortingType is back')
      console.log(data)
      onSuccess(data)
    },
    (error) => {
      onError(error)
    }
  )
}

function fetchStatusCodeAndCount(fetchRecords, onSuccess, onError) {
  fetchRecords.execute(
    ComplianceApiUrls.STATUS_LIST,
    {},
    (data) => {
      console.log('fetchStatusCodeAndCount is back ')
      console.log(data)
      onSuccess(data)
    },
    (error) => {
      onError(error)
    }
  )
}

function fetchMessageTypes(
  fetchRecords,
  searchCriteria,
  onSuccess,
  onError
) {
  console.log('fetchMessageTypes')
  console.log(searchCriteria)
  fetchRecords.execute(
    ComplianceApiUrls.MESSAGE_TYPE_LIST,
    {
      customData: searchCriteria
    },
    (data) => {
      console.log('MESSAGE_TYPE_LIST is back')
      console.log(data)
      onSuccess(data)
    },
    (error) => {
      onError(error)
    }
  )
}

function fetchDecType(fetchRecords, searchCriteria, onSuccess, onError) {
  console.log('fetchDecType')
  console.log(searchCriteria)
  fetchRecords.execute(
    ComplianceApiUrls.DEC_TYPE_LIST,
    {
      customData: searchCriteria
    },
    (data) => {
      console.log('DEC_TYPE_LIST is back')
      console.log(data)
      onSuccess(data)
    },
    (error) => {
      onError(error)
    }
  )
}

function onComplete() {
  console.log('complete')
}

function fetchComplianceList(
  fetchRecords,
  searchCriteria,
  onSuccess,
  onError
) {
  console.log('fetchComplianceList')
  console.log(searchCriteria)
  fetchRecords.execute(
    ComplianceApiUrls.COMPLIANCE_LIST,
    {
      customData: searchCriteria
    },
    (data) => {
      console.log('fetchComplianceList is back')
      console.log('data: ' + data)
      onSuccess(data)
    },
    (error) => {
      onError(error)
    }
  )
}

function fetchPermitById(fetchRecords, permitRequest, onSuccess){
  console.log('getPermitDetailsById...')
  fetchRecords.execute(
    ComplianceApiUrls.VIEW_PERMIT_BY_ID,
    { 
      customData: permitRequest 
    },
    (data) => {
      onSuccess(data)
      console.log(data)
    },
    (error) => {
      console.log(error)
    }
  )
}

const ComplianceApi = Object.freeze({
  fetchSortingType,
  fetchStatusCodeAndCount,
  fetchMessageTypes,
  fetchDecType,
  fetchComplianceList,
  fetchPermitById,
})

export default ComplianceApi
