import React from 'react'
import { Box } from '@mui/material'

function EmptyBox() {
  return (
    <Box sx={{ m: '1rem' }} />
  )
}

export default EmptyBox
