import React, { useEffect, useRef, useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import CloseIcon from '@material-ui/icons/Close'
import { Box, Card, Grid, IconButton, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import BackButton from '../../../../../../components/button/BackButton'
import SearchBar from './SearchBar'
import PartyTemplateItemDetail from './PartyTemplateItemDetail'
import { makeStyles } from '@material-ui/core/styles'
import PartyConfigUrls from '../../../../../../apiUrls/cec-admin/party-config/PartyConfigUrls'
import { components, useServices } from 'cng-web-lib'

PartyTemplateDialog.propTypes = {
  isDialogOpen: PropTypes.bool, closeDialog: PropTypes.func
}

const { CngGridItem, button: { CngPrimaryButton } } = components

const useStyles = makeStyles(theme => ({
  box_line: {
    border: '1px solid #F5F5FA', marginLeft: theme.spacing(0.75), padding: theme.spacing(0.75), width: '100%'
  }, box_line_blue: {
    border: '1px solid #5E81F4', marginLeft: theme.spacing(0.75), padding: theme.spacing(0.75), width: '100%'
  }, checked_button: {
    marginLeft: '15px',
    padding: '3px',
    backgroundColor: '#5e81f4 !important',
    borderRadius: '10px',
    color: 'white !important'
  }, check_button: {
    marginLeft: '15px', padding: '3px', backgroundColor: '#ececec', borderRadius: '10px', color: 'grey'
  }, textColor: {
    color: '#8996AF', textTransform: 'uppercase'
  }, icon: {
    borderRadius: '50%', width: 24, height: 24, backgroundColor: theme.palette.grey[200], 'input:disabled ~ &': {
      backgroundColor: theme.palette.grey[100]
    }
  }, checkedIcon: {
    backgroundColor: theme.palette.primary.main, '&:before': {
      display: 'block',
      width: 24,
      height: 24,
      backgroundImage: 'radial-gradient(#fff, #fff 28%, transparent 32%)',
      content: '""'
    }
  }

}))

function PartyTemplateDialog(props) {


  const { isDialogOpen, closeDialog, partyType, updatePartyTemplate, showNotification } = props
  const classes = useStyles()

  const pageSize = 10
  const pageNumber = useRef(0)

  const [sortColumn] = useState('code')
  const [sortType] = useState('ASC')
  const [searchText] = useState('')
  const [items, setItems] = useState([])
  const [selectedValue, setSelectedValue] = React.useState('')
  const { securedSendRequest } = useServices()

  const handleInsertButton = () => {
    if (selectedValue) {
      const template = items.find(item => item.code === selectedValue)
      if (template) {
        updatePartyTemplate(partyType, template)
      }
    }
    closeDialog()
  }
  const fetchData = () => {

    const sortProperties = sortColumn && sortType ? [{
      'sortField': sortColumn, 'sortType': sortType
    }] : []

    securedSendRequest.execute('POST', PartyConfigUrls.SAVED_SHIPPER_ADDRESS_OF_USER_PARTY, {
      'baseFilterDTO': {
        'filterType': 'OR', 'filterProperties': [{
          'fieldName': 'code', 'operatorType': 'LIKE', 'value1': searchText
        }, {
          'fieldName': 'firstName', 'operatorType': 'LIKE', 'value1': searchText
        }, {
          'fieldName': 'lastName', 'operatorType': 'LIKE', 'value1': searchText
        }, {
          'fieldName': 'address', 'operatorType': 'LIKE', 'value1': searchText
        }], 'sortProperties': sortProperties, 'page': pageNumber - 1, 'size': pageSize
      }
    }, (res) => {
      if (res.status == 200) {
        const data = res.data.content
        setItems(data)
      }
    }, (error) => console.log('Error'), () => {

    })
  }

  const descriptionElementRef = React.useRef(null)
  React.useEffect(() => {
    if (isDialogOpen) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [isDialogOpen])

  useEffect(() => {
    fetchData()
  }, [])

  const handleChange = (event) => {
    setSelectedValue(event.target.value)
  }

  return (<div>
    <Dialog
      open={isDialogOpen}
      onClose={closeDialog}
      scroll={'paper'}
      aria-labelledby='scroll-dialog-title'
      aria-describedby='scroll-dialog-description'
      fullWidth
      maxWidth='md'
    >
      <DialogTitle id='scroll-dialog-title' style={{ paddingBottom: '0px' }}>
        <Box display='flex' alignItems='center' style={{ fontSize: '16px', fontWeight: 'bold' }}>
          <Box flexGrow={1}>{'Party Template'}</Box>
          <Box>
            <IconButton onClick={closeDialog}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <hr style={{ width: '105%', marginLeft: '-25px', color: 'gray' }} />
        <Card variant='outlined' spacing={2}
              style={{ margin: '10px 18px 0px 0px', borderRadius: '1rem 1rem 0rem 0rem' }}>
          <Grid container spacing={1}>
            <CngGridItem xs={4} style={{ padding: '1.5rem' }}>
              <Typography
                variant='body2'>{items.length + (items.length < 1 ? ' items' : ' item')}</Typography>
            </CngGridItem>
            <CngGridItem xs={8} style={{ margin: '.5rem 0 .5rem 0', paddingRight: '10px' }}>
              <SearchBar />
            </CngGridItem>
          </Grid>
          <Grid container spacing={1} style={{ backgroundColor: '#eff2f04d', padding: '14px 20px' }}>
            <CngGridItem xs={1} sm={1}>
              <Typography variant='body2'></Typography>
            </CngGridItem>
            <CngGridItem xs={4} sm={3}>
              <Typography variant='body2' className={classes.textColor}>{'Party template id'}</Typography>
            </CngGridItem>
            <CngGridItem xs={7} sm={3}>
              <Typography variant='body2' className={classes.textColor}>{'First/last name'}</Typography>
            </CngGridItem>
            <CngGridItem xs={7} sm={5}>
              <Typography variant='body2' className={classes.textColor}>{'Street'}</Typography>
            </CngGridItem>
          </Grid>
        </Card>
      </DialogTitle>
      <DialogContent style={{ paddingTop: '0px', maxHeight: '500px' }}>
        <DialogContentText
          id='scroll-dialog-description'
          ref={descriptionElementRef}
          tabIndex={-1}
        >
          <Card variant='outlined' spacing={2} style={{ margin: '0px', borderRadius: '0rem 0rem 1rem 1rem' }}>
            <Grid item xs={12} container spacing={2} style={{ marginTop: '8px', marginBottom: '8px' }}>
              {items.map((template) => {
                return (<PartyTemplateItemDetail key={template} selectedValue={selectedValue}
                                                 template={template}
                                                 classes={classes} onChange={handleChange}
                                                 showNotification={showNotification} />)
              })}
            </Grid>
          </Card>
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ padding: '1rem 1rem', borderTop: '#f8f8f8', borderTopStyle: 'solid' }}>
        <BackButton onClick={closeDialog} label={'Discard'} size='large' />
        <CngPrimaryButton onClick={handleInsertButton}>
          {'Insert & populate'}
        </CngPrimaryButton>
      </DialogActions>
    </Dialog>
  </div>)
}

export default PartyTemplateDialog
