import SearchFormProperties from './SearchFormProperties'
import React from 'react'
import { components, constants } from 'cng-web-lib'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'

const {
  form: {
    CngForm
  }
} = components

const {
  FormState
} = constants

function SearchForm({ showNotification }) {

  const {
    filters
  } = useSelector(({ filters }) => ({ filters }))

  let formData = {
    lastMileShipmentProviderIDs: [],
    deliveryOrderStatusIDs: [],
    deliveryOrderRefNo: '',
    deliveryOrderSysNo: '',
    trackingNo: '',
    latestEventCodes: [],
    deliveryEventStartDate: '',
    deliveryEventEndDate: '',
    deliveryOrderStartDate: '',
    deliveryOrderEndDate: '',
    isLabelNotPrinted: false
  }
  if (filters != null && filters.length > 0) {
    if (_.find(filters, { fieldName: 'lastMileShipmentProviderId' })) {
      formData.lastMileShipmentProviderIDs = _.find(filters, { fieldName: 'lastMileShipmentProviderId' }).value1.split('|').map(s => parseInt(s))
    }
    if (_.find(filters, { fieldName: 'deliveryOrderStatusId' })) {
      formData.deliveryOrderStatusIDs = _.find(filters, { fieldName: 'deliveryOrderStatusId' }).value1.split('|').map(s => parseInt(s))
    }
    if (_.find(filters, { fieldName: 'latestEventCodes' })) {
      formData.latestEventCodes = _.find(filters, { fieldName: 'latestEventCodes' }).value1.split('|')
    }
    if (_.find(filters, { fieldName: 'deliveryOrderRefNo' })) {
      formData.deliveryOrderRefNo = _.find(filters, { fieldName: 'deliveryOrderRefNo' }).value1
    }
    if (_.find(filters, { fieldName: 'deliveryOrderSysNo' })) {
      formData.deliveryOrderSysNo = _.find(filters, { fieldName: 'deliveryOrderSysNo' }).value1
    }
    if (_.find(filters, { fieldName: 'trackingNo' })) {
      formData.trackingNo = _.find(filters, { fieldName: 'trackingNo' }).value1
    }
    if (_.find(filters, { fieldName: 'isPrinted' })) {
      formData.isLabelNotPrinted = !_.find(filters, { fieldName: 'isPrinted' }).value1
    }
    if (_.find(filters, { fieldName: 'latestEventCreatedOn', operatorType: 'GREATERTHANEQUAL' })) {
      formData.deliveryEventStartDate = moment(_.find(filters, {
        fieldName: 'latestEventCreatedOn',
        operatorType: 'GREATERTHANEQUAL'
      }).value1)
        .format('YYYY-MM-DD HH:mm:ss')
    }
    if (_.find(filters, { fieldName: 'latestEventCreatedOn', operatorType: 'LESSERTHAN' })) {
      formData.deliveryEventEndDate = moment(_.find(filters, {
        fieldName: 'latestEventCreatedOn',
        operatorType: 'LESSERTHAN'
      }).value1)
        .format('YYYY-MM-DD HH:mm:ss')
    }
    if (_.find(filters, { fieldName: 'createdOn', operatorType: 'GREATERTHANEQUAL' })) {
      formData.deliveryOrderStartDate = moment(_.find(filters, {
        fieldName: 'createdOn',
        operatorType: 'GREATERTHANEQUAL'
      }).value1)
        .format('YYYY-MM-DD HH:mm:ss')
    }
    if (_.find(filters, { fieldName: 'createdOn', operatorType: 'LESSERTHAN' })) {
      formData.deliveryOrderEndDate = moment(_.find(filters, {
        fieldName: 'createdOn',
        operatorType: 'LESSERTHAN'
      }).value1)
        .format('YYYY-MM-DD HH:mm:ss')
    }
  }

  return (

    <CngForm
      formState={FormState.COMPLETED}
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      showNotification={showNotification}
      renderBodySection={() => <SearchFormProperties.Fields
        disabled={false}
        showNotification={showNotification}
      />}
      hookFormProps={{
        initialValues: formData,
        mode: 'all'
      }}
      renderButtonSection={() => undefined}
    />

  )
}

export default SearchForm
