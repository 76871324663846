const VALUE_ALL = 'ALL'

export default class ShippingInstructionApiParser {
  static parseSortingType(data) {
    let tempList = []
    data.map((v) => {
      let temp = {
        text: v.sortDesc,
        value: v.sortCode
      }
      tempList.push(temp)
    })
    return tempList
  }

  /**
   * parses the all the data into an array for all the statuscodes so that all bookings will be viewed on one page
   * @param {*} data
   * @returns {... data, fullList}
   */
  static parseHelpdeskStatusCodeAndCount(data) {
    let concatList = data.pendingStatusList.concat(data.processedStatusList)
    let fullList = concatList.map((v) => ({
      ...v,
      checked: true,
      eligible: true
    }))
    if (fullList[0] !== undefined) {
      let newRecord = { ...fullList[0] }
      newRecord.statusDesc = VALUE_ALL
      fullList = [...fullList, newRecord]
      console.log(fullList)
    }
    return { ...data, fullList }
  }

  static parseStatusCodeAndCount(data, showMyTask, isHelpdesk) {
    if (showMyTask === undefined) {
      showMyTask = false
    }
    console.log(data)
    console.log('parseStatusCodeAndCount:: showMyTaskcc' + showMyTask)

    let siStatusList = []
    siStatusList = data
      .map(d => {
        let status = {}
        status.statusCode = d[1]
        status.statusDesc = d[2] + ' (' + d[0] + ')'

        status.checked = d[0] > 0
        status.eligible = d[0] > 0

        return status
      })

    console.log("siStatusList", siStatusList)
    data.totalCount = siStatusList.reduce((a, b) => a + b[0], 0)
    return { ...data, siStatusList }
  }

  static parseStatus(list, count, showMyTask) {
    let status = list != null ? list[0] : null
    status.statusDesc = status.statusDesc + ' (' + count + ')'

    status.checked = count > 0
    status.eligible = count > 0 && !showMyTask
    return status
  }

  static parseMessageTypes(data) {
    let filteredData = data
      .map((elem) => {
        elem.statusDesc = elem[2] + '(' + elem[0] + ')'
        elem.statusCode = elem[1] 

        if (elem[0]==0){
          elem.checked = false
          elem.eligible = false
        }else {
          elem.checked = true
          elem.eligible = true
        }
        

        console.log(elem)
        return elem
      })

    let newRecord = { ...filteredData }
    newRecord.totalCount = filteredData.reduce((a, b) => a + b[0], 0)
    newRecord.statusDesc = VALUE_ALL
    newRecord.eligible = true
    newRecord.checked = true

    data = [...filteredData, newRecord]

    return data
  }
}
