import { Yup } from 'cng-web-lib'
import ValidationMessageTranslationText from './ValidationMessageTranslationText'


function useComplianceTransportValidationSchema(){
    const validationMessageTranslation = ValidationMessageTranslationText()

    return Yup.object().shape({
        shippingInfoComplianceTransport: Yup.object().shape({
            outTptMode: Yup.string()
                .required(validationMessageTranslation.requiredMessage)
        })
    })
}

export default useComplianceTransportValidationSchema