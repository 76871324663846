import { Yup } from 'cng-web-lib'

const ValidationSchema = () => {

  const requiredErrorMessage = 'Required/Mandatory'
  const mustNonBeNegativeNumber = 'This must not be a negative number'
  const mustBeIn2DecimalPoints = 'Must be in 2 decimal points'
  const maxSizeofGeneralNumeric = 'This must be lower than or equal to 9999999.99'
  const invalidDate = 'Invalid date. Please Enter the date in the format YYYY-MM-DD '
  const invalidEmail = 'This must be a valid email'
  const getErrorMessageForMaxLength = (maxLength => `This cannot be more than ${maxLength} characters`)

  const isValidDate = dateString => {
    const regEx = /^\d{4}-\d{2}-\d{2}$/
    if (!dateString.match(regEx)) {
      return false
    }  // Invalid format
    const d = new Date(dateString)
    const dNum = d.getTime()
    if (!dNum && dNum !== 0) {
      return false
    } // NaN value, Invalid date
    return d.toISOString().slice(0, 10) === dateString
  }

  const genericMaxValueCheckForNumber = value => value <= 9999999.99

  const shouldBeNonNegativeNumberWithTwoDecimalPoints = Yup.mixed()
    .test('nonNegative', mustNonBeNegativeNumber, value => value ? !isNaN(value) && value >= 0 : true)
    .test('requiredTwoDecimalPoint', mustBeIn2DecimalPoints, value => value ? /^\d+(\.\d{2})$/.test(value) : true)
    .test('maxCheck', maxSizeofGeneralNumeric, value => value ? genericMaxValueCheckForNumber(value) : true)

  const shouldBeNonNegativeNumber = Yup.mixed()
    .test('nonNegative', mustNonBeNegativeNumber, value => value ? !isNaN(value) && value >= 0 : true)
    .test('maxCheck', maxSizeofGeneralNumeric, value => value ? genericMaxValueCheckForNumber(value) : true)

  const shouldBeValidDate = Yup.mixed()
    .test('validDate', invalidDate, value => value ? isValidDate(value) : true)

  const validationsForAddress = {
    firstName: Yup.string().nullable().max(255, getErrorMessageForMaxLength(255)),
    lastName: Yup.string().nullable().max(255, getErrorMessageForMaxLength(255)),
    phone: Yup.string().nullable().max(255, getErrorMessageForMaxLength(255)),
    email: Yup.string().nullable().email(invalidEmail).max(255, getErrorMessageForMaxLength(255)),
    companyName: Yup.string().nullable().max(255, getErrorMessageForMaxLength(255)),
    fax: Yup.string().nullable().max(255, getErrorMessageForMaxLength(255)),
    taxNo: Yup.string().nullable().max(255, getErrorMessageForMaxLength(255)),
    otherState: Yup.string().nullable().max(255, getErrorMessageForMaxLength(255)),
    city: Yup.string().nullable().max(255, getErrorMessageForMaxLength(255)),
    street: Yup.string().nullable().max(1000, getErrorMessageForMaxLength(1000)),
    postalCode: Yup.string().nullable().max(9, getErrorMessageForMaxLength(9))
  }

  return Yup.object({
    deliveryOrderRefNo: Yup.string().max(255, getErrorMessageForMaxLength(255)).required(requiredErrorMessage),
    grandTotal: shouldBeNonNegativeNumberWithTwoDecimalPoints,
    declaredAmount: shouldBeNonNegativeNumberWithTwoDecimalPoints,
    trackingNo: Yup.string().nullable().max(255, getErrorMessageForMaxLength(255)),
    salesOrderNo: Yup.string().nullable().max(255, getErrorMessageForMaxLength(255)),
    invoiceNo: Yup.string().nullable().max(255, getErrorMessageForMaxLength(255)),
    invoiceDate: shouldBeValidDate,
    length: shouldBeNonNegativeNumber,
    width: shouldBeNonNegativeNumber,
    height: shouldBeNonNegativeNumber,
    weight: shouldBeNonNegativeNumber,
    volWeight: shouldBeNonNegativeNumber,
    shipper: Yup.object().shape(validationsForAddress),
    consignee: Yup.object().shape(validationsForAddress)
  })

}


export default ValidationSchema

