import { Box, Paper } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { components, useServices } from 'cng-web-lib'
import { useHistory, useLocation} from 'react-router-dom'
import ComplianceTranslationText from '../ComplianceTranslationText'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import CngBackdrop from 'src/views/vesselschedule/searchschedule/cngcomponent/CngBackDrop'
import Utils from 'src/views/freightbooking/shared/Utils'
import CommonUtils from 'src/views/common/utils/Utils'
import GeneralStepperComponent from 'src/views/common/ui/GeneralStepperComponent'
import pathMap from 'src/paths/PathMap_Compliance'
import ViewPermitTypeComponent from '../components/ViewPermitTypeComponent'
import ViewCargoComponent from '../components/ViewCargoComponent'
import ViewContainerComponent from '../components/ViewContainerComponent'
import ViewTransportComponent from '../components/ViewTransportComponent'
import ViewPartyComponent from '../components/ViewPartyComponent'
import ViewLicenceComponent from '../components/ViewLicenceComponent'
import ViewItemSummaryComponent from '../components/ViewItemSummaryComponent'
import ViewDocumentComponent from 'src/views/freightbooking/components/ViewDocumentComponent'
import SubmittedPermitButtonComponent from './SubmittedPermitButtonComponent'
import DraftPermitButtonComponent from '../components/DraftPermitButtonComponent'
import ItemsComponent from '../components/ItemsComponent'
import ComplianceApiUrls from 'src/apiUrls/ComplianceApiUrls'
import ViewInvoiceComponent from '../components/ViewInvoiceComponent'
import ConfirmDialog from 'src/components/dialog/ConfirmDialog'

const DEFAULT_INITIAL_VALUES = Object.freeze({
  permitId: '',
  setPermitId: '',
  isFirstAction: false
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES }
}

const {
  table: { useDefaultNotification },
  CngGridItem
} = components

const ReviewPermitButtonLayout = ({ isSubmitted, onEdit, onDiscard, onSubmitPermit, redirectToSIList }) => {
  if (isSubmitted) {
    return (
      <SubmittedPermitButtonComponent
        buttonAction={redirectToSIList}
      />
    )
  } else {
    return (
      <DraftPermitButtonComponent
        onEdit={onEdit}
        onDiscard={onDiscard}
        onSubmitPermit={onSubmitPermit}
      />
    )
  }
}

function FormFields({ 
  disabled, 
  showNotification
}) {
  const ComplianceTranslatedObject = ComplianceTranslationText()
  const uiTranslatedTextsObject = CalistaUiComponentTranslationText()

  const { createRecord } = useServices()
  const history = useHistory()
  const location = useLocation()
  const data = location.state.data
  const complianceContainer = data.shippingInfoComplianceCargo?.shippingInfoComplianceContainers || []
  console.log('LOCATION DATA', data)
  const documents = getDocumentList()
  const [loading, setLoading] = useState(false)
  const prevMenu = location.state.prevMenu
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [permitStep, setPermitStep] = useState(1)
  const isSubmitted = location.state.isSubmitted || false
  const ComplianceInvoices = data.shippingInfoComplianceInvoices || []
  const [withPay, setWithPay] = useState(false)


  const { success: showSuccessNotification, error: showErrorNotification } =
    useDefaultNotification(showNotification)

  function getDocumentList() {
    if (location.state.data.isSubmitted) {
      //step 3 , booking document only
      return location.state.data.shippingInfoComplianceSupportDoc == null
        ? []
        : location.state.data.shippingInfoComplianceSupportDoc
    } else {
      //step 2 , combine document and pending document list
      let pendingList =
        location.state.data.pendingShippingInfoComplianceSupportDoc == null
          ? []
          : processPendingDocList(
              location.state.data.pendingShippingInfoComplianceSupportDoc
            )
      return location.state.data.pendingShippingInfoComplianceSupportDoc == null
        ? pendingList
        : location.state.data.pendingShippingInfoComplianceSupportDoc
    }
  }

  function onError(error) {
    console.log('connection-request error', error.message)
    setLoading(false)
    showErrorNotification(CommonUtils.getErrorMessage(error))
  }

  function processPendingDocList(pendingDocList) {
    console.log('testdoc, pendingDocList start', pendingDocList)
    pendingDocList.forEach((doc) => {
      doc.docType = doc.fields[0].value
      doc.description = doc.fields[1].value
    })

    console.log('testdoc, pendingDocList end', pendingDocList)
    return pendingDocList
  }

  const onEdit = () => {
    console.log("Back to edit")
    history.push({
      pathname: pathMap.CREATE_NEW_PERMIT,
      state: {
        shippingInfoCompliance: {
          ...data,
          shippingInfoId: data.shippingInfoId,
          shipperPartyId: data.shipperPartyId,
          carrierPartyId: data.carrierPartyId
        }, 
        prevMenu, 
        fromPermitReview: true,
        inPermit: location.state.inPermit
      }
    })
  }

  const onDiscard = () => {
    setConfirmDialogOpen(true)
  }

  function onSubmitPermit() {
    setLoading(true)
    console.log('submit permit')

    if(data.carType.startsWith("I") && data.decType == "BKT")
    {
      data.shippingInfoComplianceTransport = null
    }

    if(
      data.pendingShippingInfoComplianceSupportDoc &&
      !Utils.isEmpty(data.pendingShippingInfoComplianceSupportDoc)
    ){
      uploadDocumentAndProceed(true)
    }else{
      proceedFirstAction(data)
    }
  }

  function uploadDocumentAndProceed(isFirstAction){
    setLoading(true)
    const formData = new FormData()
    console.log(data.pendingShippingInfoComplianceSupportDoc)

    data.pendingShippingInfoComplianceSupportDoc.map((file, i) =>{
      const fileBlob = file.file
      console.log(fileBlob)
      return formData.append('fileContents', fileBlob)
    })

    function loadDocumentInfoToSICompliance(response){
      console.log('connection-request onSuccess', response)
      console.log('isFirstAction' + isFirstAction)
      if (response.errorMessages != null) {
        showErrorNotification(response.errorMessages)
      } else {

        console.log(response)
        let siComplianceSupportDocs = data.shippingInfoComplianceSupportDocs || []
        if(response && response.length > 0){
          response.forEach((file, i) => {
            let siDoc = {
              complianceDocId: data.pendingShippingInfoComplianceSupportDoc[i].fields[0].value,
              fileName: file.fileName,
              description: data.pendingShippingInfoComplianceSupportDoc[i].fields[1].value,
              docId: file.id,
              fileSize: file.fileSize
            }

            siComplianceSupportDocs.push(siDoc)
          })
        }

        data.shippingInfoComplianceSupportDocs = siComplianceSupportDocs

        console.log(data)
        proceedFirstAction(data)

      }
    }

    createRecord.execute(
      ComplianceApiUrls.DOCUMENT_UPLOAD,
      formData,
      loadDocumentInfoToSICompliance,
      onError
    )

  }

  function proceedFirstAction(newPermitData){
    console.log('proceedFirstAction', newPermitData)
    let url = ComplianceApiUrls.UPDATE_SUBMIT

    function onSuccess(response){
      console.log('connection-request onSuccess', response)
      if (response.errorMessages != null) {
        showErrorNotification(response.errorMessages)
        setLoading(false)
      }else{
        showSuccessNotification(`Transaction: ${response.transactionId} created successfully`)
        console.log('response', response)
        newPermitData = response
        newPermitData.permitSubmitted = true
        newPermitData.permitStatus = response.complianceStatus
        newPermitData.updatedDate = response.updatedDate
        newPermitData.complianceId = response.complianceId

        setLoading(false)

        navigateToSubmittedPage(newPermitData)
      }
    }

    createRecord.execute(
      url,
      newPermitData,
      onSuccess,
      onError
    )
  }

  function navigateToSubmittedPage(newPermitData){

    history.push({
      pathname: pathMap.CREATE_PERMIT_NEW_PERMIT_SUBMITTED,
      state: { data: newPermitData, prevMenu, isSubmitted: true }
    })

  }

  const redirectToSIList = () => {
    history.push({
      pathname: pathMap.MANAGE_PERMIT
    })
  }

  useEffect(() => {
    console.log(data)
    if(isSubmitted){
      setPermitStep(2)
    }

    if(data.carType.startsWith('IPT')){
      setWithPay(true)
    }
  })

  const newPermitSteps = [
    ComplianceTranslatedObject.stepLabelFillupDetails,
    ComplianceTranslatedObject.stepLabelReviewDetails,
    ComplianceTranslatedObject.stepLabelFinish
  ]

  return (
    <Box>
      <CngGridItem xs={12} sm={9}>
        <CngBackdrop loading={loading} />
      </CngGridItem>
      <Paper>
        <Box alignItems='center' pr={15} pl={15}>
          <GeneralStepperComponent
            steps={newPermitSteps}
            activeStep={permitStep}
          />
        </Box>
        {/* Success msg here */}
        <Box p={5}>
          <Box pt={5}>
              <CngGridItem xs={12} sm={12}>
                  <ViewPermitTypeComponent.FormBody 
                      permitTypeData = {data} 
                      inPermit = {location.state.inPermit}
                      withPay = {withPay}
                  /> 
              </CngGridItem>
          </Box>

          <Box pt={5}>
              <CngGridItem xs={12} sm={12}>
                  <ViewCargoComponent.FormBody 
                      cargoData = {data} 
                  /> 
              </CngGridItem>
          </Box>

          {complianceContainer.length > 0 && (
            <Box pt={5}>
                <CngGridItem xs={12} sm={12}>
                    <ViewContainerComponent.FormBody 
                        containers = {complianceContainer} 
                    /> 
                </CngGridItem>
            </Box>
          )} 

          {data.shippingInfoComplianceTransport == null || data.decType == "BKT" ? (
            <></>
          ) : 
          (
            <Box pt={5}>
                <CngGridItem xs={12} sm={12}>
                    <ViewTransportComponent.FormBody 
                        transportData = {data} 
                    /> 
                </CngGridItem>
            </Box>
          )}

          <Box pt={5}>
              <CngGridItem xs={12} sm={12}>
                  <ViewPartyComponent.FormBody 
                      partiesData = {data} 
                      isReview = {true}
                      showNotification={showNotification}
                  /> 
              </CngGridItem>
          </Box>

          <Box pt={5}>
              <CngGridItem xs={12} sm={12}>
                  <ViewLicenceComponent.FormBody 
                      licenceData = {data} 
                  /> 
              </CngGridItem>
          </Box>

          {documents.length > 0 && (
            <Box pt={5}>
              <ViewDocumentComponent.FormBody document={documents} />
            </Box>
          )}
          
          {data.shippingInfoComplianceItems == null ? (
            <></>
          ) : (
            <Box pt={5}>
              <ItemsComponent.FormBody
                isView={true}
                itemData={data}
              />
            </Box>
          )}
          <Box pt={5}>
              <CngGridItem xs={12} sm={12}>
                  <ViewItemSummaryComponent.FormBody 
                      summaryData = {data} 
                  /> 
              </CngGridItem>
          </Box>

          {!withPay ? (
            <></>
          ) : (
            <Box pt={5}>
              <CngGridItem xs={12} sm={12}>
                  <ViewInvoiceComponent.FormBody 
                      invoices = {ComplianceInvoices} 
                  /> 
              </CngGridItem>
            </Box>
          )}      
        </Box>
      </Paper>

      <Paper className='sticky-footer'>
        <Box alignItems='center'>
          <ReviewPermitButtonLayout 
            isSubmitted={isSubmitted}
            onEdit={onEdit} 
            onDiscard={onDiscard} 
            onSubmitPermit={onSubmitPermit} 
            redirectToSIList={redirectToSIList}  
          />
        </Box>
      </Paper>

      <ConfirmDialog
        isConfirmDialogOpen={confirmDialogOpen}
        closeDialog={() => setConfirmDialogOpen(false)}
        confirmDialog={redirectToSIList}
        content={ComplianceTranslatedObject.dialogDiscardPermitContent}
        okMsg={uiTranslatedTextsObject.ok}
        cancelMsg={uiTranslatedTextsObject.cancel}
        title={ComplianceTranslatedObject.dialogDiscardPermitTitle}
      />
    </Box>
  )
}

const ReviewPermitFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  FormFields: FormFields
})

export default ReviewPermitFormProperties
