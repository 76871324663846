import { Grid } from '@mui/material'
import { Box, Chip } from '@material-ui/core'
import React, { forwardRef } from 'react'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import ChevronRight from '@material-ui/icons/ChevronRight'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import Search from '@material-ui/icons/Search'
import Clear from '@material-ui/icons/Clear'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import _ from 'lodash'

const Statuses = [{
  name: 'Cancelled', color: '#babbbc'
}, {
  name: 'Created', color: '#ced9fc'
}, {
  name: 'Delivered', color: '#fbeccd'
}, {
  name: 'Ready', color: '#d7f8e6'
}]

const getStatusByName = name => Statuses.find((status) => status.name === name)

const preventAddingNonNumeric = () => (evt) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()

const getRenderOptionForOptionsHavingNameAndCode = () => (props) => {
  return (
    <>
      <Grid item xs={8}>
        {props.data.name}
      </Grid>
      <Grid item xs={4}>
        <Box display='flex' justifyContent='flex-end'>
          <Chip label={<b>{props.data.code}</b>} size='small' />
        </Box>
      </Grid>
    </>
  )
}
const getTextFieldPropsForOptionsHavingNameAndCode = fieldValue => ({
  InputProps: {
    customEndAdornment: (autocompleteEndAdornment) => {
      if (fieldValue) {
        return (
          <Box display='flex' justifyContent='flex-end'>
            <Chip label={<b>{fieldValue}</b>} size='small' />
            {autocompleteEndAdornment}
          </Box>
        )
      }
    }
  }
})

const convertFormDataForAPI = (formData) => {

  return {
    'consignee': {
      'address': {
        'city': formData.consignee ? formData.consignee.city : null,
        'country': formData.consignee ? formData.consignee.country : null,
        'postcode': formData.consignee ? formData.consignee.postalCode : null,
        'state': formData.consignee && formData.consignee.country && formData.consignee.state ? formData.consignee.country + formData.consignee.state : null,
        'stateOther': formData.consignee ? formData.consignee.otherState : null,
        'street': formData.consignee ? formData.consignee.street : null,
        'type': formData.consignee ? formData.consignee.addressType : null
      },
      'companyName': formData.consignee ? formData.consignee.companyName : null,
      'email': formData.consignee ? formData.consignee.email : null,
      'fax': formData.consignee ? formData.consignee.fax : null,
      'firstName': formData.consignee ? formData.consignee.firstName : null,
      'lastName': formData.consignee ? formData.consignee.lastName : null,
      'phone': formData.consignee ? formData.consignee.phone : null,
      'taxNo': formData.consignee ? formData.consignee.taxNo : null
    },
    'deliveryOrderRefNo': formData.deliveryOrderRefNo,
    'deliveryOrderStatus': formData.deliveryOrderStatus,
    'destinationCountry': formData.destinationCountry,
    'invoice': {
      'invoiceNo': formData.invoiceNo,
      'invoiceDate': formData.invoiceDate
    },
    'originCountry': formData.originCountry,
    'otherRef': formData.otherRef,
    'packages': {
      'dimensionUOM': formData.dimensionUOM,
      'height': formData.height,
      'item': formData.item,
      'length': formData.length,
      'packageType': formData.packageType,
      'volWeight': formData.volWeight,
      'volWeightUOM': formData.volWeightUOM,
      'weight': formData.weight,
      'weightUOM': formData.weightUOM,
      'width': formData.width
    },
    'salesOrder': {
      'salesOrderNo': formData.salesOrderNo,
      'currency': formData.currency,
      'grandTotal': formData.grandTotal,
      'declaredAmount': formData.declaredAmount
    },
    'shipmentProvider': formData.shipmentProvider,
    'shipperAddressTemplate': formData.shipperAddressTemplate,
    'shipper': {
      'address': {
        'city': formData.shipper ? formData.shipper.city : null,
        'country': formData.shipper ? formData.shipper.country : null,
        'postcode': formData.shipper ? formData.shipper.postalCode : null,
        'state': formData.shipper && formData.shipper.country && formData.shipper.state ? formData.shipper.country + formData.shipper.state : null,
        'stateOther': formData.shipper ? formData.shipper.otherState : null,
        'street': formData.shipper ? formData.shipper.street : null,
        'type': formData.shipper ? formData.shipper.addressType : null
      },
      'companyName': formData.shipper ? formData.shipper.companyName : null,
      'email': formData.shipper ? formData.shipper.email : null,
      'fax': formData.shipper ? formData.shipper.fax : null,
      'firstName': formData.shipper ? formData.shipper.firstName : null,
      'lastName': formData.shipper ? formData.shipper.lastName : null,
      'phone': formData.shipper ? formData.shipper.phone : null,
      'taxNo': formData.shipper ? formData.shipper.taxNo : null
    },
    'trackingNo': formData.trackingNo
  }
}

const convertDataFromAPIToFormData = (data) => {
  return {
    deliveryOrderRefNo: data.deliveryOrderRefNo,
    shipmentProvider: data.shipmentProvider,
    deliveryOrderStatus: data.deliveryOrderStatus,
    trackingNo: data.trackingNo,
    otherRef: data.otherRef,
    originCountry: data.originCountry,
    destinationCountry: data.destinationCountry,
    salesOrderNo: data.salesOrder.salesOrderNo,
    currency: data.salesOrder.currency,
    grandTotal: data.salesOrder.grandTotal ? data.salesOrder.grandTotal.toFixed(2) : null,
    declaredAmount: data.salesOrder.declaredAmount ? data.salesOrder.declaredAmount.toFixed(2) : null,
    invoiceNo: data.invoice.invoiceNo,
    invoiceDate: data.invoice.invoiceDate,
    weight: data.packages.weight,
    weightUOM: data.packages.weightUOM,
    volWeight: data.packages.volWeight,
    volWeightUOM: data.packages.volWeightUOM,
    item: data.packages.item,
    length: data.packages.length,
    width: data.packages.width,
    height: data.packages.height,
    dimensionUOM: data.packages.dimensionUOM,
    packageType: data.packages.packageType,
    shipperAddressTemplate: data.shipperAddressTemplate,
    shipper: {
      companyName: data.shipper.companyName,
      email: data.shipper.email,
      fax: data.shipper.fax,
      firstName: data.shipper.firstName,
      lastName: data.shipper.lastName,
      phone: data.shipper.phone,
      taxNo: data.shipper.taxNo,
      city: data.shipper.address.city,
      country: data.shipper.address.country,
      state: data.shipper.address.state ? removeFirstOccurrence(data.shipper.address.state, data.shipper.address.country) : null,
      stateOther: data.shipper.address.stateOther,
      street: data.shipper.address.street,
      addressType: data.shipper.address.type,
      postalCode: data.shipper.address.postcode
    },
    consignee: {
      companyName: data.consignee.companyName,
      email: data.consignee.email,
      fax: data.consignee.fax,
      firstName: data.consignee.firstName,
      lastName: data.consignee.lastName,
      phone: data.consignee.phone,
      taxNo: data.consignee.taxNo,
      city: data.consignee.address.city,
      country: data.consignee.address.country,
      state: data.consignee.address.state ? removeFirstOccurrence(data.consignee.address.state, data.consignee.address.country) : null,
      stateOther: data.consignee.address.stateOther,
      street: data.consignee.address.street,
      addressType: data.consignee.address.type,
      postalCode: data.consignee.address.postcode
    }
  }
}

const removeFirstOccurrence = (givenText, searchString) => {
  const index = givenText.indexOf(searchString)
  if (index === -1) {
    return givenText
  }
  return givenText.slice(0, index) + givenText.slice(index + searchString.length)
}


const tableIcons = {
  // * https://www.freakyjolly.com/react-material-table-how-to-show-icons-in-action-and-other-components/
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  Search: forwardRef((props, ref) => (
    <Search {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => (
    <ArrowDropDownIcon {...props} ref={ref} />
  )),
  ViewColumn: forwardRef((props, ref) => <FontAwesomeIcon icon='fa-table-columns' style={{ marginLeft: '1em' }} />)
}

const base64toBlob = (base64Data) => {
  let sliceSize = 1024
  let byteCharacters = atob(base64Data)
  let bytesLength = byteCharacters.length
  let slicesCount = Math.ceil(bytesLength / sliceSize)
  let byteArrays = new Array(slicesCount)

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    let begin = sliceIndex * sliceSize
    let end = Math.min(begin + sliceSize, bytesLength)

    let bytes = new Array(end - begin)
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0)
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes)
  }
  return byteArrays
}

const errorMsgContent = (error, refNo) => {
  let today = moment(new Date()).format('DD/MM/YYYY')

  let content = <span>
    <p>Failed to generate shipping label, If you need assistance, 
      please send an email to calista-support@globaletrade.services and quote the following info:</p>
    <p>Delivery Order: {refNo}</p>
    <p>Action: Label</p>
    <p>Timestamp: {today}</p>
    <p>Info: {error}</p>
    </span>

  return content
}

const convertMessageReceivedFromDeliveryOrderAPI = (inputText) => {
  return _.replace(inputText, 'DELIVERY_ORDER_REF_NO already exists', 'Delivery order ref no already exists')
}

export {
  getStatusByName as getStatusByName,
  preventAddingNonNumeric as preventAddingNonNumeric,
  getRenderOptionForOptionsHavingNameAndCode as getRenderOptionForOptionsHavingNameAndCode,
  getTextFieldPropsForOptionsHavingNameAndCode as getTextFieldPropsForOptionsHavingNameAndCode,
  convertFormDataForAPI as convertFormDataForAPI,
  convertDataFromAPIToFormData as convertDataFromAPIToFormData,
  tableIcons as tableIcons,
  base64toBlob as base64toBlob,
  errorMsgContent as errorMsgContent,
  convertMessageReceivedFromDeliveryOrderAPI as convertMessageReceivedFromDeliveryOrderAPI
}


