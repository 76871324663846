import { Grid, Box, Card, Typography, makeStyles, Tooltip } from '@material-ui/core'
import { components, useServices } from 'cng-web-lib'
import React, { useState, useCallback, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import DeliveryOrderUploadApiUrls from 'src/apiUrls/ecommerce/upload/DeliveryOrderUploadApiUrls'
import UploadFormProperties from './UploadFormProperties'
import DoUploadValidationSchema from './DoUploadValidationSchema'
import TranslationText from './TranslatedText'
import Utils from 'src/views/common/utils/Utils'
import TablePagination from '../label/TablePagination'
import CustomToolbar from '../label/CustomToolbar'
import clsx from 'clsx'

// Font Awesome imports.
import { fal as FontAwesomeLight } from '@fortawesome/pro-light-svg-icons'
import { library as FontAwesomeLibrary } from '@fortawesome/fontawesome-svg-core'

FontAwesomeLibrary.add(FontAwesomeLight)

const {
      table: { CngCrudTable, useDefaultNotification },
      form: { CngViewForm },
      CngMoreActionsMenu
} = components

const DATE_FORMAT = "DD-MMM-YYYY, HH:mm"

const moreActionsList = [
      {
            action: 'download',
            name: 'Download',
            icon: ['fal', 'download']
      }
]

export const useStyles = makeStyles((theme) => ({
      table: {
            '& thead th': {
                  backgroundColor: theme.palette.component.cngMuiTable.tableHeaderCellFill,
                  textTransform: 'uppercase',
                  fontWeight: theme.typography.fontWeightBold,
                  fontSize: '.75rem',
                  color: '#8181A5',
                  lineHeight: 1.2,
                  padding: theme.spacing(1, 2),
                  '&:not(:first-child):not(:last-child)': {
                        padding: theme.spacing()
                  }
            },
            '& tbody td': {
                  fontSize: '.75rem',
                  color: theme.palette.text.gray900,
                  '&.filter': {
                        backgroundColor: theme.palette.component.cngMuiTable.tableHeaderCellFill
                  },
                  padding: theme.spacing(1, 2),
                  '&:not(:first-child):not(:last-child)': {
                        padding: theme.spacing()
                  }
            },
            '& td, & th': {
                  borderColor: theme.palette.background.ctaBtnActiveBorder
            },
            '& tbody tr:hover': {
                  backgroundColor: theme.palette.component.cngMuiTable.tableHeaderCellFill
            }
      },
      action: {
            color: theme.palette.text.textSecondary,
            borderColor: theme.palette.background.ctaBtnActiveBorder,
            borderStyle: 'solid',
            borderWidth: 1,
            borderRadius: theme.shape.borderRadius * 2,
            height: 36, width: 36,
            fontSize: '.875rem'
      },
      boldText: {
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '.875rem'
      },
      primaryText: {
            fontSize: '.875rem'
      },
      secondaryText: {
            fontSize: '.75rem',
            color: theme.palette.text.textSecondary
      },
      limitedText: {
            maxWidth: '16em',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
      },
      colorBlue: {
            color: theme.palette.primary.main
      },
      colorGreen: {
            color: theme.palette.success.main
      },
      colorRed: {
            color: theme.palette.text.error
      },
      // cell classes
      ellipsize: {
            maxWidth: '20em',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
      },
      font: {
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '.785rem'
      },
      desc: {
            fontSize: '.7rem',
            color: theme.palette.text.textSecondary
      },
      red: {
            color: 'red'
      },
      green: {
            color: 'green'
      }

}))

function UploadPage(props) {
      const {
            showNotification
      } = props

      const {
            success: showSuccessNotification,
            error: showErrorNotification
      } = useDefaultNotification(showNotification)
      const notification = useDefaultNotification(showNotification)

      const classes = useStyles()
      const translatedTextsObject = TranslationText();
      const { createRecord } = useServices()
      const history = useHistory()
      const validationSchema = DoUploadValidationSchema()

      const [seed, setSeed] = useState(1);

      //File List Table
      const [submitting, setSubmitting] = useState(false)
      const tableRef = useRef()
      const inputRef = useRef()
      const [pageSize] = useState(5)
      const [searchText, setSearchText] = useState('')

      const tableRefStateCallback = useCallback(() => {
            return tableRef.current?.state
      }, [])

      function onSubmitUpload(submittedData) {
            uploadDocument(submittedData.pendingDocuments)

      }

      function uploadDocument(docData) {
            setSubmitting(true)
            let formData = new FormData();

            docData.map((file, i) => {
                  let fileBlob = file.file
                  formData.append("fileContents", fileBlob)
                  formData.append("docType", docData[i].fields[0].value)
            })

            function onSuccess(response) {
                  setSubmitting(false)
                  if (response.errorMessages != null) {
                        showErrorNotification(response.errorMessages)
                  } else {
                        setSeed(seed + 1);
                        onApplyClicked()

                        if (!response.isSuccess) {
                              showErrorNotification(translatedTextsObject.uploadErrorMessage)
                        } else {
                              showSuccessNotification(translatedTextsObject.savedSuccessMessage)
                        }

                  }

            }

            function onError(error) {
                  console.log(error)
                  setSubmitting(false)
                  showErrorNotification(error.message)
            }

            createRecord.execute(
                  DeliveryOrderUploadApiUrls.UPLOAD_FILE,
                  formData,
                  onSuccess,
                  onError
            )
      }

      const onApplyClicked = () => {
            tableRef.current.onQueryChange({
                  filters: [
                        {
                              column: {
                                    field: 'fileContentFilename',
                                    type: 'AND' // not used, but required, can just put a random type first
                              },
                              value: searchText
                        }
                  ],
                  page: 0
            })
            return (false);
      }

      let bottomPaginationBar = (props) => (
            <>
                  <Box mt={2}>
                        <TablePagination  {...props} />
                  </Box>
            </>
      )

      let customToolBar = (props) => (
            <>
                  <CustomToolbar
                        inputRef={inputRef}
                        page={tableRefStateCallback()?.query?.page || 0}
                        pageSize={tableRefStateCallback()?.pageSize || 0}
                        totalResult={tableRefStateCallback()?.query?.totalCount || 0}
                        placeholderText={translatedTextsObject.searchMessage}
                        searchText={searchText}
                        setSearchText={setSearchText}
                        onApplyClicked={onApplyClicked}
                  />
            </>
      )

      const downloadUploadedFile = (row) => {
            let blob = new Blob(base64toBlob(row.fileContent), {
                  type: 'application/vnd.ms-excel'
            })
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = row.fileContentFilename
            link.click()

            showSuccessNotification(translatedTextsObject.downloadSuccessMessage)

      }

      function base64toBlob(base64Data) {
            let sliceSize = 1024
            let byteCharacters = atob(base64Data)
            let bytesLength = byteCharacters.length
            let slicesCount = Math.ceil(bytesLength / sliceSize)
            let byteArrays = new Array(slicesCount)

            for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
                  let begin = sliceIndex * sliceSize
                  let end = Math.min(begin + sliceSize, bytesLength)

                  let bytes = new Array(end - begin)
                  for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
                        bytes[i] = byteCharacters[offset].charCodeAt(0)
                  }
                  byteArrays[sliceIndex] = new Uint8Array(bytes)
            }
            return byteArrays
      }

      const columns = [
            {
                  title: translatedTextsObject.filename + " / " + translatedTextsObject.templateType,
                  field: 'fileUploadTypeId',
                  filtering: false,
                  render: (rowData) =>
                        <Box className={clsx(classes.font, classes.ellipsize)}>
                              {rowData.fileContentFilename}
                              <br />
                              <Typography className={classes.desc}>
                                    {rowData.fileUploadTypeStr}
                              </Typography>
                        </Box>
            },
            {
                  title: translatedTextsObject.uploadDate, field: 'fileContentDateCreated', filtering: false, defaultSort: 'desc', render: rowData =>
                        <Box>
                              {Utils.formatDate(rowData.fileContentDateCreated, DATE_FORMAT)}
                        </Box>

            },
            {
                  title: translatedTextsObject.noOfEntries, field: 'noOfRecords', filtering: false, render: rowData =>
                        <Typography className={classes.font}>{rowData.noOfRecords}</Typography>

            },
            {
                  title: translatedTextsObject.systemResponse, field: 'additionalInfo', filtering: false, render: rowData =>
                        <Tooltip title={rowData.additionalInfo}><Box className={classes.ellipsize}>
                              {rowData.additionalInfo}
                        </Box>
                        </Tooltip>
            }, {
                  title: translatedTextsObject.status, field: 'isSuccess', filtering: false, render: rowData =>
                        <Box>
                              {rowData.isSuccess
                                    ? <Typography className={clsx(classes.font, classes.green)}>{translatedTextsObject.completed}</Typography>
                                    : <Typography className={clsx(classes.font, classes.red)}>{translatedTextsObject.failed}</Typography>
                              }
                        </Box>
            },
            {
                  title: translatedTextsObject.action, field: 'id',
                  sorting: false,
                  filtering: false,
                  render: rowData => (
                        <CngMoreActionsMenu
                              type='secondary'
                              className={classes.action}
                              menuItems={moreActionsList}
                              iconDirection='horizontal'
                              data={rowData}
                              onActionSelect={downloadUploadedFile}

                        />

                  )
            }
      ]

      return (
            <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                        <CngViewForm
                              fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                              history={history}
                              showNotification={showNotification}
                              bodySection={
                                    <>
                                          <UploadFormProperties.FormFields
                                                key={seed}
                                                showNotification={showNotification}
                                                submitting={submitting}

                                          />
                                    </>
                              }
                              formikProps={{
                                    ...UploadFormProperties.formikProps,
                                    validationSchema,
                                    onSubmit: onSubmitUpload
                              }}
                        />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                        <Box component={Card} className={classes.table}>
                              <h5 style={{ paddingLeft: '1em', paddingTop: '1em', fontWeight: '600' }}>
                                    {translatedTextsObject.uploadedFilesTitle}</h5>
                              <CngCrudTable
                                    {...props}
                                    fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
                                    columns={columns}
                                    pageSize={pageSize}
                                    components={{
                                          Pagination: bottomPaginationBar
                                    }}
                                    fetch={{
                                          url: DeliveryOrderUploadApiUrls.GET_UPLOADFILE_LIST
                                    }}
                                    idAccessor="id"
                                    notification={notification}
                                    tableRef={(ref) => {
                                          if (ref === null) {
                                                return
                                          }
                                          tableRef.current = ref
                                    }}
                                    customToolbar={customToolBar}
                              />
                        </Box>
                  </Grid>
            </Grid>
      )
}

export default UploadPage