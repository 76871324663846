import { Yup } from 'cng-web-lib'
import ValidationMessageTranslationText from './ValidationMessageTranslationText'

function useComplianceLicenceValidationSchema(){
    const validationMessageTranslation = ValidationMessageTranslationText()

    return Yup.object().shape({
        shippingInfoComplianceLicence:Yup.object().shape({
            licNo1: Yup.string()
                .required(validationMessageTranslation.requiredMessage)
                .nullable()
        })
    })
}

export default useComplianceLicenceValidationSchema