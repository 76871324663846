import Namespace from '../../../constants/locale/Namespace'
import ShipmentProviderKeys from '../../../constants/locale/key/cec/ShipmentProviderKeys'
import DeliveryOrdersKeys from '../../../constants/locale/key/cec/DeliveryOrdersKeys'
import EcommerceLabelKeys from '../../../constants/locale/key/EcommerceLabel'
import { constants, useTranslation } from 'cng-web-lib'

const NAMESPACE = Namespace.SHIPMENT_PROVIDERS
const LABEL_NAMESPACE = Namespace.ECOMMERCE_LABEL
const {
  locale: {
    key: {
      UiComponentKeys
    }
  }
} = constants

const TranslationText = () => {

  const { translate } = useTranslation([NAMESPACE, LABEL_NAMESPACE])

  const moduleTitle = translate(NAMESPACE, DeliveryOrdersKeys.MODULE_TITLE)
  const titleDeliveryOrderList = translate(NAMESPACE, DeliveryOrdersKeys.DELIVERY_ORDER_LIST)
  const titleDeliveryOrderCreate = translate(NAMESPACE, DeliveryOrdersKeys.CREATE_DELIVERY_ORDER)
  const titleDeliveryOrderEdit = translate(NAMESPACE, DeliveryOrdersKeys.EDIT_DELIVERY_ORDER)
  const titleDeliveryOrderView = translate(NAMESPACE, DeliveryOrdersKeys.VIEW_DELIVERY_ORDER)
  const lastMileProviderLabel = translate(NAMESPACE, DeliveryOrdersKeys.LAST_MILE_PROVIDER)
  const statusLabel = translate(NAMESPACE, DeliveryOrdersKeys.STATUS)
  const deliveryOrderRefNoLabel = translate(NAMESPACE, DeliveryOrdersKeys.DELIVERY_ORDER_REF_NO)
  const serviceStatusLabel = translate(NAMESPACE, DeliveryOrdersKeys.SERVICE_STATUS)
  const deliveryOrderSysNoLabel = translate(NAMESPACE, DeliveryOrdersKeys.DELIVERY_ORDER_SYSTEM_NO)
  const tackingNoLabel = translate(NAMESPACE, DeliveryOrdersKeys.TRACKING_NO)
  const serviceStartDateLabel = translate(NAMESPACE, DeliveryOrdersKeys.SERVICE_START_DATE)
  const serviceEndDateLabel = translate(NAMESPACE, DeliveryOrdersKeys.SERVICE_END_DATE)
  const creationStartDateLabel = translate(NAMESPACE, DeliveryOrdersKeys.CREATION_START_DATE)
  const creationEndDateLabel = translate(NAMESPACE, DeliveryOrdersKeys.CREATION_END_DATE)
  const printLabelStatusLabel = translate(NAMESPACE, DeliveryOrdersKeys.PRINT_LABEL_STATUS)
  const printLabelStatusFilterLabel = translate(NAMESPACE, DeliveryOrdersKeys.PRINT_LABEL_STATUS_FILTER)
  const creationOnLabel = translate(NAMESPACE, DeliveryOrdersKeys.CREATED_ON)
  const actionLabel = translate(NAMESPACE, DeliveryOrdersKeys.ACTION)


  const titleShipmentProviderList = translate(NAMESPACE, ShipmentProviderKeys.SHIPMENT_PROVIDER_LIST)
  const titleShipmentProviderCreate = translate(NAMESPACE, ShipmentProviderKeys.CREATE_SHIPMENT_PROVIDER)
  const titleShipmentProviderEdit = translate(NAMESPACE, ShipmentProviderKeys.EDIT_SHIPMENT_PROVIDER)

  //Shipment Provider
  const shipmentProviderName = translate(NAMESPACE, ShipmentProviderKeys.SHIPMENT_PROVIDER_NAME)
  const shipmentProviderCode = translate(NAMESPACE, ShipmentProviderKeys.SHIPMENT_PROVIDER_CODE)
  const isActive = translate(NAMESPACE, ShipmentProviderKeys.IS_ACTIVE)
  const isManagedByGeTS = translate(NAMESPACE, ShipmentProviderKeys.IS_MANAGED_BY_GETS)
  const packageTypes = translate(NAMESPACE, ShipmentProviderKeys.PACKAGE_TYPES)

  const defaultShipperDetails = translate(NAMESPACE, ShipmentProviderKeys.SHIPPER_DETAILS_TITLE)
  const defaultConsigneeDetails = translate(NAMESPACE, ShipmentProviderKeys.CONSIGNEE_DETAILS_TITLE)

  // Tabs
  const shipmentProviderDetails = translate(NAMESPACE, ShipmentProviderKeys.SHIPMENT_PROVIDER_DETAILS)
  const connectorConfigurations = translate(NAMESPACE, ShipmentProviderKeys.CONNECTOR_CONFIGURATIONS)
  const tabLockedMessage = translate(Namespace.UI_COMPONENT, UiComponentKeys.Tabs.PLEASE_SUBMIT_FIRST, {
    title: shipmentProviderDetails
  })

  // Mass Print
  const generateMessage = translate(LABEL_NAMESPACE, EcommerceLabelKeys.GENERATE_MSG)
  const multipleProviderMessage = translate(LABEL_NAMESPACE, EcommerceLabelKeys.MULTIPLE_PROVIDER_MSG)
  const printedMessage = translate(LABEL_NAMESPACE, EcommerceLabelKeys.PRINTED_MSG)
  const confirmMessage = translate(LABEL_NAMESPACE, EcommerceLabelKeys.CONFIRM_MSG)
  const warningTitle = translate(LABEL_NAMESPACE, EcommerceLabelKeys.WARNING_TITLE)
  const errorTitle = translate(LABEL_NAMESPACE, EcommerceLabelKeys.ERROR_TITLE)
  const generatingLabelTitle = translate(LABEL_NAMESPACE, EcommerceLabelKeys.GENERATING_LABEL_TITLE)
  const shipmentProviderNotConfigureMessage = translate(LABEL_NAMESPACE, EcommerceLabelKeys.SHIPMENT_PROVIDER_NOT_CONFIGURE_MSG)


  return {
    moduleTitle,
    lastMileProviderLabel,
    statusLabel,
    deliveryOrderRefNoLabel,
    serviceStatusLabel,
    deliveryOrderSysNoLabel,
    tackingNoLabel,
    serviceStartDateLabel,
    serviceEndDateLabel,
    creationStartDateLabel,
    creationEndDateLabel,
    printLabelStatusLabel,
    printLabelStatusFilterLabel,
    creationOnLabel,
    actionLabel,
    titleShipmentProviderList,
    titleShipmentProviderCreate,
    titleShipmentProviderEdit,
    shipmentProviderName,
    shipmentProviderCode,
    isActive,
    isManagedByGeTS,
    packageTypes,
    shipmentProviderDetails,
    tabLockedMessage,
    defaultShipperDetails,
    defaultConsigneeDetails,
    connectorConfigurations,
    titleDeliveryOrderList,
    titleDeliveryOrderCreate,
    titleDeliveryOrderEdit,
    titleDeliveryOrderView,
    generateMessage,
    multipleProviderMessage,
    printedMessage,
    confirmMessage,
    warningTitle,
    errorTitle,
    generatingLabelTitle,
    shipmentProviderNotConfigureMessage
  }
}
export default TranslationText


