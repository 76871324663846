import React from 'react'
import { Typography } from '@material-ui/core'
import CloseDialogIconButton from 'src/components/iconbutton/CloseDialogIconButton.js'
import DownloadTemplateDialogForm from './DownloadTemplateDialogForm.js'
import Paper from '@material-ui/core/Paper'
import { components } from 'cng-web-lib'

const { CngDialog } = components

function DownloadTemplateDialog({
  isDialogOpen,
  closeDialog,
  dialogTitle
}) {

  return (
      <CngDialog
        customDialogContent={
          <Paper spacing={2}>
            <div style={{ overflow: 'hidden' }}>
              <DownloadTemplateDialogForm closeDialog={closeDialog}/>
            </div>
          </Paper>
        }
        dialogTitle={
          <>
            <Typography variant='h5' className='font-bolder' style={{padding:'8px'}}>
              {dialogTitle}
            </Typography>
            <CloseDialogIconButton
              onClick={closeDialog}
            />
          </>
        }
        open={isDialogOpen}
        fullWidth
        maxWidth='md'
      />
  )
}

export default DownloadTemplateDialog
