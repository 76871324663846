import { useHistory } from 'react-router-dom'
import React from 'react'
import { components } from 'cng-web-lib'
import ReviewPermitFormProperties from './ReviewPermitFormProperties'

const {
  form: { CngViewForm }
} = components

function ReviewPermitPage({ showNotification }) {
  const history = useHistory()

  function onSubmit(data) {
    console.log('submit', data)
  }

  return (
    <CngViewForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      history={history}
      showNotification={showNotification}
      bodySection={
        <ReviewPermitFormProperties.FormFields
          disabled={false}
          showNotification={showNotification}
        />
      }
      formikProps={{
        ...ReviewPermitFormProperties.formikProps,
        onSubmit: onSubmit
      }}
    />
  )
}

export default ReviewPermitPage
