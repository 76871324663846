import { useHistory } from 'react-router-dom'
import React, { useState } from 'react'
import BodySection from './BodySection'
import { components } from 'cng-web-lib'

const {
    form: { CngViewForm }
} = components

function ViewPermitPage(props){

    const history = useHistory()
    const [enquired] = useState(false)
    const {showNotification} = props
    const [loading, setLoading] = useState(false)
    
    function onSubmit(data) {
        console.log('submit edit')
    }

    return(

        <CngViewForm
            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
            history={history}
            showNotification={showNotification}
            bodySection={
                <BodySection.FormFields
                disabled={false}
                showNotification={showNotification}
                enquired={enquired}
                {...props}
                loading={loading}
                setLoading={setLoading}
                />
            }
            formikProps={{
                ...BodySection.formikProps,
                onSubmit: onSubmit
            }}
        />
    )
}

export default ViewPermitPage