import React, { useState } from 'react'
import PropTypes from 'prop-types'
import EmptyBox from './EmptyBox'
import SCOAdminApiUrls from '../../../../../apiUrls/SCOAdminApiUrls'
import { constants, useServices } from 'cng-web-lib'


CountryLabel.propTypes = {
  showErrorNotification: PropTypes.func
}

const {
  filter: { EQUAL }
} = constants

function CountryLabel(props) {
  const { countryCode, showErrorNotification } = props
  const [countryName, setCountryName] = useState('')
  const { fetchRecords } = useServices()


  if (countryCode) {
    fetchRecords.execute(
      SCOAdminApiUrls.GET_COUNTRY_LIST,
      {
        filters: [
          {
            field: 'code',
            operator: EQUAL,
            value: countryCode
          }
        ]
      },
      ((response) =>
        response.content.length === 1 && response.content[0].code === countryCode ? setCountryName(response.content[0].name)
          : null),
      () => {
        showErrorNotification('Failed to fetch country details.')
      }
    )

    return (<> <img
      src={process.env.PUBLIC_URL + '/static/images/countries/' + countryCode + '.svg'}
      style={{ width: '1.5rem' }} /> {countryName} </>)
  } else {
    return <EmptyBox />
  }


}

export default CountryLabel
