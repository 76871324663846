import React, { useEffect, useState } from 'react'
import { components, constants, useServices } from 'cng-web-lib'
import { Box, Grid } from '@mui/material'
import { Card, CardContent, Divider } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import DeliveryOrderApiUrls from '../../../../apiUrls/ecommerce/delivery-order/DeliveryOrderApiUrls'
import DeliveryEventApiUrls from '../../../../apiUrls/ecommerce/delivery-order/DeliveryEventApiUrls'
import CngBackdrop from '../../../vesselschedule/searchschedule/cngcomponent/CngBackDrop'
import ViewHeader from '../components/view/ViewHeader'
import MainOrderDetails from '../components/view/MainOrderDetails'
import PackageAndInvoiceDetails from '../components/view/PackageAndInvoiceDetails'
import AddressDetails from '../components/view/AddressDetails'
import ItemsDetails from '../components/view/ItemsDetails'
import EventDetails from '../components/view/EventDetails'
import pathMap from '../../../../paths/PathMap_eCommerce'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SCORateMgmtApiUrls from '../../../../apiUrls/SCORateMgmtApiUrls'
import ConfirmDialog from '../../../../components/dialog/ConfirmDialog'
import { base64toBlob, errorMsgContent } from '../components/util/Utils'
import TranslationText from '../TranslationText'

const {
  CngGridItem,
  table: { useDefaultNotification },
  button: { CngPrimaryButton, CngSecondaryButton }
} = components

const {
  filter: { EQUAL }
} = constants


function ViewPage({ history, showNotification }) {

  const {
    shipmentProviderNotConfigureMessage
  } = TranslationText()

  const { deliveryOrderRefNo } = useParams()
  const [deliveryOrder, setDeliveryOrder] = useState()
  const [salesOrderCurrency, setSalesOrderCurrency] = useState('')
  const [deliveryEvents, setDeliveryEvents] = useState([])
  const { securedSendRequest } = useServices()
  const { fetchRecords } = useServices()
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [dialogContent, setDialogContent] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const {
    error: showErrorNotification
  } = useDefaultNotification(showNotification)

  const onError = (error) => {
    showErrorNotification('Error while retrieving delivery order data.')
  }

  const fetchDeliveryOrderData = () => {
    securedSendRequest.execute(
      'GET',
      DeliveryOrderApiUrls.GET_BY_REF_NO + deliveryOrderRefNo,
      {},
      (request) => {
        if (request && request.status === 200 && request.data && request.data.data) {
          setDeliveryOrder(request.data.data)
        } else {
          showErrorNotification('Error while retrieving delivery order data.')
        }
      },
      onError
    )
  }

  const fetchDeliveryEventData = () => {
    securedSendRequest.execute(
      'GET',
      DeliveryEventApiUrls.GET_BY_REF_NO + deliveryOrderRefNo,
      {},
      (request) => {
        if (request && request.status === 200 && request.data && request.data.data) {
          setDeliveryEvents(request.data.data.deliveryEvents)
        } else {
          showErrorNotification('Error while retrieving delivery event data.')
        }
      },
      onError
    )
  }

  const fetchCurrencyDetails = (deliveryOrder) => {
    if (deliveryOrder && deliveryOrder.salesOrder && deliveryOrder.salesOrder.currency) {
      fetchRecords.execute(
        SCORateMgmtApiUrls.GET_CURRENCY,
        {
          filters: [
            {
              field: 'status',
              operator: EQUAL,
              value: true
            },
            {
              field: 'code',
              operator: EQUAL,
              value: deliveryOrder.salesOrder.currency
            }
          ]
        },
        ((response) =>
          response.content.length == 1 && response.content[0].code === deliveryOrder.salesOrder.currency ? setSalesOrderCurrency(response.content[0])
            : null),
        () => {
          showErrorNotification('Failed to fetch total shipment currency details.')
        }
      )
    }
  }


  useEffect(() => {
    fetchDeliveryOrderData()
  }, [deliveryOrderRefNo])

  useEffect(() => {
    fetchDeliveryEventData()
  }, [deliveryOrderRefNo])

  useEffect(() => {
    fetchCurrencyDetails(deliveryOrder)
  }, [deliveryOrder])

  const singlePrint = (refNo) => {
    const onSuccess = (response) => {
      setIsLoading(false)

      if (response.data != null && response.data.meta.code === 200) {
        let label = response.data.data.packages.shippingLabel
        if (label.url != null && label.url != '') {
          window.open(label.url, '_blank')
        } else {
          let base64ImageData = label.file.base64
          let extension = label.file.extension
          const contentType = `image/${extension}`

          const blob = new Blob(base64toBlob(base64ImageData), { type: contentType })
          const blobUrl = URL.createObjectURL(blob)

          window.open(blobUrl, '_blank')
        }

      } else if (response.data != null && response.data.meta.code === 403) {
        errorMsgDialog(shipmentProviderNotConfigureMessage)
      } else if (response.data != null && response.data.meta.code !== 200 && response.data.meta.code !== 403) {
        errorMsgDialog(response.data.data.errorMessage)
      } else {
        errorMsgDialog('System error')
      }
    }

    const onComplete = () => {
      fetchDeliveryOrderData()
    }

    const onError = (error) => {
      setIsLoading(false)
      console.log('Error... ')
      console.log(error)
      errorMsgDialog(error)
    }

    const errorMsgDialog = (error) => {
      let content = errorMsgContent(error, refNo)

      setDialogContent({
        content: content,
        title: 'Error',
        okMsg: 'OK'
      })

      setConfirmDialogOpen(true)
    }

    setIsLoading(true)
    const reqData = { 'deliveryOrderRefNo': refNo }
    securedSendRequest.execute(
      'POST',
      DeliveryOrderApiUrls.PRINT,
      reqData,
      onSuccess,
      onError,
      onComplete
    )
  }

  function getDeliveryOrderItems() {
    let items = []
    if (deliveryOrder.packages && deliveryOrder.packages.item) {
      items = deliveryOrder.packages.item.filter(item => item['delete'] === undefined || item['delete'] === false)
      items.forEach(
        (item) => {
          if (item.price) {
            item.price = Number.parseFloat(item.price).toFixed(2)
          }
          if (item.declaredPrice) {
            item.declaredPrice = Number.parseFloat(item.declaredPrice).toFixed(2)
          }
        }
      )
    }
    return items
  }

  return (


    <>

      {
        deliveryOrderRefNo && deliveryOrder ? (

          <Card>
            <CardContent>

              <Grid container spacing={2}>

                <ViewHeader deliveryOrder={deliveryOrder} singlePrint={singlePrint}
                            isLoading={isLoading} />
                <ConfirmDialog
                  isConfirmDialogOpen={confirmDialogOpen}
                  closeDialog={() => setConfirmDialogOpen(false)}
                  confirmDialog={() => setConfirmDialogOpen(false)}
                  content={dialogContent.content}
                  title={dialogContent.title}
                  okMsg={'OK'}
                />

                <MainOrderDetails deliveryOrder={deliveryOrder}
                                  showErrorNotification={showErrorNotification}
                                  salesOrderCurrency={salesOrderCurrency} actionType={'view'} />

                <PackageAndInvoiceDetails invoice={deliveryOrder.invoice}
                                          packageDetails={deliveryOrder.packages}
                                          showNotification={showNotification} />

                <AddressDetails deliveryPartyType={'shipper'} address={deliveryOrder.shipper}
                                template={deliveryOrder.shipperAddressTemplate}
                                showErrorNotification={showErrorNotification} />

                <AddressDetails deliveryPartyType={'consignee'} address={deliveryOrder.consignee}
                                showErrorNotification={showErrorNotification} />

                <ItemsDetails
                  showErrorNotification={showErrorNotification}
                  deliveryOrderItems={getDeliveryOrderItems()}
                  salesOrderCurrency={salesOrderCurrency} />

                <EventDetails deliveryEvents={deliveryEvents} />

                <CngGridItem xs={12} sm={12}>
                  <Divider />
                </CngGridItem>

                <CngGridItem xs={6} sm={6}>
                  <CngSecondaryButton
                    onClick={() => history.push(pathMap.CEC_DELIVERY_ORDER_LIST_VIEW)}
                  >
                    Back to delivery order listing
                  </CngSecondaryButton>
                </CngGridItem>

                <CngGridItem xs={6} sm={6}>
                  <Box display='flex' justifyContent='flex-end'>
                    <CngPrimaryButton
                      onClick={() => history.push(pathMap.CEC_DELIVERY_ORDER_LIST_VIEW + '/edit/' + deliveryOrderRefNo)}
                      startIcon={<FontAwesomeIcon icon='fa-regular fa-pen-to-square' />}>
                      Edit
                    </CngPrimaryButton>
                  </Box>
                </CngGridItem>

              </Grid>
            </CardContent>
          </Card>

        ) : (
          <CngBackdrop loading={true} />
        )
      }

    </>
  )
}

export default ViewPage
