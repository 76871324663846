import { Box, Button, Grid } from '@material-ui/core'
import ComplianceTranslationText from '../ComplianceTranslationText'
import React from 'react'

function SubmittedPermitButtonComponent(props){

    const ComplianceTranslatedObject = ComplianceTranslationText()

    return (
        <Box pl={5} pr={5} pt={4} pb={4}>
          <Grid container xs={12} sm={12}>
            <Grid container xs={12} sm={12} justify='flex-end' spacing={2}>
              <Box>
                <Button
                  variant='contained'
                  className='button-blue originalText'
                  onClick={() => {
                    props.buttonAction()
                  }}
                >
                  {ComplianceTranslatedObject.backToManagePermitPage}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
    )
}

export default SubmittedPermitButtonComponent
