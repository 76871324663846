import { BasePage as CngBasePage } from 'cng-web-lib'
import React from 'react'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_EcommerceLabel'
import TranslationText from './TranslatedText'
import LabelPage from './LabelPage'


//* Module Title ---------------------------------------------------------------
function ModuleTitle() {
      const translatedTextsObject = TranslationText();
      return translatedTextsObject.moduleTitle;
}

function WrappedLabelPage(props) {
      const breadcrumbNameMap = useBreadcrumbNameMap()
      return (
            <CngBasePage
                  moduleTitle={ModuleTitle()}
                  renderPage={(showSnackbar) => (
                        <LabelPage showNotification={showSnackbar} {...props} />
                  )}
                  extraCngBreadcrumbNameMap={breadcrumbNameMap}
            />
      )
}

export {
      WrappedLabelPage as LabelPage
}