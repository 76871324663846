import { Yup } from 'cng-web-lib'
import ValidationMessageTranslationText from './ValidationMessageTranslationText'

function useCompliancePartiesValidationSchema() {
  const validationMessageTranslation = ValidationMessageTranslationText()

  return Yup.object({
    partyType: Yup.string()
      .required(validationMessageTranslation.requiredMessage)
      .nullable(),
    crno: Yup.string()
      .when('partyType', {
        is: (partyType) => partyType !== 'Consignee',
        then: Yup.string()
          .required(validationMessageTranslation.requiredMessage)
          .nullable()
    }),
    name: Yup.string()
      .required(validationMessageTranslation.requiredMessage)
      .nullable(),
    address: Yup.string()
      .when('partyType', {
        is: (partyType) => partyType == 'Consignee',
        then: Yup.string()
          .required(validationMessageTranslation.requiredMessage)
          .nullable()
      }),
    country: Yup.string()
      .when('partyType', {
        is: (partyType) => partyType == 'Consignee',
        then: Yup.string()
        .required(validationMessageTranslation.requiredMessage)
        .nullable()
    }),
    phone: Yup.string()
      .when('partyType', {
        is: (partyType) => partyType == 'Declarant',
        then: Yup.string()
          .required(validationMessageTranslation.requiredMessage)
          .nullable()
    }),
  })
}

export default useCompliancePartiesValidationSchema
