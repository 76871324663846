import { useLocation } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import ComplianceApi from '../shared/api'
import ViewPermitPageFormProperties from './ViewPermitPageFormProperties'

import {
    useServices
} from 'cng-web-lib'

const DEFAULT_INITIAL_VALUES = Object.freeze({
    viewPermit: true
})

const FORMIK_PROPS = {
    initialValues: { ...DEFAULT_INITIAL_VALUES }
}

function FormFields(props){

    const location = useLocation()
    const {fetchRecords} = useServices()
    const [permit, setPermit] = useState({})
    const [, setLoading] = useState(false)
    console.log(location.state)

    const permitRequest = {
        complianceId: location.state.complianceId
    }

    useEffect(() => {
        setLoading(true)
        ComplianceApi.fetchPermitById(fetchRecords,permitRequest,populatePermitData)

    }, [])

    function populatePermitData(data){
        
        console.log(data)
        setPermit(data)
    }

    console.log("Check data from api ", permit)

    return(
        <div>
            <ViewPermitPageFormProperties.FormFields
                permitData={permit}
                showNotification={props.showNotification}
                {...props}
            />
        </div>
    )
}

const BodySection = Object.freeze({
    formikProps: FORMIK_PROPS,
    FormFields: FormFields
})

export default BodySection
