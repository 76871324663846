import { Box, Paper, Grid} from '@material-ui/core'
import CngBackdrop from 'src/views/vesselschedule/searchschedule/cngcomponent/CngBackDrop'
import { useHistory, useLocation } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import ViewPermitTypeComponent from '../components/ViewPermitTypeComponent'
import ViewCargoComponent from '../components/ViewCargoComponent'
import ViewContainerComponent from '../components/ViewContainerComponent'
import ViewTransportComponent from '../components/ViewTransportComponent'
import ViewLicenceComponent from '../components/ViewLicenceComponent'
import ViewItemSummaryComponent from '../components/ViewItemSummaryComponent'
import ViewPartyComponent from '../components/ViewPartyComponent'
import ViewLastModifiedComponent from 'src/views/freightbooking/components/ViewLastModifiedComponent'
import { useServices, components } from 'cng-web-lib'
import ViewPermitButtonComponent from './ViewPermitButtonComponent'
import HeaderBarTop from 'src/views/compliance/components/HeaderBarTop'
import ComplianceTranslationText from '../ComplianceTranslationText'
import pathMap from 'src/paths/PathMap_Compliance'
import ComplianceApiUrls from 'src/apiUrls/ComplianceApiUrls'
import DocumentApi from 'src/views/common/api/DocumentApi'
import * as CalistaConstant from 'src/constants/CalistaConstant'
import ViewComplianceDocumentComponent from '../components/ViewComplianceDocumentComponent'
import ItemsComponent from '../components/ItemsComponent'
import ViewInvoiceComponent from '../components/ViewInvoiceComponent'

const ViewPermitButtonLayout = ({status, redirectToManagePermitPage, onEdit, enquireStatus, onDownloadPermit, enquiredStatus}) => {
    //Created
    if (status == 5402 || status == 5406) {
        return(
            <ViewPermitButtonComponent
                backToManagePermit = {redirectToManagePermitPage}
                onEdit={onEdit}
                editable={true}
                onEnquireStatus={enquireStatus}
                enquirable={true}
            />  
        )
    //Draft
    }else if(status == 5401){
        return (
            <ViewPermitButtonComponent
                backToManagePermit = {redirectToManagePermitPage}
                onEdit={onEdit}
                editable={true}
            />  
        )
    //Submitted
    } else if (status == 5403 || status == 5407) {
        return (
            <ViewPermitButtonComponent
                backToManagePermit = {redirectToManagePermitPage}
                onEdit={onEdit}
                editable={true}
                enquirable={true}
                onEnquireStatus={enquireStatus}
            />  
        )
    }
    //Approved
    else if(status == 5404){
        return (
            <ViewPermitButtonComponent
                backToManagePermit = {redirectToManagePermitPage}
                onEdit={onEdit}
                approved={true}
                editable={enquiredStatus}
                enquirable={true}
                onEnquireStatus={enquireStatus}
                onDownloadPermit={onDownloadPermit}
            />  
        )
    }
    else{
        return (
            <ViewPermitButtonComponent
                backToManagePermit = {redirectToManagePermitPage}
            />  
        )
    }
  }


function FormFields(props){
    const {permitData} = props
    const translatedTextsObject = ComplianceTranslationText()

    const history = useHistory()
    const location = useLocation()
    const containers = permitData?.shippingInfoComplianceCargo?.shippingInfoComplianceContainers || []
    const [lastModifiedDate, setLastModifiedDate] = useState()
    const [lastModifiedText, setLastModifiedText] = useState()
    const [status,setStatus] = useState(null)
    const [enquiredStatus, setEnquiredStatus] = useState(false)
    const {securedSendRequest, fetchRecords} = useServices()
    const [loading, setLoading] = useState(true)
    const [withPay, setWithPay] = useState(false)
    const { CngGridItem, table: { useDefaultNotification }} = components
    const documents = permitData?.shippingInfoComplianceSupportDocs || []
    const ComplianceInvoices = permitData?.shippingInfoComplianceInvoices || []

    console.log('location.state',location.state)
    console.log('props.permitData',props.permitData)

    const { showNotification } = props
    const {error: showErrorNotification } =
    useDefaultNotification(showNotification)
    

    useEffect(() => {

        if(props.permitData?.complianceStatus != null)
        {
            setLoading(false)
            setStatus(props.permitData?.complianceStatus)
            setEnquiredStatus(props.permitData?.enquiredStatus)

            console.log(props.permitData?.complianceStatus)
            
            switch(props.permitData?.complianceStatus){
                case 5401:
                    setLastModifiedText(translatedTextsObject.draftedOn) //draft on
                    setLastModifiedDate(props.permitData.updatedDate)
                break
                case 5402:
                case 5403:
                    setLastModifiedText(translatedTextsObject.submittedOn) //submitted on
                    setLastModifiedDate(props.permitData.updatedDate)
                break
                case 5404:
                    setLastModifiedText(translatedTextsObject.approvedOn) //approved on
                    setLastModifiedDate(props.permitData.updatedDate)
                break
                case 5405:
                    setLastModifiedText(translatedTextsObject.createdOn) //created on
                    setLastModifiedDate(props.permitData.updatedDate)
                break
                case 5406:
                    setLastModifiedText(translatedTextsObject.amendCreatedOn) //amend created on
                    setLastModifiedDate(props.permitData.updatedDate)
                break
                case 5407:
                    setLastModifiedText(translatedTextsObject.amendSubmittedOn) //amend submitted on
                    setLastModifiedDate(props.permitData.updatedDate)    
                break
                default:
                break
            }

            if(props.permitData?.carType.startsWith('IPT')){
                setWithPay(true)
            }
        }}, [props])

    useEffect(() => {
        console.log('Status:', status)
    }, [status])

    const downloadClick = (id) => {
        console.log('docid'+ permitData.shippingInfoCompliancePermit.declarationDocId)

        let permitDoc = {docId: permitData.shippingInfoCompliancePermit.declarationDocId,
                        filename: permitData.shippingInfoCompliancePermit.declarationFileName}
        DocumentApi.downloadDocument(
          securedSendRequest,
          permitDoc,
          CalistaConstant.MODULE_SI
        );
      };
      
      const OnDownloadPermit = (id) => {
        console.log('id for permitList' + id)
        downloadClick(id);
      };

    const onEdit = () => {

        const complianceId = props.permitData?.complianceId
        setLoading(true)

        if(props.permitData?.complianceStatus == 5401){
            history.push({
                pathname: pathMap.CREATE_PERMIT_UPDATE_DRAFT,
                state: {
                    shippingInfoCompliance: permitData,
                    fromViewPermit: true,
                    inPermit: location.state.inPermit
                }
            })
        }else{
            fetchRecords.execute(
                ComplianceApiUrls.ENQUIRE_STATUS,
                {
                    customData: {complianceId: complianceId}
                },
                (data) => {
                    console.log('Enquiry successful', data)
                    if (data.errorMessages != null && data.errorMessages.length > 0) {
                        console.log("enquiry error");
                        setLoading(false);
                        showErrorNotification(data.errorMessages);
                    } else {
                        if(props.permitData?.complianceStatus == data.complianceStatus){
                            history.push({
                                pathname: pathMap.CREATE_PERMIT_UPDATE_DRAFT,
                                state: {
                                fromViewPermit: true,
                                action: 'edit',
                                shippingInfoCompliance: data,
                                inPermit: location.state.inPermit
                            }
                        }) 
                        } else if(props.permitData?.complianceStatus != data.complianceStatus){
                            history.push({
                                pathname: pathMap.MANAGE_VIEW_PERMIT,
                                state:{
                                complianceId: complianceId,
                                inPermit: location.state.inPermit,
                            }
                        })
                        }          
                    }
                },
                (error) => {
                    console.error('Error enquiring status:', error)
                }
            )
        }
      }

    const redirectToManagePermitPage = () => {
        history.push({
            pathname: pathMap.MANAGE_PERMIT
        })
    }

    const enquireStatus = () => {
        const complianceId = props.permitData?.complianceId
        console.log(complianceId)
        setLoading(true)

        fetchRecords.execute(
            ComplianceApiUrls.ENQUIRE_STATUS,
            {
                customData: {complianceId: complianceId}
            },
            (data) => {
                console.log('Enquiry successful', data)
                if (data.errorMessages != null && data.errorMessages.length > 0) {
                    console.log("enquiry error");
                    setLoading(false);
                    showErrorNotification(data.errorMessages);
                } else {
                    console.log("enquiry success");
                    window.location.reload();
                }
            },
            (error) => {
                console.error('Error enquiring status:', error)
            }
        )
    }

    return(

        <Box>
            <Paper>
                <CngGridItem xs={12} sm={9} shouldHide={loading ? false : true}>
                    <CngBackdrop loading={loading} />
                </CngGridItem>

                <HeaderBarTop permit={permitData} />
                <Box p={5} borderTop={1} borderBottom={1} className='border-light-blue'>

                    <Box pb={5}>
                        <Grid container xs={12} sm={12} alignItems='center' style={{ marginBottom: 40 }}>
                            <ViewLastModifiedComponent text={lastModifiedText} date={lastModifiedDate} />
                            <Grid item xs={12} sm={8} container alignItems='center'/>
                            <Grid item xs={12} sm={4} container alignItems='center'>
                                <Box display='block' mt={1}>
                                    <Box display='flex' alignItems='center'>
                                        <Box>{translatedTextsObject.bookingId}</Box>
                                        <Box className={'boldLabel'}>&nbsp;{permitData.dockey || '-'}</Box>
                                    </Box>
                                    <Box display='flex' alignItems='center'>
                                        <Box>{translatedTextsObject.permitDraftId}</Box>
                                        <Box className={'boldLabel'}>&nbsp;{permitData.transactionId || '-'}</Box>
                                    </Box>
                                    <Box display='flex' alignItems='center'>
                                        <Box>{translatedTextsObject.permitNo}</Box>
                                        <Box className={'boldLabel'}>&nbsp;{permitData.shippingInfoCompliancePermit?.pmtNo || '-'}</Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    
                    <Box pt={5}>
                        <ViewPermitTypeComponent.FormBody
                            permitTypeData = {permitData}
                            inPermit = {location.state.inPermit}
                            withPay = {withPay}
                        />
                    </Box>

                    {permitData.shippingInfoComplianceCargo == null ? (
                        <></>
                    ) : (
                    <Box pt={5}>
                        <CngGridItem xs={12} sm={12}>
                            <ViewCargoComponent.FormBody 
                                cargoData = {permitData} 
                            /> 
                        </CngGridItem>
                    </Box>
                    )}

                    {containers.length > 0 && (
                        <Box pt={5}>
                            <CngGridItem xs={12} sm={12}>
                                <ViewContainerComponent.FormBody 
                                    containers = {containers} 
                                /> 
                            </CngGridItem>
                        </Box>
                    )} 

                    {permitData.shippingInfoComplianceTransport == null ? (
                        <></>
                    ) : (
                        <Box pt={5}>
                            <CngGridItem xs={12} sm={12}>
                                <ViewTransportComponent.FormBody 
                                    transportData = {permitData} 
                                /> 
                            </CngGridItem>
                        </Box>
                    )}

                    {permitData.shippingInfoComplianceParty == null ? (
                        <></>
                    ) : (
                    <Box pt={5}>
                        <CngGridItem xs={12} sm={12}>
                            <ViewPartyComponent.FormBody 
                                partiesData = {permitData} 
                                isReview = {true}
                                showNotification={props.showNotification}
                            /> 
                        </CngGridItem>
                    </Box>
                    )}
                    
                    {permitData.shippingInfoComplianceLicence == null ? (
                        <></>
                    ) : (
                    <Box pt={5}>
                        <CngGridItem xs={12} sm={12}>
                            <ViewLicenceComponent.FormBody 
                                licenceData = {permitData} 
                            /> 
                        </CngGridItem>
                    </Box>
                    )}

                    {documents.length > 0 && (
                        <Box pt={5}>
                            <ViewComplianceDocumentComponent.FormBody 
                                document={documents} 
                                allowDownload
                                setLoading={false}
                                module={CalistaConstant.MODULE_SI}
                                showNotification={props.showNotification}
                            />
                        </Box>
                    )}
					{permitData.shippingInfoComplianceItems == null ? (
                        <></>
                    ) : (
                        <Box pt={5}>
                            <ItemsComponent.FormBody
                                isView={true}
                                itemData={permitData}
                            />
                        </Box>
                    )}
                    {permitData.shippingInfoComplianceSummary == null ? (
                        <></>
                    ) : (
                    <Box pt={5}>
                        <CngGridItem xs={12} sm={12}>
                            <ViewItemSummaryComponent.FormBody 
                                summaryData = {permitData} 
                            /> 
                        </CngGridItem>
                    </Box>
                    )}

                    {!withPay ? (    
                        <></>
                    ) : (                      
                        <Box pt={5}>
                            <CngGridItem xs={12} sm={12}>
                                <ViewInvoiceComponent.FormBody 
                                    invoices = {ComplianceInvoices} 
                                /> 
                            </CngGridItem>
                        </Box>
                    )}  

                    <Paper className='sticky-footer'>
                        <Box alignItems='center'>
                            <ViewPermitButtonLayout 
                                status = {status}
                                redirectToManagePermitPage = {redirectToManagePermitPage}
                                onEdit = {onEdit}
                                onDownloadPermit = {OnDownloadPermit}
                                enquireStatus = {enquireStatus}
                                enquiredStatus = {enquiredStatus}
                            />
                        </Box>
                    </Paper>                        
                </Box>
            </Paper>
        </Box>
    )
}

const ViewPermitPageFormProperties = Object.freeze({
    FormFields: FormFields
})

export default ViewPermitPageFormProperties
