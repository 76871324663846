import React, { useEffect, useRef, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  IconButton,
  Tooltip,
  Typography
} from '@material-ui/core'
import { Grid } from '@mui/material'
import { components, constants, useServices } from 'cng-web-lib'
import CountrySelectAutoComplete from '../../../../common/ui/CountrySelectAutoComplete'
import AccordionHeaderComponent from '../../../../common/ui/AccordionHeaderComponent'
import SCOAdminApiUrls from '../../../../../apiUrls/SCOAdminApiUrls'
import { useFormContext } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AccordionFooterControlComponent from '../../../../common/ui/AccordionFooterControlComponet'
import RadioToggleButtonGroup from './radio-toggle/RadioToggleButtonGroup'
import PartyTemplateDialog from './party-template/PartyTemplateDialog'
import clsx from 'clsx'
import _ from 'lodash'


AddressDetails.propTypes = {}

const {
  form: {
    field: {
      CngTextField, CngLookupAutocompleteField
    }
  }, CngGridItem, button: { CngSecondaryButton }
} = components


const {
  filter: {
    EQUAL
  }
} = constants

function AddressDetails(props) {

  const { partyType, actionType, partyTemplate, updatePartyTemplate, showNotification } = props
  const [countryCode, setCountryCode] = useState()
  const { fetchRecords, securedSendRequest } = useServices()
  const { setValue, watch } = useFormContext()
  const [isExpanded, setExpanded] = useState(actionType === 'add' ? true : false)
  const [isPartyTemplateDialogOpen, setPartyTemplateDialogOpen] = useState(false)
  const isInitialMount = useRef(true)


  const handlePartyTemplateButton = () => {
    setPartyTemplateDialogOpen(true)
  }
  const handleDialogClose = () => {
    setPartyTemplateDialogOpen(false)
  }


  const clearExistingValues = () => {
    setValue(partyType + '.' + 'state', '')
    setValue(partyType + '.' + 'stateId', '')
    setValue(partyType + '.' + 'street', '')
    setValue(partyType + '.' + 'city', '')
    setValue(partyType + '.' + 'postalCode', '')
  }

  const handleAccordionExpand = () => {
    setExpanded(!isExpanded)
  }

  useEffect(() => {
    if (isInitialMount.current) {
      console.log('Initial template mount-' + partyType)
      isInitialMount.current = false
    } else {
      console.log('Template changed-' + partyType)
      updateFormWithTemplateValues(partyTemplate)
    }
  }, [partyTemplate])

  const updateFormWithTemplateValues = (partyTemplate) => {
    if (partyTemplate.code) {
      console.log('Updating template: ' + partyType + partyTemplate.code)
      setValue(partyType + 'AddressTemplate', partyTemplate.code)
      setValue(partyType + '.firstName', partyTemplate.firstName)
      setValue(partyType + '.lastName', partyTemplate.lastName)
      setValue(partyType + '.phone', partyTemplate.phoneNo)
      setValue(partyType + '.email', partyTemplate.email)
      setValue(partyType + '.companyName', partyTemplate.companyName)
      setValue(partyType + '.fax', partyTemplate.faxNo)
      setValue(partyType + '.taxNo', partyTemplate.taxNo)
      setValue(partyType + '.city', partyTemplate.city)
      setValue(partyType + '.otherState', partyTemplate.otherState)
      setValue(partyType + '.street', partyTemplate.address)
      setValue(partyType + '.postalCode', partyTemplate.postCode)
      setValue(partyType + '.country', partyTemplate.countryCode)
      setCountryCode(partyTemplate.countryCode)
      setValue(partyType + '.stateId', partyTemplate.stateId)
      if (partyTemplate.stateId) {
        fetchRecords.execute(SCOAdminApiUrls.GET_COUNTRY_STATE_LIST, {
          filters: [{
            field: 'id', operator: EQUAL, value: partyTemplate.stateId
          }]
        }, (data) => {
          if (data.content && data.content[0] && data.content[0].stateCode) {
            setValue(partyType + '.state', data.content[0].stateCode)
          }
        }, () => {
          showNotification('Error while retrieving state details.')
        })
      } else {
        setValue(partyType + '.state', '')
      }

      if (partyTemplate.addressTypeId) {
        securedSendRequest.execute('POST', SCOAdminApiUrls.GET_CODE_MASTER_LIST, {
          'baseFilterDTO': {
            'filterType': 'AND', 'filterProperties': [{
              fieldName: 'codeType', operatorType: 'EQUAL', value1: 'CEC_ADDRESS_TYPE'
            }, {
              fieldName: 'id', operatorType: 'EQUAL', value1: partyTemplate.addressTypeId
            }]
          }
        }, (res) => {
          if (res.status === 200) {
            const addressTypeName = res.data.content.filter(u => u['codeType'] === 'CEC_ADDRESS_TYPE' && u['id'] === partyTemplate.addressTypeId).map(u => u['name'])[0]
            setValue(partyType + '.addressType', addressTypeName)
          }
        }, () => showNotification('Error while retrieving address type.'))
      } else {
        setValue(partyType + '.addressType', 'Residential')
      }


    }
  }


  const clearPartyTemplate = () => {
    setValue(partyType + 'AddressTemplate', '')
    setValue(partyType + '.firstName', '')
    setValue(partyType + '.lastName', '')
    setValue(partyType + '.phone', '')
    setValue(partyType + '.email', '')
    setValue(partyType + '.companyName', '')
    setValue(partyType + '.fax', '')
    setValue(partyType + '.taxNo', '')
    setValue(partyType + '.city', '')
    setValue(partyType + '.otherState', '')
    setValue(partyType + '.street', '')
    setValue(partyType + '.postalCode', '')
    setValue(partyType + '.country', null)
    setCountryCode('')
    setValue(partyType + '.stateId', '')
    setValue(partyType + '.state', '')
    setValue(partyType + '.addressType', 'Residential')
    updatePartyTemplate(partyType, { code: '' })
  }

  return (<Card>


    <Accordion expanded={isExpanded}>

      <AccordionSummary
        aria-controls='panel1a-content'
        id='panel1a-header'
      >

        <Grid container spacing={1} sx={{ margin: '0px' }}>

          <Grid item sm={8}>
            <AccordionHeaderComponent
              title={partyType.toLowerCase().trim() === 'shipper' ? 'Shipper' : 'Consignee'}
              hideMandatory
            />
          </Grid>

          <Grid item sm={4} shouldHide={partyType.toLowerCase().trim() !== 'shipper'}>
            {!partyTemplate.code ?
              <Box display={partyType.toLowerCase().trim() !== 'shipper' ? 'none' : 'flex'}
                   justifyContent='flex-end'>
                <CngSecondaryButton startIcon={<FontAwesomeIcon icon='fa-pen-field' />} size='small'
                                    onClick={() => handlePartyTemplateButton()}>
                  Retrieve Address

                </CngSecondaryButton>
              </Box>
              :
              <Box style={{ textAlign: 'right' }}>
                <Typography display='inline' className={clsx('greyLabel')}
                            style={{ fontSize: 14, fontWeight: 'bold', width: 'fit-content' }}
                >
                  {'Template: '}
                </Typography>
                <Tooltip
                  title={'Template for: ' + partyTemplate.code}>
                  <Typography display='inline' className={clsx('greyLabel')}
                              style={{ fontSize: 14, fontWeight: 'bold', width: 'fit-content' }}>
                    {partyTemplate.code}
                    <IconButton aria-label='delete' onClick={clearPartyTemplate}>
                      <FontAwesomeIcon icon={['fal', 'fa-circle-xmark']}
                                       style={{ color: 'red', marginRight: 2 }} />
                    </IconButton>
                  </Typography>
                </Tooltip>
              </Box>
            }


          </Grid>

          <PartyTemplateDialog />

          <CngGridItem xs={12}>
            <RadioToggleButtonGroup
              name={partyType + '.addressType'}
              defaultValue={watch(partyType + '.addressType') || 'Residential'}
              disabled={!_.isEmpty(partyTemplate.code)}
              options={[{
                value: 'Residential', label: 'Residential / Personal address'
              }, {
                value: 'Business', label: 'Business address'
              }]}
              key={partyType + '.addressType.' + partyTemplate.code}
            />
          </CngGridItem>

        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1} sx={{ margin: '0px' }}>
          <CngGridItem xs={12} sm={4}>
            <CngTextField
              type='text'
              name={partyType + '.firstName'}
              defaultValue=''
              label={'First name'}
              disabled={!_.isEmpty(partyTemplate.code)}
              key={partyType + '.firstName.' + partyTemplate.code}
            />
          </CngGridItem>

          <CngGridItem xs={12} sm={4}>
            <CngTextField
              type='text'
              name={partyType + '.lastName'}
              label={'Last name'}
              disabled={!_.isEmpty(partyTemplate.code)}
              key={partyType + '.lastName.' + partyTemplate.code}
            />
          </CngGridItem>

          <CngGridItem xs={12} sm={4}>
            <CngTextField
              type='tel'
              name={partyType + '.phone'}
              label={'Contact no'}
              disabled={!_.isEmpty(partyTemplate.code)}
              key={partyType + '.phone.' + partyTemplate.code}
            />
          </CngGridItem>

          <CngGridItem xs={12} sm={4}>
            <CngTextField
              type='email'
              name={partyType + '.email'}
              label={'Email address'}
              disabled={!_.isEmpty(partyTemplate.code)}
              key={partyType + '.email.' + partyTemplate.code}
            />
          </CngGridItem>

          <CngGridItem xs={12} sm={4}>
            <CngTextField
              type='text'
              name={partyType + '.companyName'}
              label={'Company Name'}
              disabled={!_.isEmpty(partyTemplate.code)}
              key={partyType + '.companyName.' + partyTemplate.code}
            />
          </CngGridItem>

          <CngGridItem xs={12} sm={4}>
            <CngTextField
              type='tel'
              name={partyType + '.fax'}
              label={'Fax no.'}
              disabled={!_.isEmpty(partyTemplate.code)}
              key={partyType + '.fax.' + partyTemplate.code}
            />
          </CngGridItem>

          <CngGridItem xs={12} sm={4}>
            <CngTextField
              type='text'
              name={partyType + '.taxNo'}
              label={'Tax no'}
              disabled={!_.isEmpty(partyTemplate.code)}
              key={partyType + '.taxNo.' + partyTemplate.code}
            />
          </CngGridItem>

          <CngGridItem xs={12} sm={4}>
            <CountrySelectAutoComplete
              key={partyType + '.country.' + partyTemplate.code}
              name={partyType + '.country'}
              label={'Country'}
              disabled={!_.isEmpty(partyTemplate.code)}
              onChangeAutoComplete={(val) => {
                setCountryCode(val)
                clearExistingValues()
              }}
            />
          </CngGridItem>

          <CngGridItem xs={12} sm={4}>

            <CngLookupAutocompleteField
              key={partyType + countryCode + '.stateId.' + partyTemplate.code}
              name={partyType + '.stateId'}
              label={'State'}
              disabled={!watch(partyType + '.country') || partyTemplate.code}
              onChange={(_val, event) => {
                if (event && event.data) {
                  setValue(partyType + '.state', event.data.stateCode)
                } else {
                  setValue(partyType + '.state', '')
                }
              }}
              lookupProps={{
                url: SCOAdminApiUrls.GET_COUNTRY_STATE_LIST,
                label: 'stateName',
                value: 'id',
                filters: [{
                  field: 'countryCode', operator: EQUAL, value: watch(partyType + '.country')
                }]
              }}
            >
            </CngLookupAutocompleteField>
          </CngGridItem>

          <CngGridItem xs={12} sm={4}>
            <CngTextField
              type='text'
              key={partyType + '.otherState.' + partyTemplate.code}
              name={partyType + '.otherState'}
              disabled={!_.isEmpty(partyTemplate.code)}
              label={'Other state/province'}
            />
          </CngGridItem>

          <CngGridItem xs={12} sm={4}>
            <CngTextField
              key={partyType + '.city.' + partyTemplate.code}
              type='text'
              disabled={!_.isEmpty(partyTemplate.code)}
              name={partyType + '.city'}
              label={'City'}
            />
          </CngGridItem>

          <CngGridItem xs={12} sm={4}>
            <CngTextField
              type='text'
              disabled={!_.isEmpty(partyTemplate.code)}
              key={partyType + '.street.' + partyTemplate.code}
              name={partyType + '.street'}
              label={'Street'}
            />
          </CngGridItem>

          <CngGridItem xs={12} sm={4}>
            <CngTextField
              type='text'
              disabled={!_.isEmpty(partyTemplate.code)}
              key={partyType + '.postalCode.' + partyTemplate.code}
              name={partyType + '.postalCode'}
              label={'Postal/Zip code'}
            />
          </CngGridItem>

        </Grid>
      </AccordionDetails>

    </Accordion>

    <AccordionFooterControlComponent
      style={{ marginBottom: 20 }}
      handleAccordionExpand={handleAccordionExpand}
      expanded={isExpanded}
      footerText={'Other details'}
    />

    <PartyTemplateDialog closeDialog={handleDialogClose} isDialogOpen={isPartyTemplateDialogOpen}
                         partyType={partyType}
                         updatePartyTemplate={updatePartyTemplate}
                         showNotification={showNotification} />

  </Card>)
}

export default AddressDetails
