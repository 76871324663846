import React from 'react'
import PropTypes from 'prop-types'
import { Button, Paper, Typography } from '@material-ui/core'
import { components } from 'cng-web-lib'
import Box from '@material-ui/core/Box'
import Stack from '@mui/material/Stack'


DeleteItemDialog.propTypes = {
  isDialogOpen: PropTypes.bool,
  closeDialog: PropTypes.func,
  itemData: PropTypes.object,
  deliveryOrderItems: PropTypes.array,
  deliveryOrderActionType: PropTypes.string,
  setDeliveryOrderItems: PropTypes.func
}

const { CngDialog } = components


function DeleteItemDialog(props) {

  const {
    isDialogOpen,
    closeDialog,
    itemsToDelete,
    deliveryOrderItems,
    deliveryOrderActionType,
    setDeliveryOrderItems
  } = props

  const findAndDeleteItems = () => {
    const excludedRefNos = itemsToDelete.map(item => item.refNo)
    if (deliveryOrderActionType === 'add') {
      setDeliveryOrderItems(deliveryOrderItems.filter(item => !excludedRefNos.includes(item.refNo)))
    } else {
      let clonedArray = JSON.parse(JSON.stringify(deliveryOrderItems))
      clonedArray.filter(item => excludedRefNos.includes(item.refNo)).forEach(item => item.delete = true)
      setDeliveryOrderItems(clonedArray)
    }
  }

  return (
    <CngDialog
      customDialogContent={
        <Paper style={{ borderRadius: '0px' }}>
          <Box m={2} mt={1}>
            <Box mt={0.5}>
              <Typography variant='body2' style={{ maxHeight: '60vh', overflow: 'auto' }}>Item that you
                delete can't be
                restored.
                Are you sure about that?</Typography>
            </Box>
            <Box display='flex' flexDirection='row-reverse' mt={2}>
              <Stack direction='row' spacing={2}>
                <Button size='small' color='primary' onClick={closeDialog} className='originalText'>No,
                  take me
                  back</Button>
                <Button
                  variant='contained'
                  className='button-blue originalText'
                  onClick={() => {
                    findAndDeleteItems()
                    closeDialog()
                  }}
                  type='button'
                  style={{ borderRadius: '8px' }}
                >Yes, delete</Button>
              </Stack>
              <Box flexGrow={1}></Box>
            </Box>
          </Box>
        </Paper>
      }
      dialogTitle={
        <>
          {'Delete'}
        </>
      }
      open={isDialogOpen}
    />
  )
}

export default DeleteItemDialog
