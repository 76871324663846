import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Card, CardContent, Chip, Divider, Typography } from '@material-ui/core'
import { Grid } from '@mui/material'
import { components, constants, useServices } from 'cng-web-lib'
import Moment from 'moment'
import SCOAdminApiUrls from '../../../../../apiUrls/SCOAdminApiUrls'
import EmptyBox from './EmptyBox'


const {
  CngGridItem,
  table: { useDefaultNotification }
} = components

const {
  filter: { EQUAL }
} = constants


PackageAndInvoiceDetails.propTypes = {
  invoice: PropTypes.object,
  packageDetails: PropTypes.object,
  showNotification: PropTypes.func
}


function PackageAndInvoiceDetails(props) {

  const { invoice, packageDetails, showNotification } = props
  const { fetchRecords, securedSendRequest } = useServices()
  let uomCodes = []
  uomCodes.push(packageDetails.weightUOM, packageDetails.volWeightUOM, packageDetails.dimensionUOM)
  const concatenatedUomCodes = uomCodes.filter(uom => uom !== null).join('|')
  const [uomDetails, setUomDetails] = useState()
  const {
    error: showErrorNotification
  } = useDefaultNotification(showNotification)


  useEffect(() => {
    if (concatenatedUomCodes) {
      securedSendRequest.execute(
        'POST',
        SCOAdminApiUrls.GET_CODE_MASTER_LIST,
        {
          'baseFilterDTO': {
            'filterType': 'AND',
            'filterProperties': [
              {
                fieldName: 'codeType',
                operatorType: 'IN',
                value1: 'COM_UOM_VOLM|COM_UOM_WEGH|COM_UOM_DIMN|COM_UOM_VOLW'
              },
              {
                fieldName: 'code',
                operatorType: 'IN',
                value1: concatenatedUomCodes
              }
            ]
          }
        },
        (res) => {
          if (res.status == 200) {
            const data = res.data.content
            setUomDetails(data)
          }
        },
        (error) =>
          showErrorNotification('Error while retrieving units of measurements.')
      )
    }

  }, [concatenatedUomCodes])

  const [packageName, setPackageName] = useState('')
  useEffect(() => {
    if (packageDetails.packageType) {
      fetchRecords.execute(
        SCOAdminApiUrls.GET_CODE_MASTER_LIST,
        {
          filters: [
            {
              field: 'codeType',
              operatorType: EQUAL,
              value: 'CEC_PACKAGE_TYPE'
            },
            {
              field: 'code',
              operatorType: EQUAL,
              value: packageDetails.packageType
            }
          ]
        },
        (data) => {
          if (data.content) {
            setPackageName(data.content.filter(u => u['code'] === packageDetails.packageType && u['codeType'] === 'CEC_PACKAGE_TYPE').map(u => u['name'])[0])
          }
        }, () => {
          showErrorNotification('Error while retrieving package types.')
        }
      )
    }
  }, [packageDetails.packageType])


  return (
    <Grid item xs={12}>
      <Card>
        <CardContent>

          <Typography className={'boldLabel'} style={{ fontSize: 16, marginBottom: '1rem' }}>
            Package & invoice
          </Typography>

          <Grid container spacing={1}>

            <CngGridItem xs={12} sm={4}>
              <Typography variant='caption' color='textSecondary'>Package type</Typography>
              <Typography variant='body2'>{packageDetails.packageType ? packageName :
                <EmptyBox />}</Typography>
            </CngGridItem>

            <CngGridItem xs={12} sm={4}>
              <Typography variant='caption' color='textSecondary'>Invoice no.</Typography>
              <Typography variant='body2' noWrap={true}>{invoice.invoiceNo ? invoice.invoiceNo :
                <EmptyBox />}</Typography>
            </CngGridItem>

            <CngGridItem xs={12} sm={4}>
              <Typography variant='caption' color='textSecondary'>Invoice date</Typography>
              <Typography
                variant='body2'>{invoice.invoiceDate ? Moment(invoice.invoiceDate).format('DD MMM YYYY') :
                <EmptyBox />}</Typography>
            </CngGridItem>

            <CngGridItem xs={12} sm={12}>
              <Divider />
            </CngGridItem>

            <CngGridItem xs={12} sm={3}>
              <Typography variant='caption' color='textSecondary'>Length</Typography>
              <Typography variant='body2'>{packageDetails.length ? packageDetails.length :
                <EmptyBox />}</Typography>
            </CngGridItem>

            <CngGridItem xs={12} sm={3}>
              <Typography variant='caption' color='textSecondary'>Width</Typography>
              <Typography variant='body2'>{packageDetails.width ? packageDetails.width :
                <EmptyBox />}</Typography>
            </CngGridItem>

            <CngGridItem xs={12} sm={3}>
              <Typography variant='caption' color='textSecondary'>Height</Typography>
              <Typography variant='body2'>{packageDetails.height ? packageDetails.height :
                <EmptyBox />}</Typography>
            </CngGridItem>

            <CngGridItem xs={12} sm={3}>
              <Typography variant='caption' color='textSecondary'>Dimension UOM</Typography>
              <Typography variant='body2'>{
                (uomDetails && packageDetails.dimensionUOM) ? uomDetails.filter(u => u['code'] === packageDetails.dimensionUOM && u['codeType'] === 'COM_UOM_DIMN').map(u => u['name'])[0] + ' ' : null}
                {packageDetails.dimensionUOM ?
                  <Chip label={packageDetails.dimensionUOM} size='small' variant='outlined' /> :
                  <EmptyBox />}
              </Typography>
            </CngGridItem>

            <CngGridItem xs={12} sm={3}>
              <Typography variant='caption' color='textSecondary'>Weight</Typography>
              <Typography variant='body2'>{packageDetails.weight ? packageDetails.weight :
                <EmptyBox />}</Typography>
            </CngGridItem>

            <CngGridItem xs={12} sm={3}>
              <Typography variant='caption' color='textSecondary'>Weight UOM</Typography>
              <Typography variant='body2'>{
                (uomDetails && packageDetails.weightUOM) ? uomDetails.filter(u => u['code'] === packageDetails.weightUOM && u['codeType'] === 'COM_UOM_WEGH').map(u => u['name'])[0] + ' ' : null}
                {packageDetails.weightUOM ?
                  <Chip label={packageDetails.weightUOM} size='small' variant='outlined' /> :
                  <EmptyBox />}
              </Typography>
            </CngGridItem>

            <CngGridItem xs={12} sm={3}>
              <Typography variant='caption' color='textSecondary'>Volumetric weight</Typography>
              <Typography variant='body2'>{packageDetails.volWeight ? packageDetails.volWeight :
                <EmptyBox />}</Typography>
            </CngGridItem>

            <CngGridItem xs={12} sm={3}>
              <Typography variant='caption' color='textSecondary'>Volumetric weight UOM</Typography>
              <Typography variant='body2'>{
                (uomDetails && packageDetails.volWeightUOM) ? uomDetails.filter(u => u['code'] === packageDetails.volWeightUOM && u['codeType'] === 'COM_UOM_VOLW').map(u => u['name'])[0] + ' ' : null}
                {packageDetails.volWeightUOM ?
                  <Chip label={packageDetails.volWeightUOM} size='small' variant='outlined' /> :
                  <EmptyBox />}
              </Typography>
            </CngGridItem>

          </Grid>

        </CardContent>
      </Card>
    </Grid>
  )
}

export default PackageAndInvoiceDetails
