import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, makeStyles, Typography } from '@material-ui/core'
import { Grid } from '@mui/material'
import clsx from 'clsx'
import MaterialTable, { MTableToolbar } from 'material-table'
import SCOAdminApiUrls from '../../../../../apiUrls/SCOAdminApiUrls'
import { constants, useServices } from 'cng-web-lib'
import ViewItemDialog from './ViewItemDialog'
import { tableIcons } from '../util/Utils'
import _ from 'lodash'

ItemsDetails.propTypes = {
  deliveryOrderItems: PropTypes.array, showErrorNotification: PropTypes.func
}

const {
  filter: { EQUAL }
} = constants

export const useDeliveryOrderStyles = makeStyles((theme) => ({
  table: {
    '& thead th': {
      backgroundColor: theme.palette.component.cngMuiTable.tableHeaderCellFill,
      textTransform: 'uppercase',
      fontWeight: theme.typography.fontWeightBold,
      fontSize: '.75rem',
      color: '#8181A5',
      lineHeight: 1.2,
      padding: theme.spacing(1, 2),
      '&:not(:first-child):not(:last-child)': {
        padding: theme.spacing()
      }
    }, '& tbody td': {
      fontSize: '.75rem', color: theme.palette.text.gray900, '&.filter': {
        backgroundColor: theme.palette.component.cngMuiTable.tableHeaderCellFill
      }, padding: theme.spacing(1, 2), '&:not(:first-child):not(:last-child)': {
        padding: theme.spacing()
      }
    }, '& td, & th': {
      borderColor: theme.palette.background.ctaBtnActiveBorder
    }, '& tbody tr:hover': {
      backgroundColor: theme.palette.component.cngMuiTable.tableHeaderCellFill, padding: theme.spacing(1, 2)
    }
  }, limitedText: {
    maxWidth: '24em', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'
  }, action: {
    color: theme.palette.text.textSecondary,
    borderColor: theme.palette.background.ctaBtnActiveBorder,
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: theme.shape.borderRadius * 2,
    height: 36,
    width: 36,
    fontSize: '.875rem'
  }, boldText: {
    fontWeight: theme.typography.fontWeightBold, fontSize: '.875rem'
  }, primaryText: {
    fontSize: '.875rem'
  }, secondaryText: {
    fontSize: '.75rem', color: theme.palette.text.textSecondary
  }, colorBlue: {
    color: theme.palette.primary.main
  }, colorGreen: {
    color: theme.palette.success.main
  }, colorOrange: {
    color: '#C48E1F'
  }

}))

function ItemsDetails(props) {

  const { deliveryOrderItems, showErrorNotification, salesOrderCurrency } = props
  const currencySymbolPrefix = salesOrderCurrency && salesOrderCurrency.symbol ? salesOrderCurrency.symbol + ' ' : ''
  const classes = useDeliveryOrderStyles()
  const tableRef = useRef()
  const { fetchRecords } = useServices()
  const [itemUOMs, setItemUOMs] = useState([])

  const [isDialogOpen, setDialogOpen] = useState(false)
  const [itemForView, setItemForView] = useState()
  const handleDialogClickOpen = () => {
    setDialogOpen(true)
  }
  const handleDialogClose = () => {
    setDialogOpen(false)
  }


  useEffect(() => {
    fetchRecords.execute(SCOAdminApiUrls.GET_CODE_MASTER_LIST, {
      filters: [{
        field: 'codeType', operatorType: EQUAL, value: 'COM_UOM_ITEM'
      }]
    }, (data) => {
      if (data.content) {
        setItemUOMs(data.content)
      }
    }, () => {
      showErrorNotification('Error while retrieving item UOM.')
    })
  }, [])

  const onRowClick = (event, rowData) => {
    handleDialogClickOpen()
    setItemForView(rowData)
  }


  const columns = [{
    title: 'Quantity & UOM / Product Name Or Description',
    field: 'productName',
    render: rowData => <Typography
      className={clsx(classes.primaryText, classes.limitedText)}>{(rowData.quantity === null ? ' ' : rowData.quantity) + ' ' +
      (_.isEmpty(itemUOMs.filter(uom => uom['code'] === rowData.uom).map(uom => uom['name'])) ? ' ' : itemUOMs.filter(uom => uom['code'] === rowData.uom).map(uom => uom['name'])) +
      ' - ' + (_.isEmpty(rowData.productName) ? ' ' : rowData.productName)}</Typography>,
    defaultSort: 'asc',
    customSort: (a, b) => {
      const productName1 = a.productName ? a.productName.toLowerCase() : ''
      const productName2 = b.productName ? b.productName.toLowerCase() : ''
      return productName1.localeCompare(productName2) > 0 ? 1 : -1
    }
  }, {
    title: 'Unit Price', field: 'price', render: rowData => <Typography
      className={clsx(classes.primaryText)}>{rowData.price && currencySymbolPrefix + rowData.price}</Typography>
  }, {
    title: 'Declared Price', field: 'declaredPrice', render: rowData => <Typography
      className={clsx(classes.primaryText)}>{rowData.declaredPrice && currencySymbolPrefix + rowData.declaredPrice}</Typography>
  }]

  let customToolbar = props => (<div>
    <div class='MuiToolbar-root MuiToolbar-regular MuiToolbar-gutters' style={{ flex: '1 1 10%' }}>
      <Typography className={'boldLabel'} style={{ fontSize: 16, paddingRight: 8 }}>
        Items
      </Typography>
      <Typography className={'greyLabel'}
                  style={{ fontSize: 12 }}> / {props.data.length} Entries </Typography>
      <div style={{ flex: '1 1 10%' }} />
      <MTableToolbar {...props} />
    </div>
  </div>)
  return (<Grid item xs={12}>
    <Box className={classes.table}>
      <MaterialTable
        title=''
        tableRef={tableRef}
        icons={tableIcons}
        columns={columns}
        onRowClick={onRowClick}
        options={{
          search: true, paging: true, sorting: true, emptyRowsWhenPaging: false, padding: 'default'
        }}
        localization={{
          toolbar: { searchPlaceholder: 'Search in your entries' }
        }}
        data={deliveryOrderItems.filter(item => item['delete'] === undefined || item['delete'] === false).map(item => ({ ...item }))}
        components={{
          Toolbar: customToolbar
        }}
      />
    </Box>
    <ViewItemDialog isDialogOpen={isDialogOpen} closeDialog={handleDialogClose} itemData={itemForView}
                    salesOrderCurrency={salesOrderCurrency} itemUOMs={itemUOMs} />
  </Grid>)
}

export default ItemsDetails
