import { Yup } from 'cng-web-lib'
import ValidationMessageTranslationText from './ValidationMessageTranslationText'

function useComplianceInItemsValidationSchema() {
  const validationMessageTranslation = ValidationMessageTranslationText()

  return Yup.object({
    hsCode: Yup.string()
      .required(validationMessageTranslation.requiredMessage)
      .nullable(),
    brand: Yup.string()
      .required(validationMessageTranslation.requiredMessage)
      .nullable(),
    itemDesc: Yup.string()
      .required(validationMessageTranslation.requiredMessage)
      .nullable(),
    ctyOrigin: Yup.string()
      .required(validationMessageTranslation.requiredMessage)
      .nullable(),
    hsQtyValue: Yup.number()
      .required(validationMessageTranslation.requiredMessage)
      .min(1, validationMessageTranslation.minItemQuantity)
      .nullable(),
    hsQtyUom: Yup.string()
      .required(validationMessageTranslation.requiredMessage)
      .nullable(),
    cifFobAmt: Yup.number()
      .required(validationMessageTranslation.requiredMessage)
      .min(1, validationMessageTranslation.minItemCifFob)
      .nullable(),
    grossWeight: Yup.number()
      .required(validationMessageTranslation.requiredMessage)
      .min(1, validationMessageTranslation.minItemGrossWeight)
      .nullable(),
  })
}

export default useComplianceInItemsValidationSchema
