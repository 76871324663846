import React from 'react'
import { components, constants } from 'cng-web-lib'
import TranslationText from '../../../TranslationText'
import { Button, Card, CardContent, Chip, Grid, Typography } from '@material-ui/core'
import ReplayIcon from '@material-ui/icons/Replay'
import SearchIcon from '@material-ui/icons/Search'

import { useFormContext } from 'react-hook-form'
import SCOAdminApiUrls from '../../../../../../apiUrls/SCOAdminApiUrls'
import { useDispatch, useSelector } from 'react-redux'
import ShipmentProviderUrls from '../../../../../../apiUrls/cec-admin/shipment-provider/ShipmentProviderUrls'


const {
  form: {
    adapter: {
      useFormAdapter: { useField }
    },
    field: {
      CngTextField, CngLookupAutocompleteField, CngDateTimeField, CngCheckboxField
    }
  }, CngGridItem
} = components

const {
  filter: {
    EQUAL
  }
} = constants


function Fields({ ...props }) {

  const dispatch = useDispatch()

  const [lastMileShipmentProviderIDs] = useField('lastMileShipmentProviderIDs')
  const [deliveryOrderStatusIDs] = useField('deliveryOrderStatusIDs')
  const [deliveryOrderRefNo] = useField('deliveryOrderRefNo')
  const [deliveryOrderSysNo] = useField('deliveryOrderSysNo')
  const [trackingNo] = useField('trackingNo')
  const [latestEventCodes] = useField('latestEventCodes')
  const [deliveryEventStartDate] = useField('deliveryEventStartDate')
  const [deliveryEventEndDate] = useField('deliveryEventEndDate')
  const [deliveryOrderStartDate] = useField('deliveryOrderStartDate')
  const [deliveryOrderEndDate] = useField('deliveryOrderEndDate')
  const [isLabelNotPrinted] = useField('isLabelNotPrinted')

  const response = useSelector(({ response }) => response)

  const { reset } = useFormContext()

  const {
    lastMileProviderLabel,
    statusLabel,
    deliveryOrderRefNoLabel,
    deliveryOrderSysNoLabel,
    tackingNoLabel,
    serviceStatusLabel,
    serviceStartDateLabel,
    serviceEndDateLabel,
    creationStartDateLabel,
    creationEndDateLabel,
    printLabelStatusFilterLabel
  } = TranslationText()


  const resetForm = () => {
    reset({
      lastMileShipmentProviderIDs: [],
      deliveryOrderStatusIDs: [],
      deliveryOrderRefNo: '',
      deliveryOrderSysNo: '',
      trackingNo: '',
      latestEventCodes: [],
      deliveryEventStartDate: '',
      deliveryEventEndDate: '',
      deliveryOrderStartDate: '',
      deliveryOrderEndDate: '',
      isLabelNotPrinted: false
    })
  }

  const submitForm = () => {
    let filterProperties = []
    if (lastMileShipmentProviderIDs.value && lastMileShipmentProviderIDs.value.length > 0) {
      filterProperties.push({
        fieldName: 'lastMileShipmentProviderId',
        operatorType: 'IN',
        value1: lastMileShipmentProviderIDs.value.join('|')
      })
    }
    if (deliveryOrderStatusIDs.value && deliveryOrderStatusIDs.value.length > 0) {
      filterProperties.push({
        fieldName: 'deliveryOrderStatusId',
        operatorType: 'IN',
        value1: deliveryOrderStatusIDs.value.join('|')
      })
    }
    if (deliveryOrderRefNo.value) {
      filterProperties.push({
        fieldName: 'deliveryOrderRefNo',
        operatorType: 'LIKE',
        value1: deliveryOrderRefNo.value
      })
    }
    if (deliveryOrderSysNo.value) {
      filterProperties.push({
        fieldName: 'deliveryOrderSysNo',
        operatorType: 'LIKE',
        value1: deliveryOrderSysNo.value
      })
    }
    if (trackingNo.value) {
      filterProperties.push({ fieldName: 'trackingNo', operatorType: 'LIKE', value1: trackingNo.value })
    }
    if (isLabelNotPrinted.value) {
      filterProperties.push({ fieldName: 'isPrinted', operatorType: 'EQUAL', value1: false })
    }
    if (deliveryOrderStartDate.value) {
      filterProperties.push({
        fieldName: 'createdOn',
        operatorType: 'GREATERTHANEQUAL',
        value1: new Date(deliveryOrderStartDate.value).getTime()
      })
    }
    if (deliveryOrderEndDate.value) {
      filterProperties.push({
        fieldName: 'createdOn',
        operatorType: 'LESSERTHAN',
        value1: new Date(deliveryOrderEndDate.value).getTime()
      })
    }
    if (latestEventCodes.value && latestEventCodes.value.length > 0) {
      filterProperties.push({
        fieldName: 'latestEventCodes',
        operatorType: 'IN',
        value1: latestEventCodes.value.join('|')
      })
    }
    if (deliveryEventStartDate.value) {
      filterProperties.push({
        fieldName: 'latestEventCreatedOn',
        operatorType: 'GREATERTHANEQUAL',
        value1: new Date(deliveryEventStartDate.value).getTime()
      })
    }
    if (deliveryEventEndDate.value) {
      filterProperties.push({
        fieldName: 'latestEventCreatedOn',
        operatorType: 'LESSERTHAN',
        value1: new Date(deliveryEventEndDate.value).getTime()
      })
    }

    dispatch({ type: 'SET_FILTERS', payload: filterProperties })
    dispatch({ type: 'SET_PAGE_NUMBER', payload: 1 })
    dispatch({ type: 'SET_TRIGGER_FETCH', payload: true })

  }


  return (<>
      <Grid container spacing={3}>
        <CngGridItem xs={12} sm={12}>
          <Card>
            <CardContent>
              <Grid container>

                {response && response.length}

                <Grid container spacing={2}>
                  <CngGridItem xs={12} sm={3}>

                    <CngLookupAutocompleteField
                      size='small'
                      multiple={true}
                      disableCloseOnSelect={true}
                      limitTags={0}
                      name='lastMileShipmentProviderIDs'
                      label={lastMileProviderLabel}
                      lookupProps={{
                        url: ShipmentProviderUrls.GET_FOR_USER_PARTY,
                        label: 'name',
                        value: 'id'
                      }}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip
                            variant='outlined'
                            data-value={option['value']}
                            data-label={option['label']}
                            key={option.key}
                            label={option.label}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      getLimitTagsText={(selectedItems) => `${selectedItems} selected`}
                    />
                  </CngGridItem>

                  <CngGridItem xs={12} sm={3}>
                    <CngLookupAutocompleteField
                      size='small'
                      name='deliveryOrderStatusIDs'
                      label={statusLabel}
                      multiple={true}
                      limitTags={0}
                      disableCloseOnSelect={true}
                      lookupProps={{
                        url: SCOAdminApiUrls.GET_CODE_MASTER_LIST,
                        label: 'name',
                        value: 'id',
                        filters: [
                          {
                            field: 'codeType',
                            operator: EQUAL,
                            value: 'CEC_DELIVERY_ORDER_STATUS'
                          }
                        ]
                      }}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip
                            variant='outlined'
                            data-value={option['value']}
                            data-label={option['label']}
                            key={option.key}
                            label={option.label}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      getLimitTagsText={(selectedItems) => `${selectedItems} selected`}
                    >
                    </CngLookupAutocompleteField>
                  </CngGridItem>

                  <CngGridItem xs={12} sm={3}>
                    <CngTextField
                      size='small'
                      name='deliveryOrderRefNo'
                      label={deliveryOrderRefNoLabel}
                    />
                  </CngGridItem>

                  <CngGridItem xs={12} sm={3}>
                    <CngTextField
                      size='small'
                      name='deliveryOrderSysNo'
                      label={deliveryOrderSysNoLabel}
                    />
                  </CngGridItem>

                  <CngGridItem xs={12} sm={3}>
                    <CngTextField
                      size='small'
                      name='trackingNo'
                      label={tackingNoLabel}
                    />
                  </CngGridItem>

                  <CngGridItem xs={12} sm={3}>

                    <CngLookupAutocompleteField
                      size='small'
                      name='latestEventCodes'
                      label={serviceStatusLabel}
                      multiple={true}
                      limitTags={0}
                      disableCloseOnSelect={true}
                      lookupProps={{
                        url: SCOAdminApiUrls.GET_CODE_MASTER_LIST,
                        label: 'name',
                        value: 'code',
                        filters: [
                          {
                            field: 'codeType',
                            operator: EQUAL,
                            value: 'CEC_DELIVERY_ORDER_EVENT'
                          }
                        ]
                      }}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip
                            variant='outlined'
                            data-value={option['value']}
                            data-label={option['label']}
                            key={option.key}
                            label={option.label}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      getLimitTagsText={(selectedItems) => `${selectedItems} selected`}
                    >
                    </CngLookupAutocompleteField>
                  </CngGridItem>

                  <CngGridItem xs={12} sm={3}>

                    <CngDateTimeField
                      name='deliveryEventStartDate'
                      label={serviceStartDateLabel}
                      format='YYYY-MM-DD HH:mm:ss'
                      size='small'
                      toserverdataformat='YYYY-MM-DD HH:mm:ss'
                    />
                  </CngGridItem>

                  <CngGridItem xs={12} sm={3}>
                    <CngDateTimeField
                      name='deliveryEventEndDate'
                      label={serviceEndDateLabel}
                      format='YYYY-MM-DD HH:mm:ss'
                      size='small'
                      toserverdataformat='YYYY-MM-DD HH:mm:ss'
                    />
                  </CngGridItem>

                  <CngGridItem xs={12} sm={3}>
                    <CngDateTimeField
                      name='deliveryOrderStartDate'
                      label={creationStartDateLabel}
                      format='YYYY-MM-DD HH:mm:ss'
                      size='small'
                      toserverdataformat='YYYY-MM-DD HH:mm:ss'
                    />
                  </CngGridItem>

                  <CngGridItem xs={12} sm={3}>
                    <CngDateTimeField
                      name='deliveryOrderEndDate'
                      label={creationEndDateLabel}
                      format='YYYY-MM-DD HH:mm:ss'
                      size='small'
                      toserverdataformat='YYYY-MM-DD HH:mm:ss'
                    />
                  </CngGridItem>

                  <CngGridItem xs={12} sm={3}
                               style={{
                                 display: 'flex',
                                 justifyContent: 'flex-end',
                                 alignItems: 'center'
                               }}>
                    <CngCheckboxField
                      name='isLabelNotPrinted'
                      label={
                        <Typography variant='body2' gutterBottom>
                          {printLabelStatusFilterLabel}
                        </Typography>
                      }
                    />
                  </CngGridItem>

                  <CngGridItem xs={12} sm={3}>
                    <Grid container spacing={3}>

                      <CngGridItem xs={12} sm={6}>
                        <Button variant='outlined' startIcon={<ReplayIcon />}
                                size='large'
                                onClick={resetForm}
                                style={{
                                  color: '#e91e63',
                                  width: '100%',
                                  textTransform: 'none'
                                }}>
                          Reset
                        </Button>
                      </CngGridItem>

                      <CngGridItem xs={12} sm={6}>
                        <Button variant='outlined' startIcon={<SearchIcon />}
                                onClick={submitForm}
                                color='primary' size='large'
                                style={{ width: '100%', textTransform: 'none' }}>
                          Search
                        </Button>
                      </CngGridItem>

                    </Grid>
                  </CngGridItem>

                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </CngGridItem>
      </Grid>
    </>

  )
}

const DEFAULT_INITIAL_VALUES = {
  lastMileShipmentProviderIDs: [],
  deliveryOrderStatusIDs: [],
  deliveryOrderRefNo: '',
  deliveryOrderSysNo: '',
  trackingNo: '',
  latestEventCodes: [],
  deliveryEventStartDate: '',
  deliveryEventEndDate: '',
  deliveryOrderStartDate: '',
  deliveryOrderEndDate: '',
  isLabelNotPrinted: false
}

const hookFormProps = {
  initialValues: JSON.parse(JSON.stringify(DEFAULT_INITIAL_VALUES))
}

const SearchFormProperties = Object.freeze({
  hookFormProps: hookFormProps,
  Fields: Fields
})

export default SearchFormProperties
