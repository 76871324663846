import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Card, CardContent, Divider, Tooltip, Typography } from '@material-ui/core'
import { Grid } from '@mui/material'
import { components, constants, useServices } from 'cng-web-lib'
import CountryLabel from './CountryLabel'
import SCOAdminApiUrls from '../../../../../apiUrls/SCOAdminApiUrls'
import EmptyBox from './EmptyBox'
import clsx from 'clsx'


AddressDetails.propTypes = {
  address: PropTypes.object,
  deliveryPartyType: PropTypes.string,
  template: PropTypes.string,
  showErrorNotification: PropTypes.func
}

const {
  filter: { EQUAL }
} = constants


const {
  CngGridItem
} = components

const getStateCodeByRemovingCountryCodePrefix = (countryCodePlusStateCode, countryCode) => {
  const index = countryCodePlusStateCode.indexOf(countryCode)
  if (index === -1) {
    return countryCodePlusStateCode
  }
  return countryCodePlusStateCode.slice(0, index) + countryCodePlusStateCode.slice(index + countryCode.length)
}

function AddressDetails(props) {
  const { address, deliveryPartyType, showErrorNotification, template } = props
  const [stateName, setStateName] = useState('')
  const [addressType, setAddressType] = useState('')
  const { fetchRecords } = useServices()

  if (address.address && address.address.state && address.address.country) {
    fetchRecords.execute(SCOAdminApiUrls.GET_COUNTRY_STATE_LIST, {
      filters: [{
        field: 'countryCode', operator: EQUAL, value: address.address.country
      }, {
        field: 'stateCode',
        operator: EQUAL,
        value: getStateCodeByRemovingCountryCodePrefix(address.address.state, address.address.country)
      }]
    }, ((response) => response.content.length == 1 && response.content[0].countryCode === address.address.country && response.content[0].stateCode === getStateCodeByRemovingCountryCodePrefix(address.address.state, address.address.country) ? setStateName(response.content[0].stateName) : null), () => {
      showErrorNotification('Failed to fetch state details.')
    })
  }

  if (address.address.type) {
    fetchRecords.execute(SCOAdminApiUrls.GET_CODE_MASTER_LIST, {
      filters: [{
        field: 'codeType', operatorType: EQUAL, value: 'CEC_ADDRESS_TYPE'
      }, {
        field: 'name', operatorType: EQUAL, value: address.address.type
      }]
    }, (data) => {
      if (data.content) {
        setAddressType(data.content.filter(u => u['codeType'] === 'CEC_ADDRESS_TYPE' && u['name'] === address.address.type).map(u => u['description'])[0])
      }
    }, () => {
      showErrorNotification('Error while retrieving address type.')
    })
  }

  const extractTemplateLabelText = template => template.length > 20 ? template.slice(0, 20) + '...' : template

  const getTemplateName = template => template ? extractTemplateLabelText(template) : 'N/A'

  return (<Grid item xs={12}>
    <Card>
      <CardContent>

        <Typography className={'boldLabel'} style={{ fontSize: 16, marginBottom: '1rem' }}>
          {deliveryPartyType.toLowerCase().trim() === 'shipper' ? 'Shipper' : 'Consignee'}
        </Typography>

        <Grid container spacing={1}>

          <CngGridItem xs={6} sm={6}>
            <Typography className={'greyLabel'} style={{ fontSize: 14, fontWeight: 'bold' }}>
              {addressType}
            </Typography>
          </CngGridItem>

          <CngGridItem xs={6} sm={6} style={{ textAlign: 'right' }}>
            <Typography display='inline' className={clsx('greyLabel')}
                        style={{ fontSize: 14, fontWeight: 'bold', width: 'fit-content' }}
                        hidden={deliveryPartyType !== 'shipper'}>
              {'Template: '}
            </Typography>
            <Tooltip
              title={'Template for: ' + (template ? template : 'N/A')}>
              <Typography display='inline' className={clsx('greyLabel')}
                          style={{ fontSize: 14, fontWeight: 'bold', width: 'fit-content' }}
                          hidden={deliveryPartyType !== 'shipper'}>
                {getTemplateName(template)}
              </Typography>
            </Tooltip>
          </CngGridItem>

          <CngGridItem xs={12} sm={12}>
            <Divider />
          </CngGridItem>

          <CngGridItem xs={12} sm={4}>
            <Typography variant='caption' color='textSecondary'>First name</Typography>
            <Typography variant='body2' noWrap={true}>{address.firstName ? address.firstName :
              <EmptyBox />}</Typography>
          </CngGridItem>

          <CngGridItem xs={12} sm={4}>
            <Typography variant='caption' color='textSecondary'>Last name</Typography>
            <Typography variant='body2' noWrap={true}>{address.lastName ? address.lastName :
              <EmptyBox />}</Typography>
          </CngGridItem>

          <CngGridItem xs={12} sm={4}>
            <Typography variant='caption' color='textSecondary'>Contact no.</Typography>
            <Typography variant='body2' noWrap={true}>{address.phone ? address.phone :
              <EmptyBox />}</Typography>
          </CngGridItem>

          <CngGridItem xs={12} sm={4}>
            <Typography variant='caption' color='textSecondary'>Emaill address</Typography>
            <Typography variant='body2' noWrap={true}>{address.email ? address.email :
              <EmptyBox />}</Typography>
          </CngGridItem>

          <CngGridItem xs={12} sm={4}>
            <Typography variant='caption' color='textSecondary'>Company name</Typography>
            <Typography variant='body2' noWrap={true}>{address.companyName ? address.companyName :
              <EmptyBox />}</Typography>
          </CngGridItem>

          <CngGridItem xs={12} sm={4}>
            <Typography variant='caption' color='textSecondary'>Fax no.</Typography>
            <Typography variant='body2' noWrap={true}>{address.fax ? address.fax : <EmptyBox />}</Typography>
          </CngGridItem>

          <CngGridItem xs={12} sm={4}>
            <Typography variant='caption' color='textSecondary'>Tax no.</Typography>
            <Typography variant='body2' noWrap={true}>{address.taxNo ? address.taxNo :
              <EmptyBox />}</Typography>
          </CngGridItem>

          <CngGridItem xs={12} sm={4}>
            <Typography variant='caption' color='textSecondary'>Country</Typography>
            <Typography variant='body2'>
              <CountryLabel countryCode={address.address.country}
                            showErrorNotification={showErrorNotification} />
            </Typography>
          </CngGridItem>

          <CngGridItem xs={12} sm={4}>
            <Typography variant='caption' color='textSecondary'>State / Province</Typography>
            <Typography variant='body2'>{(address.address.country && address.address.state) ? stateName :
              <EmptyBox />}</Typography>
          </CngGridItem>

          <CngGridItem xs={12} sm={4}>
            <Typography variant='caption' color='textSecondary'>Other state / Province</Typography>
            <Typography variant='body2'
                        noWrap={true}>{address.address.stateOther ? address.address.stateOther :
              <EmptyBox />}</Typography>
          </CngGridItem>

          <CngGridItem xs={12} sm={4}>
            <Typography variant='caption' color='textSecondary'>City</Typography>
            <Typography variant='body2' noWrap={true}>{address.address.city ? address.address.city :
              <EmptyBox />}</Typography>
          </CngGridItem>

          <CngGridItem xs={12} sm={4}>
            <Typography variant='caption' color='textSecondary'>Street</Typography>
            <Typography variant='body2' noWrap={true}>{address.address.street ? address.address.street :
              <EmptyBox />}</Typography>
          </CngGridItem>

          <CngGridItem xs={12} sm={4}>
            <Typography variant='caption' color='textSecondary'>Postal / Zip code</Typography>
            <Typography variant='body2'>{address.address.postcode ? address.address.postcode :
              <EmptyBox />}</Typography>
          </CngGridItem>

        </Grid>

      </CardContent>
    </Card>
  </Grid>)
}

export default AddressDetails
