import { BasePage as CngBasePage } from 'cng-web-lib'
import ManagePermitPage from './ManagePermitPage'
import React from 'react'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_Compliance'
import ComplianceTranslationText from '../ComplianceTranslationText'

function WrappedManagePermitPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  const translatedTextsObject = ComplianceTranslationText()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.title}
      renderPage={(showSnackbar) => (
        <ManagePermitPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}


export {
  WrappedManagePermitPage as ManagePermitPage
}
