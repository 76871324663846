import React, {useState} from 'react'
import { components, constants, useServices } from 'cng-web-lib'
import { Card, CardContent, Grid } from "@material-ui/core";
import FormProperties from "./CeCReport_FormProperties";
import ReportUrls from "../../../apiUrls/cec-admin/report/ReportUrls";
import moment from "moment";
import {useFormContext} from "react-hook-form";

const {
    form: { CngForm }
} = components

const { FormState, NotificationType } = constants

function CeCReportViewPage({ showNotification }) {

    const { securedSendRequest } = useServices()
    const [loading, setLoading] = useState(false)

    function onComplete() {
        setLoading(false)
        console.log("complete")
    }

    function validateField(input) {

        if(input.deliveryEventStartDate != null && input.deliveryEventEndDate != null) {
            let serviceFromDate = moment(input.deliveryEventStartDate)
            let serviceToDate = moment(input.deliveryEventEndDate)
            let serviceDayDiff = serviceToDate.diff(serviceFromDate, "days")

            if(serviceDayDiff < 0) {
                showNotification(NotificationType.ERROR, "Delivery event end date should be after delivery event start date")
                return true
            }
        } else if(input.deliveryEventStartDate != null && input.deliveryEventEndDate == null) {
            showNotification(NotificationType.ERROR, "Please select delivery event end date")
            return true
        } else if(input.deliveryEventStartDate == null && input.deliveryEventEndDate != null) {
            showNotification(NotificationType.ERROR, "Please select delivery event start date")
            return true
        }

        if(input.creationStartDate != null && input.creationEndDate != null) {
            let creationFromDate = moment(input.creationStartDate)
            let creationToDate = moment(input.creationEndDate)
            let creationDayDiff = creationToDate.diff(creationFromDate, "days")

            if(creationDayDiff < 0) {
                showNotification(NotificationType.ERROR, "Creation end date should be after creation start date")
                return true
            }
        } else if(input.creationStartDate != null && input.creationEndDate == null) {
            showNotification(NotificationType.ERROR, "Please select creation end date")
            return true
        } else if(input.creationStartDate == null && input.creationEndDate != null) {
            showNotification(NotificationType.ERROR, "Please select creation start date")
            return true
        }

    }

    const onSubmit = (input) => {

        if(validateField(input))
            return

        if(input.creationEndDate != null)
            input.creationEndDate = moment(input.creationEndDate).add(1, 'd').format("YYYY-MM-DD")

        if(input.deliveryEventEndDate != null)
            input.deliveryEventEndDate = moment(input.deliveryEventEndDate).add(1, 'd').format("YYYY-MM-DD")

        setLoading(true)
        let url = ReportUrls.REPORT_FETCH;

        securedSendRequest.execute("POST", url, input,
            (response) => {

                let date = moment().format('DD-MM-YYYY')

                let blob = response.data
                let fileName = "CeC_General_Report_" + date + ".xlsx";

                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = fileName;
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
                setLoading(false)
            },
            (error) => {
                setLoading(false)
                console.error(error)
                error.response.data.text().then(value => showNotification(NotificationType.ERROR, value))
            },
            onComplete,
            {responseType: "blob"}

        )

    }

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <CngForm
                            formikProps={{
                                initialValues: FormProperties.formikProps.initialValues,
                                validationSchema: FormProperties.formikProps.validationSchema,
                                onSubmit: onSubmit,
                            }}
                            formState={FormState.COMPLETED}
                            renderBodySection={() => <>
                                <FormProperties.Fields />
                            </>}
                            renderButtonSection={() => <>
                                <FormProperties.Buttons loading={loading} />
                            </>}
                        />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
        //* End JSX ------------------------------------------------------------

    );
    //* End of function --------------------------------------------------------
}

export default CeCReportViewPage