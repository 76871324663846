import React from 'react'
import DoDocumentComponent from './components/DoDocumentComponent'

const DEFAULT_INITIAL_VALUES = Object.freeze({
      ...DoDocumentComponent.initialValues,
})

const FORMIK_PROPS = {
      initialValues: { ...DEFAULT_INITIAL_VALUES }
}

function FormFields({ keys, showNotification, submitting}) {

      return (
            <>
              <DoDocumentComponent.FormBody keys={keys} showNotification={showNotification} submitting={submitting}
              />
            </>
          )

}


const UploadFormProperties = Object.freeze({
      formikProps: FORMIK_PROPS,
      FormFields: FormFields
})

export default UploadFormProperties