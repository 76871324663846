import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    Grid, Box, Button, Typography,
    CircularProgress
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { components, useServices, constants } from 'cng-web-lib'
import AccordionHeaderComponent from 'src/views/common/ui/AccordionHeaderComponent'
import TranslationText from '../TranslatedText'
import { useFormContext } from 'react-hook-form'
import SCOAdminApiUrls from 'src/apiUrls/SCOAdminApiUrls'
import DownloadTemplateDialog from './DownloadTemplateDialog'

const {
    form: {
        adapter: {
            useFormAdapter: { useFormikContext }
        }
    },
    CngGridItem,
    dropzone: { CngFileUpload, HelperTextArea },
    table: { useDefaultNotification },
    button : {CngPrimaryButton}
} = components

const initialValues = {
    pendingDocuments: []
}
const {
    filter: { EQUAL }
} = constants



const FormBody = (props) => {

    const {
        handleSubmit
    } = useFormContext()

    const translatedTextsObject = TranslationText();

    const { fetchRecords } = useServices()


    const [fieldConfig, setFieldConfig] = useState([])
    const { setFieldValue } = useFormikContext()


    const { error: showErrorNotification } = useDefaultNotification(props.showNotification)
    const [shouldRender, setShouldRender] = useState(false)

    const [dialogOpen, setDialogOpen] = useState(false)

    const [uploadFile, setUploadFile] = useState(null)

    const downloadTemplateClickHandler = () => {
        setDialogOpen(true)
    }

    const showErrorMessages = (errorMessages) => {
        showErrorNotification(errorMessages[0])
    }

    const moreActions = [
        {
            action: 'remove',
            name: translatedTextsObject.deleted,
            icon: ['fal', 'trash']
        }
    ]

    useEffect(() => {
        fetchRecords.execute(
            SCOAdminApiUrls.GET_CODE_MASTER_LIST,
            {
                filters: [
                    {
                        field: 'codeType',
                        operator: EQUAL,
                        value: 'CEC_FILE_UPLOAD_TYPE'
                    },
                    {
                        field: 'status',
                        operator: EQUAL,
                        value: true
                    }]
            },
            onLoadDDLType
        )

        return () => {
            fetchRecords.abort()
        }

    }, [])

    function onLoadDDLType(data) {
        let docTypeOptionList = []
        if (data.content.length > 0) {
            data.content.map((singleDocTypeOption) => {
                let detailedOption = {
                    value: singleDocTypeOption.code,
                    label: singleDocTypeOption.name
                }
                docTypeOptionList.push(detailedOption)
            })
        }


        let currentFieldConfig = [
            {
                fieldType: 'dropDown',
                fieldName: 'docType',
                label: 'File type',
                mandatory: true,
                error: false,
                errorMessage: 'Required',
                validationRule: '',
                dropDownLov: docTypeOptionList
            }

        ]

        setFieldConfig(currentFieldConfig)
        setShouldRender(true)

    }

    const setSelectedFiles = (file) => {
        let processedFileObjs = []
        file.forEach((item) => {
            processedFileObjs.push(item)
        })
        setFieldValue('pendingDocuments', processedFileObjs)
        setUploadFile(file)
    }

    if (!shouldRender) {
        return null
    }



    return (
        <Grid container style={{ display: 'block' }} >
            <Card>
                <Accordion key={props.keys} expanded={true} style={{ marginBottom: 20 }}>
                    <AccordionSummary
                        aria-controls='documentContent'
                        id='documentComponentHeader'>
                        <Grid container>
                            <Grid container spacing={2} >
                                <CngGridItem xs={12} sm={12}>
                                    <AccordionHeaderComponent hideMandatory={true} />
                                </CngGridItem>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails >
                        <Grid container spacing={2}>
                            <CngGridItem xs={12} sm={12}>
                                <CngFileUpload
                                    accept={[
                                        '.xls',
                                        '.xlsx'
                                    ]}
                                    maxSize={10485760}
                                    maxFiles={1}
                                    disabled={props.submitting}
                                    onFileSelect={setSelectedFiles}
                                    showFormFields={true}
                                    files={uploadFile}
                                    fieldConfig={fieldConfig}
                                    moreActions={moreActions}
                                    onDropRejected={(error) => showErrorMessages(error)}
                                    renderHelperText={() => {
                                        return (<>
                                            <HelperTextArea
                                                accept={[
                                                    '.xls',
                                                    '.xlsx'
                                                ]}
                                                maxFiles={1}
                                                maxSize={1048576}
                                            />
                                            <br/>
                                            <Typography style={{ fontSize: '12px', marginTop: '-24px', marginLeft: '10px', color:'#7680a3' }}>
                                                {translatedTextsObject.docMessage}
                                                </Typography>
                                            </>
                                        )
                                    }}
                                />
                            </CngGridItem>
                            <CngGridItem xs={12} sm={12}>
                                <Box style={{ float: 'right' }} pt={2} >
                                    <CngPrimaryButton
                                        type='submit'
                                        disabled={uploadFile==null || props.submitting}
                                        style={{ width:'10rem' }}
                                        onClick={() => handleSubmit()}
                                        endIcon={
                                            props.submitting ? (
                                              <CircularProgress color='inherit' size={10} />
                                            ) : null
                                          }
                                    >
                                        {props.submitting? translatedTextsObject.uploading: translatedTextsObject.upload}
                                    </CngPrimaryButton>
                                </Box>
                                <Box style={{ float: 'right' }} pr={2} pt={2} >
                                    <Button
                                        variant='contained'
                                        color='secondary'
                                        classes={{ root: 'ng-button-filled-secondary' }}
                                        style={{ minHeight: '42px', width:'10rem' }}
                                        onClick={() => { downloadTemplateClickHandler() }}
                                    >
                                        {translatedTextsObject.selectTemplate}
                                    </Button>
                                </Box>
                            </CngGridItem>
                        </Grid>
                        <DownloadTemplateDialog
                            isDialogOpen={dialogOpen}
                            closeDialog={() => setDialogOpen(false)}
                            dialogTitle={translatedTextsObject.selectTemplate}
                        />
                    </AccordionDetails>
                </Accordion>

            </Card >
        </Grid>
    )
}


const DoDocumentComponent = Object.freeze({
    FormBody: FormBody,
    initialValues: initialValues
})

export default DoDocumentComponent