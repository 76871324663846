import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import PropTypes from 'prop-types'
import { components, constants, useServices } from 'cng-web-lib'
import { makeStyles } from '@material-ui/core'
import pathMap from '../../../../../paths/PathMap_eCommerce'
import FormProperties from './form/FormProperties'
import DeliveryOrderApiUrls from '../../../../../apiUrls/ecommerce/delivery-order/DeliveryOrderApiUrls'
import { useHistory } from 'react-router-dom'
import {
  convertDataFromAPIToFormData,
  convertFormDataForAPI,
  convertMessageReceivedFromDeliveryOrderAPI
} from '../util/Utils'
import CngBackdrop from '../../../../vesselschedule/searchschedule/cngcomponent/CngBackDrop'
import SCORateMgmtApiUrls from '../../../../../apiUrls/SCORateMgmtApiUrls'
import SCOAdminApiUrls from '../../../../../apiUrls/SCOAdminApiUrls'

StepperForm.propTypes = {
  showNotification: PropTypes.func,
  actionType: PropTypes.string
}

const steps = ['Provide details', 'Add items', 'Finalise order']
const {
  filter: {
    EQUAL
  }, FormState, NotificationType
} = constants


function StepperForm(props) {

  const { showNotification, actionType, deliveryOrderRefNo } = props
  const [activeStep, setActiveStep] = useState(0)
  const [salesOrderCurrency, setSalesOrderCurrency] = useState('')
  const [partyTemplates, setPartyTemplates] = useState()
  const [deliveryOrderItems, setDeliveryOrderItems] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const { securedSendRequest, fetchRecords } = useServices()
  const history = useHistory()
  const [deliveryOrder, setDeliveryOrder] = useState()

  const updatePartyTemplate = (partyType, partyTemplate) => {
    setPartyTemplates({ ...partyTemplates, [partyType]: partyTemplate })
  }

  const fetchCurrencyDetails = (currencyCode) => {
    if (currencyCode) {
      fetchRecords.execute(
        SCORateMgmtApiUrls.GET_CURRENCY,
        {
          filters: [
            {
              field: 'status',
              operator: EQUAL,
              value: true
            },
            {
              field: 'code',
              operator: EQUAL,
              value: currencyCode
            }
          ]
        },
        ((response) =>
          response.content.length == 1 && response.content[0].code === currencyCode ? setSalesOrderCurrency(response.content[0])
            : null),
        () => {
          showNotification(NotificationType.ERROR, 'Failed to fetch total shipment currency details.')
        }
      )
    } else {
      setSalesOrderCurrency(null)
    }
  }


  useEffect(() => {
    console.log('initializing stepper')
    if (actionType === 'edit' && deliveryOrderRefNo) {
      setIsLoading(true)
      fetchDeliveryOrderData(deliveryOrderRefNo)
    } else {
      setDeliveryOrder(FormProperties.hookFormProps.initialValues)
      setPartyTemplates({
        shipper: { code: '' }, consignee: { code: '' }
      })
    }
  }, [])

  const handleNext = () => {
    setActiveStep(activeStep + 1)
  }
  const handleBack = () => {
    setActiveStep(activeStep - 1)
  }

  const {
    form: {
      CngForm
    },
    table: { useDefaultNotification }
  } = components

  const {
    success: showSuccessNotification,
    error: showErrorNotification
  } = useDefaultNotification(showNotification)

  const onError = (error) => {
    showErrorNotification('Error while saving the delivery order.')
    setIsLoading(false)
  }

  const fetchDeliveryOrderData = (deliveryOrderRefNo) => {
    securedSendRequest.execute(
      'GET',
      DeliveryOrderApiUrls.GET_BY_REF_NO + deliveryOrderRefNo,
      {},
      (request) => {
        if (request && request.status === 200 && request.data && request.data.data) {
          const existingData = convertDataFromAPIToFormData(request.data.data)
          if (existingData.currency) {
            fetchCurrencyDetails(existingData.currency)
          }
          if (existingData.shipperAddressTemplate) {
            setPartyTemplates({
              shipper: { code: existingData.shipperAddressTemplate }, consignee: { code: '' }
            })
          } else {
            setPartyTemplates({
              shipper: { code: '' }, consignee: { code: '' }
            })
          }
          if (existingData.item) {
            existingData.item.forEach(
              (item) => {
                if (item.price) {
                  item.price = item.price.toFixed(2)
                }
                if (item.declaredPrice) {
                  item.declaredPrice = item.declaredPrice.toFixed(2)
                }
              }
            )
            setDeliveryOrderItems(existingData.item)
          }

          let stateArray = new Set()
          let countryArray = new Set()
          if (existingData.shipper.state && existingData.shipper.country) {
            stateArray.add(existingData.shipper.state)
            countryArray.add(existingData.shipper.country)
          }
          if (existingData.consignee.state && existingData.consignee.country) {
            stateArray.add(existingData.consignee.state)
            countryArray.add(existingData.consignee.country)
          }

          if (stateArray.size > 0) {

            securedSendRequest.execute('POST', SCOAdminApiUrls.GET_COUNTRY_STATE_LIST, {
              'baseFilterDTO': {
                'filterType': 'AND', 'filterProperties': [{
                  fieldName: 'countryCode', operatorType: 'IN', value1: [...countryArray].join('|')
                }, {
                  fieldName: 'stateCode', operatorType: 'IN', value1: [...stateArray].join('|')
                }]
              }
            }, (res) => {
              if (res.status === 200) {
                if (existingData.shipper.state && existingData.shipper.country) {
                  existingData.shipper.stateId = res.data.content.filter(state => state['stateCode'] === existingData.shipper.state && state['countryCode'] === existingData.shipper.country).map(state => state['id'])[0]
                }

                if (existingData.consignee.state && existingData.consignee.country) {
                  existingData.consignee.stateId = res.data.content.filter(state => state['stateCode'] === existingData.consignee.state && state['countryCode'] === existingData.consignee.country).map(state => state['id'])[0]
                }
                setDeliveryOrder(existingData)
                setIsLoading(false)
              }
            }, () => showNotification('Error while retrieving state details.'))

          } else {
            setDeliveryOrder(existingData)
            setIsLoading(false)
          }


        } else {
          showErrorNotification('Error while retrieving delivery order data.')
          setIsLoading(false)
        }
      },
      onError
    )
  }

  const onSubmitForCreate = (formData) => {
    handleFormSubmission('add', formData)
  }

  const handleFormSubmission = (actionType, data) => {

    const apiUrl = actionType === 'add' ? DeliveryOrderApiUrls.CREATE : DeliveryOrderApiUrls.UPDATE_BY_REF_NO + data.deliveryOrderRefNo
    const httpMethod = actionType === 'add' ? 'POST' : 'PUT'
    const successMassage = actionType === 'add' ? 'Order created.' : 'Order updated.'

    securedSendRequest.execute(
      httpMethod,
      apiUrl,
      convertFormDataForAPI(data),
      (request) => {
        if (request && request.status === 200 && request.data && request.data.data && request.data.meta && request.data.meta.code === 200) {
          showSuccessNotification(successMassage)
          history.push(pathMap.CEC_DELIVERY_ORDER_LIST_VIEW)
        } else {
          let errorMessage = 'Error while saving the delivery order. '
          if (request.data && request.data.meta && request.data.meta.message) {
            errorMessage += request.data.meta.message
          }
          showErrorNotification(convertMessageReceivedFromDeliveryOrderAPI(errorMessage))
        }
      },
      onError
    )


  }

  const onSubmitForEdit = (formData) => {
    handleFormSubmission('edit', formData)
  }

  const useStyles = makeStyles({
    secondContainer: {
      paddingBottom: 20,
      paddingRight: 0,
      paddingLeft: '0',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '110%',
      height: '40'
    },
    stepLabel: {
      fontSize: '1.2rem !important',
      fontWeight: 'bolder'
    }
  })

  const classes = useStyles()

  return (

    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <CngBackdrop loading={isLoading} />
      </Grid>

      <Grid item xs={12}>
        <Grid container justifyContent='center'>
          <Grid item xs={10} sm={8}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                return (
                  <Step key={label}>
                    <StepLabel classes={{ label: classes.stepLabel }}>{label}</StepLabel>
                  </Step>
                )
              })}
            </Stepper>
          </Grid> {/* item */}
        </Grid>{/* container */}
      </Grid> {/* item */}

      <Grid item xs={12}>
        <Grid container
              className={classes.secondContainer}
        >

          <Grid item xs={12}>
            {(deliveryOrder && partyTemplates) &&
              <CngForm
                formState={FormState.COMPLETED}
                fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                renderBodySection={
                  () => <FormProperties.Fields
                    activeStep={activeStep}
                    steps={steps}
                    handleNext={handleNext}
                    handleBack={handleBack}
                    actionType={actionType}
                    showNotification={showNotification}
                    salesOrderCurrency={salesOrderCurrency}
                    fetchCurrencyDetails={fetchCurrencyDetails}
                    partyTemplates={partyTemplates}
                    updatePartyTemplate={updatePartyTemplate}
                    deliveryOrderItems={deliveryOrderItems}
                    setDeliveryOrderItems={setDeliveryOrderItems}
                  />
                }
                hookFormProps={{
                  initialValues: deliveryOrder,
                  validationSchema: FormProperties.hookFormProps.makeValidationSchema,
                  mode: 'all',
                  onSubmit: actionType === 'add' ? onSubmitForCreate : onSubmitForEdit
                }}
                renderButtonSection={() => undefined}
              />}
          </Grid>

        </Grid>{/* container */}
      </Grid> {/* item */}


    </Grid>
  )
}

export default StepperForm
