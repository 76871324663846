import React, { forwardRef, useEffect, useRef } from 'react'
import { Box, Button, IconButton, makeStyles, Typography } from '@material-ui/core'
import PrinterIcon from '@material-ui/icons/LocalPrintshopOutlined'
import MaterialTable, { MTableToolbar } from 'material-table'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import ClearIcon from '@material-ui/icons/Clear'
import TablePagination from './TablePagination'
import { components } from 'cng-web-lib'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import SkipPreviousRoundedIcon from '@material-ui/icons/SkipPreviousRounded'
import SkipNextRoundedIcon from '@material-ui/icons/SkipNextRounded'
import NavigateNextRoundedIcon from '@material-ui/icons/NavigateNextRounded'
import NavigateBeforeRoundedIcon from '@material-ui/icons/NavigateBeforeRounded'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Utils from 'src/views/common/utils/Utils'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import Moment from 'moment'
import ECommercePathMap from '../../../../../../paths/PathMap_eCommerce'
import { useHistory } from 'react-router-dom'


DeliveryOrderTable.propTypes = {}

const {
  CngMoreActionsMenu
} = components


const tableIcons = {
  // * https://www.freakyjolly.com/react-material-table-how-to-show-icons-in-action-and-other-components/
  SortArrow: forwardRef((props, ref) => (
    <ArrowDropDownIcon {...props} ref={ref} />
  )),
  FirstPage: forwardRef((props, ref) => (
    <SkipPreviousRoundedIcon {...props} ref={ref} />
  )),
  LastPage: forwardRef((props, ref) => (
    <SkipNextRoundedIcon {...props} ref={ref} />
  )),
  NextPage: forwardRef((props, ref) => (
    <NavigateNextRoundedIcon {...props} ref={ref} />
  )),
  PreviousPage: forwardRef((props, ref) => (
    <NavigateBeforeRoundedIcon {...props} ref={ref} />
  )),
  ViewColumn: forwardRef((props, ref) => <FontAwesomeIcon icon='fa-table-columns' style={{ marginLeft: '1em' }} />)
}

const moreActionsList = [
  {
    action: 'edit',
    name: 'Edit',
    icon: ['fal', 'fa-pen-to-square']
  },
  {
    action: 'print',
    name: 'Print Label',
    icon: ['fal', 'print']
  }
]

export const useDeliveryOrderStyles = makeStyles((theme) => ({
  table: {
    '& thead th': {
      backgroundColor: theme.palette.component.cngMuiTable.tableHeaderCellFill,
      textTransform: 'uppercase',
      fontWeight: theme.typography.fontWeightBold,
      fontSize: '.75rem',
      color: '#8181A5',
      lineHeight: 1.2,
      padding: theme.spacing(1, 2),
      '&:not(:first-child):not(:last-child)': {
        padding: theme.spacing()
      }
    },
    '& tbody td': {
      fontSize: '.75rem',
      color: theme.palette.text.gray900,
      '&.filter': {
        backgroundColor: theme.palette.component.cngMuiTable.tableHeaderCellFill
      },
      padding: theme.spacing(1, 2),
      '&:not(:first-child):not(:last-child)': {
        padding: theme.spacing()
      }
    },
    '& td, & th': {
      borderColor: theme.palette.background.ctaBtnActiveBorder
    },
    '& tbody tr:hover': {
      backgroundColor: theme.palette.component.cngMuiTable.tableHeaderCellFill
    }
  },
  limitedText: {
    maxWidth: '16em',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  action: {
    color: theme.palette.text.textSecondary,
    borderColor: theme.palette.background.ctaBtnActiveBorder,
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: theme.shape.borderRadius * 2,
    height: 36, width: 36,
    fontSize: '.875rem'
  },
  boldText: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '.875rem'
  },
  primaryText: {
    fontSize: '.875rem'
  },
  secondaryText: {
    fontSize: '.75rem',
    color: theme.palette.text.textSecondary
  },
  colorBlue: {
    color: theme.palette.primary.main
  },
  colorGreen: {
    color: theme.palette.success.main
  },
  colorOrange: {
    color: '#C48E1F'
  }

}))

const printIcon = () => {
  return <>
    <Button variant='outlined' startIcon={<PrinterIcon />}
            color='primary'
            style={{ width: '100%', textTransform: 'none' }}>
      Print Label
    </Button>
    <Button variant='text'>Text</Button>

    <Button variant='text'
            color='primary'
            style={{ width: '100%', textTransform: 'none' }}>
      X
    </Button>
  </>
}

function DeliveryOrderTable(props) {

  const history = useHistory()

  const classes = useDeliveryOrderStyles()
  const tableRef = useRef()
  const {
    deliveryOrders,
    pageNumber,
    pageSize,
    isLoading,
    totalRecords,
    sortColumn,
    sortType,
    selectedRows
  } = useSelector(({
                     deliveryOrders,
                     pageNumber,
                     pageSize,
                     isLoading,
                     totalRecords,
                     sortColumn,
                     sortType,
                     selectedRows
                   }) => ({
    deliveryOrders,
    pageNumber,
    pageSize,
    isLoading,
    totalRecords,
    sortColumn,
    sortType,
    selectedRows
  }))

  const dispatch = useDispatch()

  const clearSelection = () => {
    if (tableRef.current) {
      tableRef.current.onAllSelected(false)
    }
  }

  const handleSelectedAction = (rowData, action) => {
    switch (action) {
      case 'edit':
        history.push({
          pathname: ECommercePathMap.CEC_DELIVERY_ORDER_LIST_VIEW + '/edit/' + rowData.deliveryOrderRefNo
        })
        break

      case 'print':
        dispatch({ type: 'SET_SINGLE_PRINT', payload: rowData.deliveryOrderRefNo })
        break
    }
  }

  const handlePrintAction = () => {
    dispatch({ type: 'SET_TRIGGER_PRINT', payload: true })
  }

  const getColumns = () => {

    const columns = [
      {
        title: 'Last mile provider/ Tracking no.', field: 'trackingNo', render: rowData =>
          <Box>
            <Typography
              className={clsx(classes.boldText, classes.limitedText)}>{rowData.lastMileShipmentProviderName}</Typography>
            <Typography
              className={clsx(classes.secondaryText, classes.limitedText)}>{rowData.trackingNo}</Typography>
          </Box>
      },
      {
        title: 'Delivery order ref. no.', field: 'deliveryOrderRefNo',
        render: rowData =>
          <Typography
            className={clsx(classes.primaryText, classes.limitedText)}>{rowData.deliveryOrderRefNo}</Typography>
      }
      ,
      {
        title: 'Delivery event', field: 'trackingStatus', render: rowData => <Box>
          <Typography
            className={clsx(classes.limitedText)}>{(rowData.latestEvent && rowData.latestEvent.eventDescription) ? rowData.latestEvent.eventDescription : ''}</Typography>
          <Typography className={clsx(classes.secondaryText, classes.limitedText)}>

            {(rowData.latestEvent && rowData.latestEvent.eventTimestamp) ? Moment(rowData.latestEvent.eventTimestamp.substring(0, 19)).format('DD MMM YYYY , HH:mm') + ' ( ' + rowData.latestEvent.eventTimestamp.substring(19) + ' ) '
              : ''}
            {(rowData.latestEvent && rowData.latestEvent.detailedEventDescription) ? ' - ' + rowData.latestEvent.detailedEventDescription : ''}</Typography>
        </Box>
      },
      {
        title: 'Delivery order system no.', field: 'deliveryOrderSysNo', render: rowData =>
          <Typography
            className={clsx(classes.primaryText, classes.limitedText)}>{rowData.deliveryOrderSysNo}</Typography>
      },
      {
        title: 'Print label status', field: 'isPrinted', align: 'center', render: rowData =>
          rowData.isPrinted ? <CheckCircleIcon color='secondary' fontSize='small' /> : null
      },
      {
        title: 'Created on', field: 'createdOn',
        defaultSort: sortType,
        render: rowData => Utils.formatDate(rowData.createdOn, 'DD MMM YYYY , HH:mm', ' ')
      },
      {
        title: 'Delivery Order Status', field: 'deliveryOrderStatus', render: rowData => {
          const status = rowData.deliveryOrderStatus
          let cssClassName = ''
          switch (status) {
            case 'Created':
              cssClassName = 'colorBlue'
              break
            case 'Delivered':
              cssClassName = 'colorOrange'
              break
            case 'Ready':
              cssClassName = 'colorGreen'
              break
          }
          return <Typography
            className={clsx(classes.boldText, classes[cssClassName])}>{rowData.deliveryOrderStatus}</Typography>
        }
      },
      {
        title: 'Action', field: 'last_name',
        sorting: false,
        render: rowData => (
          <CngMoreActionsMenu
            type='secondary'
            className={classes.action}
            menuItems={moreActionsList}
            iconDirection='horizontal'
            data={rowData}
            onActionSelect={handleSelectedAction}

          />
        )
      }
    ]

    const clonedMap = columns.map(column => {
      if (sortColumn && sortType && sortColumn === column.field) {
        let clone = { ...column, defaultSort: sortType }
        return clone
      } else {
        return column
      }

    })

    return clonedMap
  }

  const onOrderChange = (sourceIndex, destinationIndex) => {
    if (sourceIndex !== -1 && destinationIndex != null) {
      const sortColumn = tableRef.current.dataManager.columns[sourceIndex].field
      dispatch({ type: 'SET_SORT_COLUMN', payload: sortColumn })
      dispatch({ type: 'SET_SORT_TYPE', payload: destinationIndex })
    } else {
      dispatch({ type: 'SET_SORT_COLUMN', payload: null })
      dispatch({ type: 'SET_SORT_TYPE', payload: null })
    }
    dispatch({ type: 'SET_PAGE_NUMBER', payload: 1 })
    dispatch({ type: 'SET_TRIGGER_FETCH', payload: true })
  }

  const onRowClick = (event, rowData) => {
    if (document.getSelection() && document.getSelection().type !== 'Range'
      && !(['button', 'li'].includes(event.target.nodeName.toLowerCase())
        || (event.target.nodeName.toLowerCase() === 'div' && !event.target.classList.contains('MuiBox-root'))
        || (event.target.nodeName.toLowerCase() === 'svg' && !event.target.classList.contains('MuiSvgIcon-colorSecondary')))) {
      history.push({
        pathname: ECommercePathMap.CEC_DELIVERY_ORDER_LIST_VIEW + '/view/' + rowData['deliveryOrderRefNo']
      })
    }
  }

  const onSelectionChange = (row) => {
    const displayedIds = deliveryOrders.map(result => result.id)
    const selectedRowsNotDisplayed = selectedRows.filter(selectedRow => {
      return !displayedIds.includes(selectedRow.id)
    })
    dispatch({ type: 'SET_SELECTED_ROWS', payload: [...selectedRowsNotDisplayed, ...row] })
  }

  const indexOfTheFirstRecord = (pageNumber - 1) * pageSize + 1
  const indexOfTheLastRecord = (pageNumber - 1) * pageSize + deliveryOrders.length


  let topToolbar = props => (
    <div>
      {selectedRows.length == 0 ?
        <div className='MuiToolbar-root MuiToolbar-regular MuiToolbar-gutters'>
          <div><Typography
            className={classes.secondaryText}> {indexOfTheFirstRecord + '-' + indexOfTheLastRecord + ' of ' + totalRecords}</Typography>
          </div>
          <div style={{ flex: '1 1 10%' }} />
          <MTableToolbar {...props} />
          <Button variant='contained' startIcon={<AddCircleOutlineIcon />}
                  color='primary'
                  onClick={() => history.push({ pathname: ECommercePathMap.CEC_DELIVERY_ORDER_ADD_VIEW })}
                  style={{ textTransform: 'none' }}>
            Single Order
          </Button>
        </div>

        :

        <div className='MuiToolbar-root MuiToolbar-regular MuiToolbar-gutters'>
          <div>
            <Typography
              className={clsx(classes.colorBlue, classes.secondaryText)}>{Number(selectedRows.length) + (selectedRows.length === 1 ? ' item ' : ' items ') + 'selected'} </Typography>
            <Typography
              className={classes.secondaryText}> {indexOfTheFirstRecord + '-' + indexOfTheLastRecord + ' of ' + totalRecords + ' items'} </Typography>
          </div>
          <div style={{ flex: '1 1 10%' }} />
          <Button variant='outlined' startIcon={<PrinterIcon />}
                  color='primary'
                  style={{ textTransform: 'none' }}
                  onClick={() => {
                    handlePrintAction()
                  }}>
            Print Label
          </Button>
          <IconButton aria-label='delete' onClick={clearSelection}>
            <ClearIcon />
          </IconButton>
        </div>
      }

    </div>
  )
  let bottomPaginationBar = (props) => (
    <>
      <Box mt={2}>
        <TablePagination {...props} />
      </Box>
    </>
  )

  useEffect(() => {
    dispatch({ type: 'SET_TRIGGER_FETCH', payload: true })
  }, [])

  return (
    <Box className={classes.table}>
      <MaterialTable
        tableRef={tableRef}
        icons={tableIcons}
        columns={getColumns()}
        isLoading={isLoading}
        onOrderChange={onOrderChange}
        onRowClick={onRowClick}
        onSelectionChange={onSelectionChange}
        localization={{
          toolbar: { addRemoveColumns: 'View Columns' }
        }}
        options={{
          selection: true,
          search: false,
          paginationType: 'stepped',
          columnsButton: true,
          pageSize: 100,
          loadingType: 'overlay',
          emptyRowsWhenPaging: false,
          padding: 'default',
          showTitle: false
        }}
        data={deliveryOrders.map(row => selectedRows.find(selected => selected.id === row.id) ? {
          ...row,
          tableData: { checked: true }
        } : { ...row, tableData: { checked: false } })}
        components={{
          Toolbar: topToolbar,
          Pagination: bottomPaginationBar
        }}
      />
    </Box>
  )
}

export { DeliveryOrderTable }
