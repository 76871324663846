import React from 'react'
import { BasePage as CngBasePage } from 'cng-web-lib'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_CECADMIN'
import TranslationText from './TranslateText'
import CeCReportViewPage from './CeCReport_ViewPage'

function ModuleTitle() {
    const translatedTextsObject = TranslationText()
    return translatedTextsObject.moduleTitle
}

function WrappedViewPage(props) {
    const breadcrumbNameMap = useBreadcrumbNameMap()
    return (
        <CngBasePage
            moduleTitle={ModuleTitle()}
            renderPage={(showSnackbar) => (
                <CeCReportViewPage showNotification={showSnackbar} {...props} />
            )}
            extraCngBreadcrumbNameMap={breadcrumbNameMap}
        />
    )
}


export {
    WrappedViewPage as ViewPage,
}