import { Yup } from 'cng-web-lib'
import ValidationMessageTranslationText from './ValidationMessageTranslationText'

function useComplianceCargoValidationSchema(){
    const validationMessageTranslation = ValidationMessageTranslationText()

    return Yup.object().shape({
        shippingInfoComplianceCargo: Yup.object().shape({
             packType: Yup.string()
                .required(validationMessageTranslation.requiredCargoType)
                .nullable(),
            releaseLocCode: Yup.string()
                .required(validationMessageTranslation.requiredReleaseLocCode)
                .nullable(),
            receiptLocCode: Yup.string()
                .required(validationMessageTranslation.requiredReceiptLocCode)
                .nullable(),
        })
    })
}

export default useComplianceCargoValidationSchema