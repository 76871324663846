import React from 'react'
import PropTypes from 'prop-types'
import { components, constants } from 'cng-web-lib'
import { Paper } from '@material-ui/core'
import FormProperties from '../form/ItemFormProperties'


AddEditItemDialog.propTypes = {
  isDialogOpen: PropTypes.bool,
  closeDialog: PropTypes.func,
  itemData: PropTypes.object
}

const { FormState } = constants

const {
  form: {
    CngForm,
    field: {
      CngTextField, CngLookupAutocompleteField
    }
  },
  CngDialog, CngGridItem, button: { CngPrimaryButton, CngSecondaryButton },
  table: { useDefaultNotification }
} = components


function AddEditItemDialog(props) {
  const {
    isDialogOpen,
    closeDialog,
    itemData,
    deliveryOrderItems,
    setDeliveryOrderItems,
    showNotification,
    deliveryOrderActionType
  } = props
  const {
    success: showSuccessNotification,
    error: showErrorNotification
  } = useDefaultNotification(showNotification)
  const onSubmit = (data) => {
    const newRefNoValue = data.refNo

    if (deliveryOrderActionType === 'add') {
      //add delivery order
      if (itemData) {
        //edit item
        if ([...deliveryOrderItems.filter(item => item.refNo !== itemData.refNo), { ...data }].filter(item => item.refNo === newRefNoValue).length > 1) {
          showErrorNotification('Duplicated Item Ref No')
        } else {
          setDeliveryOrderItems([...deliveryOrderItems.filter(item => item.refNo !== itemData.refNo), { ...data }])
          showSuccessNotification('Changes to item information saved.')
          closeDialog()
        }
      } else {
        //add item
        if (deliveryOrderItems.filter(item => item.refNo === newRefNoValue).length > 0) {
          showErrorNotification('Duplicated Item Ref No')
        } else {
          setDeliveryOrderItems([...deliveryOrderItems, { ...data }])
          showSuccessNotification('Item information saved.')
          closeDialog()
        }
      }
    } else {
      //edit delivery order
      if (itemData) {
        //edit item
        setDeliveryOrderItems([...deliveryOrderItems.filter(item => item.refNo !== itemData.refNo), {
          ...data,
          delete: false
        }])
        showSuccessNotification('Changes to item information saved.')
        closeDialog()
      } else {
        //add item
        if (deliveryOrderItems.filter(item => (item.refNo === newRefNoValue && (item.delete === undefined || item.delete === false))).length > 0) {
          showErrorNotification('Duplicated Item Ref No')
        } else {
          const updatedDeliveryOrderItems = [...deliveryOrderItems.filter(item => item.refNo !== newRefNoValue), {
            ...data,
            delete: false
          }]
          setDeliveryOrderItems(updatedDeliveryOrderItems)
          showSuccessNotification('Item information saved.')
          closeDialog()
        }
      }
    }
  }

  return (
    <CngDialog
      customDialogContent={
        <Paper style={{ borderRadius: '0px' }}>
          <div style={{ overflow: 'hidden' }}>
            <CngForm
              formState={FormState.COMPLETED}
              fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
              innerForm={true}
              bodySection={
                <FormProperties.Fields
                  closeDialog={closeDialog}
                  deliveryOrderItems={deliveryOrderItems}
                  deliveryOrderActionType={deliveryOrderActionType}
                  itemData={itemData}
                />
              }
              hookFormProps={{
                initialValues: itemData ? itemData : FormProperties.formikProps.initialValues,
                validationSchema: FormProperties.formikProps.makeValidationSchema,
                onSubmit: onSubmit,
                validateOnChange: true
              }}
              renderButtonSection={() => false}
            />
          </div>
        </Paper>
      }
      shouldShowCloseButton
      onClose={closeDialog}
      dialogTitle={itemData ? 'Edit item' : 'Add Item'}
      open={isDialogOpen}
      fullWidth
      maxWidth='md'
    />
  )
}

export default AddEditItemDialog
