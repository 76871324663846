import React from 'react'
import { components, Yup, constants } from 'cng-web-lib'
import TranslationText from './TranslateText'
import { CardContent, FormControlLabel, Grid, Typography} from '@material-ui/core'
import SCOAdminApiUrls from "../../../apiUrls/SCOAdminApiUrls";
import ShipmentProviderUrls from "../../../apiUrls/cec-admin/shipment-provider/ShipmentProviderUrls";
import {OutlinedButton} from "../../../components/button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useFormContext} from "react-hook-form";

const {
    form: {
        field: {
            CngTextField, CngDateField, CngSwitchField, CngLookupAutocompleteField
        }
    },
    button: { CngPrimaryButton },
    CngGridItem
} = components

const {
    filter: { EQUAL }
} = constants


function Fields() {
    const TranslateObj = TranslationText()
    const [checked, setChecked] = React.useState(false);

    const handleChange = (event) => {
        setChecked(event.target.checked)
    };


    return (
        <>
            <CardContent>
                <Grid container spacing={3}>
                    <CngGridItem xs={12} sm={6} style={{ alignSelf: 'center' }}>
                        <Typography variant='h3'><b>{TranslateObj.headerSummary}</b></Typography>
                    </CngGridItem>

                    <CngGridItem xs={12} sm={6} style={{ textAlign: 'end' }}>
                        <FormControlLabel
                            control={<CngSwitchField
                                name='advMode'
                            />}
                            label={TranslateObj.advMode}
                            labelPlacement="start"
                            checked={checked}
                            onChange={handleChange}
                        />
                    </CngGridItem>

                    <CngGridItem xs={12} sm={3}>
                        <CngDateField
                            label={TranslateObj.deliveryEventStartDate}
                            name='deliveryEventStartDate'
                            size='small'
                            format='YYYY-MM-DD'
                        />
                    </CngGridItem>

                    <CngGridItem xs={12} sm={3}>
                        <CngDateField
                            label={TranslateObj.deliveryEventEndDate}
                            name='deliveryEventEndDate'
                            size='small'
                            format='YYYY-MM-DD'
                        />
                    </CngGridItem>

                    <CngGridItem xs={12} sm={3}>
                        <CngDateField
                            label={TranslateObj.creationStartDate}
                            name='creationStartDate'
                            size='small'
                            format='YYYY-MM-DD'
                        />
                    </CngGridItem>

                    <CngGridItem xs={12} sm={3}>
                        <CngDateField
                            label={TranslateObj.creationEndDate}
                            name='creationEndDate'
                            size='small'
                            format='YYYY-MM-DD'
                        />
                    </CngGridItem>

                    <CngGridItem xs={12} sm={4}>
                        <CngTextField
                            label={TranslateObj.doRefNo}
                            name='doRefNo'
                            hidden={!checked}
                        />
                    </CngGridItem>

                    <CngGridItem xs={12} sm={4}>
                        <CngTextField
                            label={TranslateObj.doSysNo}
                            name='doSysNo'
                            hidden={!checked}
                        />
                    </CngGridItem>

                    <CngGridItem xs={12} sm={4}>
                        <CngTextField
                            label={TranslateObj.trackingNo}
                            name='trackingNo'
                            hidden={!checked}
                        />
                    </CngGridItem>

                    <CngGridItem xs={12} sm={4}>
                        <CngLookupAutocompleteField
                            name='lastMileProvider'
                            label={TranslateObj.lastMileProvider}
                            multiple={true}
                            hidden={!checked}
                            lookupProps={{
                                url: ShipmentProviderUrls.GET_FOR_USER_PARTY,
                                label: 'name',
                                value: 'id',
                                filters: [
                                    {
                                        field: 'isActive',
                                        operator: EQUAL,
                                        value: true
                                    }
                                ]
                            }}
                        >
                        </CngLookupAutocompleteField>
                    </CngGridItem>

                    <CngGridItem xs={12} sm={4}>
                        <CngLookupAutocompleteField
                            name='deliveryEvent'
                            label={TranslateObj.deliveryEvent}
                            hidden={!checked}
                            lookupProps={{
                                url: SCOAdminApiUrls.GET_CODE_MASTER_LIST,
                                label: 'name',
                                value: 'code',
                                filters: [
                                    {
                                        field: 'codeType',
                                        operator: EQUAL,
                                        value: 'CEC_DELIVERY_ORDER_EVENT'
                                    }
                                ]
                            }}
                        >
                        </CngLookupAutocompleteField>
                    </CngGridItem>


                    <CngGridItem xs={12} sm={4}>
                        <CngLookupAutocompleteField
                            name='doStatus'
                            label={TranslateObj.doStatus}
                            hidden={!checked}
                            lookupProps={{
                                url: SCOAdminApiUrls.GET_CODE_MASTER_LIST,
                                label: 'name',
                                value: 'id',
                                filters: [
                                    {
                                        field: 'codeType',
                                        operator: EQUAL,
                                        value: 'CEC_DELIVERY_ORDER_STATUS'
                                    }
                                ]
                            }}
                        >
                        </CngLookupAutocompleteField>
                    </CngGridItem>

                </Grid>
            </CardContent>
        </>

    )
}

function Buttons(props) {

    const form = useFormContext()

    return (
        <>
            <CardContent>
                <Grid container spacing={2}>
                    <CngGridItem xs={12} sm={6} >
                        <OutlinedButton
                            customColor='text.error'
                            type='reset'
                            startIcon={<FontAwesomeIcon icon={['fal', 'rotate-left']} />}
                            children='Clear Fields'
                            onClick={ () => form.reset() }
                        >
                        </OutlinedButton>
                    </CngGridItem>

                    <CngGridItem xs={12} sm={6} style={{ textAlign: 'end' }}>
                        <CngPrimaryButton
                            type='submit'
                            startIcon={<FontAwesomeIcon icon={['fal', 'file-plus']} />}
                            children='Generate Report'
                            disabled={props.loading}
                            endIcon={props.loading && <FontAwesomeIcon icon={['fal', 'spinner-third']} fixedWidth spin />}
                        />
                    </CngGridItem>

                </Grid>
            </CardContent>
        </>
    )
}


function toClientDataFormat(serverData) {
    return serverData
}

function toServerDataFormat(localData) {
    return localData
}

const DEFAULT_INITIAL_VALUES = {
    lastMileProvider: [],
}

const ValidationSchema = () => {
    return Yup.object({

    })
}

const FORMIK_PROPS = {
    initialValues: { ...DEFAULT_INITIAL_VALUES }, makeValidationSchema: ValidationSchema
}


const FormProperties = Object.freeze({
    formikProps: FORMIK_PROPS,
    Fields: Fields,
    Buttons: Buttons,
    toClientDataFormat: toClientDataFormat,
    toServerDataFormat: toServerDataFormat
})

export default FormProperties