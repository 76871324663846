import React from 'react'
import Namespace from '../../../constants/locale/Namespace'
import { constants, useTranslation } from 'cng-web-lib'
import ReportKeys from "../../../constants/locale/key/cec/ReportKeys";

const NAMESPACE = Namespace.CEC_REPORT

const {
    locale: {
        key: {
            UiComponentKeys
        }
    }
} = constants

const TranslationText = () => {

    const { translate } = useTranslation([NAMESPACE])

    const moduleTitle = translate(NAMESPACE, ReportKeys.MODULE_TITLE)

    // Header
    const headerSummary = translate(NAMESPACE, ReportKeys.HEADER.SUMMARY)

    // Fields
    const deliveryEventStartDate = translate(NAMESPACE, ReportKeys.FIELDS.DELIVERY_EVENT_START_DATE)
    const deliveryEventEndDate = translate(NAMESPACE, ReportKeys.FIELDS.DELIVERY_EVENT_END_DATE)
    const creationStartDate = translate(NAMESPACE, ReportKeys.FIELDS.CREATION_START_DATE)
    const creationEndDate = translate(NAMESPACE, ReportKeys.FIELDS.CREATION_END_DATE)
    const doRefNo = translate(NAMESPACE, ReportKeys.FIELDS.DO_REF_NO)
    const doSysNo = translate(NAMESPACE, ReportKeys.FIELDS.DO_SYS_NO)
    const trackingNo = translate(NAMESPACE, ReportKeys.FIELDS.TRACKING_NO)
    const lastMileProvider = translate(NAMESPACE, ReportKeys.FIELDS.LAST_MILE_PROVIDER)
    const deliveryEvent = translate(NAMESPACE, ReportKeys.FIELDS.DELIVERY_EVENT)
    const doStatus = translate(NAMESPACE, ReportKeys.FIELDS.DO_STATUS)
    const advMode = translate(NAMESPACE, ReportKeys.FIELDS.ADV_MODE)

    return {
        moduleTitle,
        headerSummary,
        deliveryEventStartDate,
        deliveryEventEndDate,
        creationStartDate,
        creationEndDate,
        doRefNo,
        doSysNo,
        trackingNo,
        lastMileProvider,
        deliveryEvent,
        doStatus,
        advMode
    }
}

export default TranslationText


