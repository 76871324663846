import { Box, Button, Grid } from '@material-ui/core'

import ComplianceTranslationText from '../ComplianceTranslationText'
import React from 'react'

function ViewPermitButtonComponent(props){

    const ComplianceTranslatedObject = ComplianceTranslationText()

    return (
        <Box pl={5} pr={5} pt={4} pb={4}>
          <Grid container xs={12} sm={12}>
            <Grid container xs={6} sm={6} justify='flex-start' >
              <Box>
                <Button
                  variant='contained'
                  className='button-blue originalText'
                  onClick={() => {
                    props.backToManagePermit()
                  }}
                >
                  {ComplianceTranslatedObject.backToManagePermitPage}
                </Button>
              </Box>
            </Grid>

            <Grid container xs={6} sm={6} justify='flex-end'>
              {
                props.editable && ( 
                  <Box pr={2}>
                    <Button
                      variant='contained'
                      className='button-blue originalText'
                      onClick={() => {
                        props.onEdit()
                      }}
                    >
                      {ComplianceTranslatedObject.updatePermit}
                    </Button>
                  </Box>
                )
              }

              {
                props.approved && (
                  <Box pr={2}>
                    <Button
                      variant='contained'
                      className='button-blue originalText'
                      onClick={() => {
                        props.onDownloadPermit()
                      }}
                    >
                      {ComplianceTranslatedObject.downloadPermit}
                    </Button>
                  </Box>
                )
              }

              {
                props.enquirable && ( 
                  <Box>
                    <Button
                      variant='contained'
                      className='button-blue originalText'
                      onClick={() => {
                        props.onEnquireStatus()
                      }}
                    >
                      {ComplianceTranslatedObject.enquireStatus}
                    </Button>
                  </Box>
                )
              }
            </Grid>
          </Grid>
        </Box>
    )
}

export default ViewPermitButtonComponent