import React, { useState } from 'react'
import { Box, Grid } from '@mui/material'
import Button from '@mui/material/Button'
import { components } from 'cng-web-lib'

import { useFormContext } from 'react-hook-form'
import DiscardDialog from './DiscardDialog'

StepperButtonSection.propTypes = {}
const {
  CngGridItem
} = components

function StepperButtonSection(props) {
  const { activeStep, steps, handleNext, handleBack, formState, actionType } = props
  const [isDiscardDialogOpened, setDiscardDialogOpened] = useState(false)
  const { trigger } = useFormContext()
  const openDiscardDialog = () => {
    setDiscardDialogOpened(true)
  }
  const closeDiscardDialog = () => {
    setDiscardDialogOpened(false)
  }
  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={6} sm={6}>
        <Button
          variant='contained'
          className='ng-button-filled-secondary2 originalText'
          type='button'
          size='large'
          onClick={() => openDiscardDialog()}
          sx={{ borderRadius: '8px !important' }}>
          Discard</Button>
      </Grid> {/* item */}

      <CngGridItem item xs={6} sm={6}>
        <Box display='flex' justifyContent='flex-end'>
          {(activeStep !== 0 && activeStep !== steps.length - 0) &&
            <Button
              variant='contained'
              className='button-blue originalText'
              onClick={handleBack}
              type='button'
              size='large'
              style={{ borderRadius: '8px' }}
            >Back</Button>
          }
          {activeStep !== steps.length - 1 &&
            <Button
              variant='contained'
              onClick={async (event) => {
                const isValid = await trigger()
                if (isValid) {
                  handleNext()
                } else {
                  console.log(formState.errors)
                }
              }}
              type='button'
              size='large'
              className='button-light-green originalText'
              style={{ borderRadius: '8px', marginLeft: '5px' }}
            >Continue</Button>
          }
          {activeStep === steps.length - 1 &&
            <Button
              variant='contained'
              className='button-light-green originalText'
              type='submit'
              size='large'
              style={{ borderRadius: '8px', marginLeft: '5px' }}
            >{actionType === 'add' ? 'Create order' : 'Update order'}
            </Button>
          }
        </Box>
      </CngGridItem>

      <DiscardDialog isDialogOpen={isDiscardDialogOpened} closeDialog={closeDiscardDialog} />

    </Grid>
  )
}

export default StepperButtonSection
