import Namespace from '../../../constants/locale/Namespace'
import { useTranslation } from 'cng-web-lib'
import EcommerceLabelKeys from "../../../constants/locale/key/EcommerceLabel";

const NAMESPACE = Namespace.ECOMMERCE_LABEL

const TranslationText = () => {

    const { translate } = useTranslation([NAMESPACE])

    const moduleTitle = translate(NAMESPACE, EcommerceLabelKeys.LABEL_PAGE_TITLE)

    //table header
    const filename = translate(NAMESPACE, EcommerceLabelKeys.FILENAME)
    const lastMileProvider = translate(NAMESPACE, EcommerceLabelKeys.LAST_MILE_PROVIDER)
    const createdOn = translate(NAMESPACE, EcommerceLabelKeys.CREATED_ON)
    const noOfDO = translate(NAMESPACE, EcommerceLabelKeys.NO_OF_DO)
    const systemResponse = translate(NAMESPACE, EcommerceLabelKeys.SYSTEM_RESPONSE)
    const status = translate(NAMESPACE, EcommerceLabelKeys.STATUS)
    const action = translate(NAMESPACE, EcommerceLabelKeys.ACTION)

    const downloadSuccessMessage = translate(NAMESPACE, EcommerceLabelKeys.DOWNLOAD_SUCCESS_MESSAGE)
    const searchFileMessage = translate(NAMESPACE, EcommerceLabelKeys.SEARCH_FILE_MSG)

    return {
        moduleTitle,
      
        filename,
        lastMileProvider,
        createdOn,
        noOfDO,
        systemResponse,
        status,
        action,
        
        downloadSuccessMessage,
        searchFileMessage
       
    }
}

export default TranslationText


