import * as CalistaConstant from 'src/constants/CalistaConstant'
import {  Box, Card, CardContent, Fab, Grid } from '@material-ui/core'
import { Yup, components, useServices } from 'cng-web-lib'
import React, { Fragment, useEffect, useState } from 'react'
import ButtonTabComponent from 'src/views/compliance/components/ButtonTabComponent'
import CngBackdrop from 'src/views/vesselschedule/searchschedule/cngcomponent/CngBackDrop'
import FreeTextSearchComponent from 'src/views/compliance/components/FreeTextSearchComponent'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import ComplianceAPI from 'src/views/compliance/shared/api'
import SIApi from 'src/views/shippinginstruction/shared/api'
import FRBApi from 'src/views/freightbooking/shared/api'
import SIListComponent from 'src/views/shippinginstruction/components/SIListComponent'
import ComplianceFilterPanelComponent from 'src/views/compliance/components/ComplianceFilterPanelComponent'
import SIFilterPanelComponent from 'src/views/shippinginstruction/components/SIFilterPanelComponent'
import ListComponent from 'src/views/compliance/components/ListComponent'
import SIUtils from 'src/views/shippinginstruction/shared/Utils'
import ScrollTop from 'src/views/freightbooking/components/ScrollTop'
import apiParser from 'src/views/compliance/shared/apiParser'
import SiApiParser from 'src/views/shippinginstruction/shared/apiParser'
import FRBApiParser from 'src/views/freightbooking/shared/apiParser'
import Utils from 'src/views/common/utils/Utils'

const {
  form: {
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  dateRange: 'all',
  dateRangeSI: 'all',
  approvedSIClicked: true,
  filterText: '',
  filterTextSI: '',
  sortCode: '',
  sortCodeSI: '',
  approvedSICount: 0,
  compliancePermitCount: 0,
  pendingStatusCount: 0,
  processedStatusCount: 0,
  statusCodes: [
    CalistaConstant.PERMIT_STATUS_PENDING_SUBMISSION,
    CalistaConstant.PERMIT_STATUS_SUBMITTED,
    CalistaConstant.PERMIT_STATUS_CREATED,
    CalistaConstant.PERMIT_STATUS_APPROVED,
    CalistaConstant.PERMIT_STATUS_REJECTED,
    CalistaConstant.PERMIT_STATUS_AMENDMENT_CREATED,
    CalistaConstant.PERMIT_STATUS_AMENDMENT_SUBMITTED,
  ],
  approvedSIStatusCodes: [CalistaConstant.SI_STATUS_SI_APPROVED],
  status: {},
  bls: {},
  approvedSIstatus: {},
  messageTypes: {},
  decTypes: {},
  myTask: true,
  partyIds: [],
  carriers: {},
  showArchived: true,
  statusTabChanged: true,
  approvedSIStatusTabChanged: true,
  blAppTypeCodes: ['SI_SB', 'SI_BL']
})

function DEFAULT_VALIDATION_SCHEMA() {
  return Yup.object({})
}

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: DEFAULT_VALIDATION_SCHEMA
}

function FormFields({ showNotification }) {
  const { fetchRecords } = useServices()
  const [statusCodesField, , { setValue: setStatusCodesField }] =
    useField('statusCodes')
  const [messageTypeCodesField, , { setValue: setMessageTypeCodesField }] =
    useField('messageTypeCodes')
  const [statusField, , { setValue: setStatusField }] = useField('status')
  const [sortCodeField, , { setValue: setSortCodeField }] = useField('sortCode')
  const [sortCodeSIField, , { setValue: setSortCodeSIField }] = useField('sortCodeSI')
  const [, , { setValue: setSortByListField }] = useField('sortByList')
  const [, , { setValue: setSortByListSIField }] = useField('sortByListSI')

  const [filterTextField, , { setValue: setFilterTextField }] =
    useField('filterText')

  const [filterTextSI, setFilterTextSI] = useState()
  const [filterTextSIField, , { setValue: setFilterTextSIField }] =
    useField('filterTextSI')
  
  const [, setDateRangeSI] = useState()
  const [dateRangeSIField, , { setValue: setDateRangeSIField }] =
  useField('dateRangeSI')

  const [decTypesField, , { setValue: setDecTypesField }] = useField('decTypes')
  const [decTypeCodesField, , { setValue: setDecTypeCodesField }] = useField('decTypeCodes')

  const [, , { setValue: setMyTaskField }] = useField('myTask')
  const [, , { setValue: setStatusTabChanged }] = useField('statusTabChanged');
  const [, setReloadStatus] = useState(false)
  const [messageTypesField, , { setValue: setMessageTypesField }] = useField('messageTypes')
  const [dateRangeField, , { setValue: setDateRangeField }] =
    useField('dateRange')

  const defaultDateRange = 'all' //default value to query via API
  const VALUE_ALL = 'ALL'

  const [, setDateRange] = useState()
  const [sortByList, setSortByList] = useState()
  const [sortByListSI, setSortByListSI] = useState()
  const [filterText, setFilterText] = useState()
  const [pmList, setPmList] = useState([])
  const [, setShowMyTask] = useState()
  const [loading, setLoading] = useState(true)
  const [shownCount, setShownCount] = useState(0)
  const [totalCount, setTotalCount] = useState(0)

  const [approvedSIList, setApprovedSIList] = useState([])
  const [SIShownCount, setSIShownCount] = useState(0)
  const [totalApprovedSICount, setTotalApprovedSICount] = useState(0)
  const [approvedSIClicked, setApprovedSIClicked] = useState()
  const [approvedSIClickedField, , {setValue: setApprovedSIClickedField}] =
    useField('approvedSIClicked') 
  
  const [totalCompliancePermit, setTotalCompliancePermit] = useState(0)

  const [showArchived, setShowArchived] = useState()
  const [showArchivedField, , { setValue: setShowArchivedField }] =
    useField('showArchived')
  
  const defaultShowArchived = true

  const [carriers, setCarriers] = useState([])
  const [, , { setValue: setCarriersField }] = useField('carriers')

  const [bls, setBls] = useState([])
  const [blsField, , { setValue: setBlsField }] = useField('bls')
  const [blAppTypeCodesField, , { setValue: setBlAppTypeCodesField }] =
    useField('blAppTypeCodes')

  const [, setApprovedSIStatus] = useState([])
  const [approvedSIStatusField, , { setValue: setApprovedSIStatusField }] = useField('approvedSIStatus')

  const [, , { setValue: setApprovedSIStatusTabChanged }] = useField('aprovedSIStatusTabChanged')
  const [approvedSIStatusTabChangedField, , { setValue: setApprovedSIStatusTabChangedField }] =
    useField('aprovedSIStatusTabChanged')

  const [partyIdsField, , { setValue: setPartyIdsField }] = 
    useField('partyIds')

  const permitStatusCode = [
    CalistaConstant.PERMIT_STATUS_PENDING_SUBMISSION,
    CalistaConstant.PERMIT_STATUS_CREATED,
    CalistaConstant.PERMIT_STATUS_SUBMITTED,
    CalistaConstant.PERMIT_STATUS_APPROVED,
    CalistaConstant.PERMIT_STATUS_REJECTED,
    CalistaConstant.PERMIT_STATUS_AMENDMENT_CREATED,
    CalistaConstant.PERMIT_STATUS_AMENDMENT_SUBMITTED
  ]

  const manualRefresh = () => {
    setRefresh(!refresh)
    setLoading(true)
    setPmList([])
    setShownCount(0)
  }

  const manualRefreshSI = () => {
    setRefresh(!refresh)
    setLoading(true)
    setApprovedSIList([])
    setSIShownCount(0)
  }

  const clickApprovedSI = () => {
    console.log('clickApprovedSI')
    setApprovedSIClicked(true)
    setApprovedSIClickedField(true)
    setApprovedSIStatus(approvedSIList)
    setApprovedSIStatusField(approvedSIList)
    setApprovedSIStatusTabChangedField(true)

    setPmList([])
    manualRefreshSI()
  }

  const clickPermit = () => {
    console.log('clickCompliancePermit')
    setApprovedSIClicked(false)
    setApprovedSIClickedField(false)
    setStatusCodesField(permitStatusCode)
    setStatus(pmList)
    setStatusField(pmList)
    setStatusTabChanged(true)

    manualRefresh()
  }

  const showArchivedChange = (e) => {
    console.log('showArchivedChange')
    console.log(e.target.checked)
    setShowArchivedField(e.target.checked)
    setApprovedSIStatusTabChangedField(true) //force carrier list load from api
    manualRefreshSI()
    //submitForm()
  }

  function reloadCarrierCheckBoxNeeded() {
    return approvedSIStatusTabChangedField.value
  }

  const onCarrierCheckBoxChange =
  (index) =>
  ({ target: { checked } }) => {
    console.log('onCarrierCheckBoxChange ' + index)
    let newCarriers = [...carriers]
    const carrier = carriers[index]
    console.log(carrier)
    if (carrier.nameOth !== VALUE_ALL) {
      const allCarrier = carriers[carriers.length - 1]

      newCarriers[index] = { ...carrier, checked: !carrier.checked }
      newCarriers[carriers.length - 1] = { ...allCarrier, checked: false }
    } else {
      newCarriers = newCarriers.map((item) => ({
        ...item,
        checked: !carrier.checked
      }))
    }

    setCarriers(newCarriers)
    setCarriersField(newCarriers)

    let temp = []
    newCarriers
      .filter((v) => v.checked)
      .forEach((v) => temp.push(v.carrierPartyId))

    setPartyIdsField(temp)
    setApprovedSIStatusTabChanged(false)

    manualRefreshSI()
    // submitForm()
  }

  const onBLCheckBoxChange =
  (index) =>
  ({ target: { checked } }) => {
    console.log('onBLCheckBoxChange ' + index)
    let newBls = [...bls]
    const bl = bls[index]

    if (bl.statusDesc !== VALUE_ALL) {
      const allBl = bls[bls.length - 1]

      newBls[index] = { ...bl, checked: !bl.checked }
      newBls[bls.length - 1] = { ...allBl, checked: false }
    } else {
      newBls = newBls.map((item) => ({
        ...item,
        checked: !bl.checked
      }))
    }

    setBls(newBls)
    setBlsField(newBls)

    let newBlList = getNewBls(newBls)
    console.log(newBlList)

    setBlAppTypeCodesField(newBlList)
    setApprovedSIStatusTabChanged(true) //force carrier list load from api

    manualRefreshSI()
  }

  function getNewBls(newBls) {
    let temp = []
    newBls.filter((v) => v.checked).map((v) => temp.push(v.statusCode))

    return temp
  }

  const handleSortByChange = (e) => {
    console.log('handleSortByChange')
    if (e.target.value != null && e.target.value != 0) {
      setSortCodeField(e.target.value)
      setStatusTabChanged(true)
      manualRefresh()
    }
  }

  const handleSortByChangeSI = (e) => {
    console.log('handleSortByChangeSI')
    if (e.target.value != null && e.target.value != 0) {
      setSortCodeSIField(e.target.value)
      setApprovedSIStatusTabChanged(true)
      manualRefreshSI()
    }
  }

  const handelFreeTextSearch = () => {
    setStatusTabChanged(true)
    manualRefresh()
  }

  const handelFreeTextSISearch = () => {
    setApprovedSIStatusTabChanged(true)
    manualRefreshSI()
  }

  const handleClearFiltreAndTextButtonClick = () => {
    console.log('handleClearFiltreAndTextButtonClick')

    setFilterText('')
    setFilterTextField('')
    handleClearAllButtonClick()
  }

  const handleClearFiltreAndTextButtonClickSI = () => {
    console.log('handleClearFiltreAndTextButtonClickSI')

    setFilterTextSI('')
    setFilterTextSIField('')
    handleClearAllButtonClickSI()
  }

  const handleClearAllButtonClick = () => {
    console.log('handleClearAllButtonClick')

    setDateRange(defaultDateRange)
    setDateRangeField(defaultDateRange)
    let newStatus = [...status]


    let newStatusCode = getNewStatuCodeForClearAll(newStatus)

    newStatus = newStatus.map((item) => ({
      ...item,
      checked: item.eligible
    }))

    setReloadStatus(true)
    setStatusField(newStatus)
    setStatusCodesField(newStatusCode)

    let newDecTypes = [...decTypes]
    newDecTypes = newDecTypes.map((item) => ({
      ...item,
      checked: true
    }))

    setDecTypes(newDecTypes)
    setDecTypesField(newDecTypes)
    setDecTypeCodesField(getCheckedCodes(newDecTypes))

    let newMessageTypes = [...messageTypes]
    newMessageTypes = newMessageTypes.map((item) => ({
      ...item,
      checked: true
    }))
    setMessageTypes(newMessageTypes)
    setMessageTypesField(newMessageTypes)
    setMessageTypeCodesField(getCheckedCodes(newMessageTypes))

    setShowMyTask(false)
    setMyTaskField(false)

    setStatusTabChanged(true) //force carrier list load from api
    manualRefresh()
  }

  const handleClearAllButtonClickSI = () => {
    console.log('handleClearAllButtonClickSI')

    setDateRangeSI(defaultDateRange)
    setDateRangeSIField(defaultDateRange)

    setShowArchived(defaultShowArchived)
    setShowArchivedField(defaultShowArchived)

    let newCarriers = [...carriers]
    newCarriers = newCarriers.map((item) => ({
      ...item,
      checked: true
    }))

    setCarriers(newCarriers)
    setCarriersField(newCarriers)

    setBlsField([])
    setBlAppTypeCodesField(DEFAULT_INITIAL_VALUES.blAppTypeCodes)

    setApprovedSIStatusTabChanged(true) //force carrier list load from api
    manualRefreshSI()
  }

  const dateRangeChange = (e) => {
    setStatusTabChanged(true)
    setDateRange(e.target.defaultValue)
    setDateRangeField(e.target.defaultValue)
    searchCriteria.dateRange = e.target.defaultValue
    manualRefresh()
  }

  const dateRangeSIChange = (e) => {
    setApprovedSIStatusTabChanged(true)
    setDateRangeSI(e.target.defaultValue)
    setDateRangeSIField(e.target.defaultValue)
    SISearchCriteria.dateRange = e.target.defaultValue
    manualRefreshSI()
  }

  const handleFilterTextChange = (e) => {
    setFilterText(e.target.value)
    setFilterTextField(e.target.value)
  }

  const handleFilterTextSIChange = (e) => {
    setFilterTextSI(e.target.value)
    setFilterTextSIField(e.target.value)
  }

  //here broken
  const onStatusCheckBoxChange =
    (index) =>
      ({ target: { checked } }) => {
        console.log('onStatusCheckBoxChange ' + index)
        let newStatuss = [...status]
        const stat = status[index]
        if (stat.statusDesc !== VALUE_ALL) {
          const allStatuss = newStatuss[newStatuss.length - 1]

          newStatuss[index] = { ...stat, checked: !stat.checked }
          newStatuss[status.length - 1] = { ...allStatuss, checked: false }
        } else {
          newStatuss = newStatuss.map((item) => ({
            ...item,
            checked: !stat.checked
          }))
        }

        setStatus(newStatuss)
        setStatusField(newStatuss)

        let temp = getCheckedCodes(newStatuss)
        setStatusCodesField(temp)
        setStatusTabChanged(false)

        manualRefresh()
      }

  function populatePartyId(dat){
    let FromApi = SiApiParser.parsePartyId(dat)

    FromApi.map((elem) => (elem.bookingCount = elem.shippingCount))
    let newRecord = { ...FromApi[0] }
    newRecord.nameOth = 'ALL'
    newRecord.bookingCount = FromApi.reduce((a, b) => a + b.shippingCount, 0)
    FromApi = [...FromApi, newRecord]

    let partyIdsForSearch = getPartyIdForSIList(dat)
    console.log(partyIdsForSearch)
    console.log(partyIdsField)
    console.log(searchCriteria)

    getApprovedSIList()

    setCarriers(FromApi)
    setCarriersField(FromApi)
  }

  function getPartyIdForSIList(carrierList) {
    console.log('getPartyIdForBookingList')
    let partyIdss = []
    
    console.log('loading party id from api')

    carrierList.map((v) => {
      partyIdss.push(v.carrierPartyId)
    })

    //temp for booking list
    SISearchCriteria.carrierCodes = partyIdss
    SISearchCriteria.statusCodes = ['5203']
    setPartyIdsField(partyIdss)
  
    return partyIdss
  }

  function fetchPartyIdFromAPI() {
    SIApi.fetchPartyId(fetchRecords, SISearchCriteria, populatePartyId, onError)
    //Api.fetchPartyId(fetchRecords, searchCriteria, populatePartyId, onError)
  }

  function getNewStatuCodeForClearAll(newStatus) {

    //in the case of clear all, need to get all eligible status code as search criteria
    let temp = []
    newStatus.filter((v) => (v.eligible || v.checked) && v.statusCode !== undefined).map((v) => temp.push(v.statusCode))

    return temp
  }

  const onMyTaskChange = (e) => {
    console.log('onMyTaskChange' + e.target.checked)

    setMyTaskField(e.target.checked)
    setReloadStatus(true)
    setShowMyTask(e.target.checked)
    setStatusCodesField(permitStatusCode)
    searchCriteria.statusCodes = permitStatusCode
    setStatusTabChanged(true)
    manualRefresh()
  }

  const onMessTypeCheckBoxChange =
    (index) =>
      ({ target: { checked } }) => {
        console.log('onMessTypeCheckBoxChange ' + index)
        let newMessageTypes = [...messageTypes]
        const messageType = messageTypes[index]
        if (messageType.statusDesc !== VALUE_ALL) {
          const allMessageTypes = messageTypes[messageTypes.length - 1]

          newMessageTypes[index] = { ...messageType, checked: !messageType.checked }
          newMessageTypes[messageTypes.length - 1] = { ...allMessageTypes, checked: false }
        } else {
          newMessageTypes = newMessageTypes.map((item) => ({
            ...item,
            checked: !messageType.checked
          }))
        }

        setMessageTypes(newMessageTypes)
        setMessageTypesField(newMessageTypes)

        let newMessageTypeList = getCheckedCodes(newMessageTypes)

        setMessageTypeCodesField(newMessageTypeList)
        setStatusTabChanged(true)

        manualRefresh()
      }

  function getCheckedCodes(data) {
    let temp = data.filter((v) => v.checked == true && v.statusCode !== undefined).map((v) => v.statusCode)
    return temp
  }

  const onDecTypeCheckBoxChange =
    (index) =>
      ({ target: { checked } }) => {
        console.log('onDecTypeCheckBoxChange ' + index)
        let newDecTypes = [...decTypes]
        const decType = decTypes[index]

        if (decType.statusDesc !== VALUE_ALL) {
          const allDecTypes = decTypes[decTypes.length - 1]

          newDecTypes[index] = { ...decType, checked: !decType.checked }
          newDecTypes[decTypes.length - 1] = { ...allDecTypes, checked: false }
        } else {
          newDecTypes = newDecTypes.map((item) => ({
            ...item,
            checked: !decType.checked
          }))
        }

        setDecTypes(newDecTypes)
        setDecTypesField(newDecTypes)

        let temp = getCheckedCodes(newDecTypes)

        setDecTypeCodesField(temp)
        setStatusTabChanged(false)

        manualRefresh()
      }

  let searchCriteria = {
    filterText: filterTextField.value,
    dateRange: dateRangeField.value,
    decTypes: decTypeCodesField.value,
    statusCodes: statusCodesField.value,
    messageTypes: messageTypeCodesField.value,
    sortCode: Utils.isEmptyString(sortCodeField.value)
      ? 1
      : sortCodeField.value,
  }

  let SISearchCriteria = {
    filterText: filterTextSIField.value,
    dateRange: dateRangeSIField.value,
    blAppTypeCodes: blAppTypeCodesField.value,
    statusCodes: ['5203'],
    polCodes: ['SGSIN'],
    podCodes: ['SGSIN'],
    showArchived: showArchivedField.value,
    carrierCodes: partyIdsField.value,
    partyIds: partyIdsField.value,
    sortCode: Utils.isEmptyString(sortCodeSIField.value)
      ? 1
      : sortCodeSIField.value,
    showDirectSIOnly: false,
    compliance: true,
    hazardous: false,
  }

  const [decTypes, setDecTypes] = useState([])
  const [status, setStatus] = useState([])
  const [messageTypes, setMessageTypes] = useState([])
  const [loadingCriteria, setLoadingCriteria] = useState()
  const [loadingCriteriaSI, setLoadingCriteriaSI] = useState()
  const [, setPageError] = useState(false)
  const [refresh, setRefresh] = useState(false)

  useEffect(() => {
    setPageError(false)
    setApprovedSIClicked(approvedSIClickedField.value)

    if(approvedSIClickedField.value){
      console.log('APPROVED SI CLICKED')

      setSortCodeSIField(
        sortCodeSIField.value == null || sortCodeSIField.value == ''
          ? 1
          : sortCodeSIField.value
      )

      if (sortByListSI == null) {
        //fetch sort code when the page is first loaded, the result will remain static
        getSortingTypeSI()
      }

      fetchApprovedSIStatusCodeAndCountFromAPI()
      fetchStatusCodeAndCountFromAPI()
    }
    else{
      console.log('COMPLIANCE PERMIT CLICKED')

      setSortCodeField(
        sortCodeField.value == null || sortCodeField.value == ''
          ? 1
          : sortCodeField.value
      )
      if (sortByList == null) {
        //fetch sort code when the page is first loaded, the result will remain static
        getSortingType()
      }
      fetchStatusCodeAndCountFromAPI()
      fetchDecTypeFromAPI()
      fetchMessageTypeFromAPI()
  
      getComplianceList()
    }
  }, [refresh])


  function fetchDecTypeFromAPI() {
    ComplianceAPI.fetchDecType(fetchRecords, searchCriteria, populateDecTypes, onError)
  }

  function populateStatusCodeAndCount(dat) {
    let statusList = apiParser.parseMessageTypes(dat)
    setStatus(Utils.isEmptyObject(statusField.value) ? statusList : statusField.value)

    setTotalCount(statusList.find(s => s.statusDesc == VALUE_ALL).totalCount)
    setTotalCompliancePermit(statusList.find(s => s.statusDesc == VALUE_ALL).totalCount)
  }

  function populateApprovedSIStatusCodeAndCount(dat) {
    let isHelpdesk = false
    const data = SiApiParser.parseStatusCodeAndCount(dat, false, isHelpdesk)

    const SiStatusList = data.siStatusList

    setApprovedSIStatus(
      Utils.isEmptyObject(approvedSIStatusField.value) ? SiStatusList : approvedSIStatusField.value
    )
    fetchBLApplicationTypeFromAPI()
    fetchPartyIdFromAPI()
  }

  function fetchBLApplicationTypeFromAPI() {
    SIApi.fetchBLApplicationType(
      fetchRecords,
      SISearchCriteria,
      populateBLApplicationType,
      onBLApplicationTypeError
    )
  }

  function populateBLApplicationType(dat) {
    let BLList = SiApiParser.parseBlApplicationType(dat)

    console.log('Check BL field', blsField.value)
    setBls(Utils.isEmptyObject(blsField.value) ? BLList : blsField.value) //error here
  }

  function onBLApplicationTypeError(error) {
    console.log(error)
  }

  function fetchApprovedSIStatusCodeAndCountFromAPI() {
    SIApi.fetchStatusCodeAndCount(
      fetchRecords,
      populateApprovedSIStatusCodeAndCount,
      onStatusFilterError
    )
  }

  function onStatusFilterError(error) {
    //when filter load got error, dont show the error page, instead , just condition to next call
    fetchBLApplicationTypeFromAPI()
    fetchPartyIdFromAPI()
  }

  function fetchMessageTypeFromAPI() {
    ComplianceAPI.fetchMessageTypes(
      fetchRecords,
      searchCriteria,
      populateMessageTypes,
      onMessageTypeError
    )
  }

  function onMessageTypeError(error) {
    console.log(error)
  }
  function populateDecTypes(dat) {
    let decTypeList = apiParser.parseMessageTypes(dat)
    setDecTypes(Utils.isEmptyObject(decTypesField.value) ? decTypeList : decTypesField.value)
  }
  function populateMessageTypes(dat) {
    let messageTypeList = apiParser.parseMessageTypes(dat)
    setMessageTypes(Utils.isEmptyObject(messageTypesField.value) ? messageTypeList : messageTypesField.value)
  }

  function fetchStatusCodeAndCountFromAPI() {
    ComplianceAPI.fetchStatusCodeAndCount(
      fetchRecords,
      populateStatusCodeAndCount,
      onStatusError
    )
  }

  function onStatusError(error) {
    console.log(error)
  }

  function getLeftCount() {
    return totalCount - shownCount
  }

  function getSILeftCount(){
    return totalApprovedSICount - SIShownCount
  }

  function getSortingType() {
    setSortByList(SIUtils.PermitSortingType)
    setSortByListField(SIUtils.PermitSortingType)
  }

  function getSortingTypeSI() {
    FRBApi.fetchSortingType(fetchRecords, populateSortingType, onError) //onError cause the page to premature finish load
  }

  function populateSortingType(dat) {
    const list = FRBApiParser.parseSortingType(dat)
    setSortByListSI(list)
    setSortByListSIField(list)
  }

  function onError(error) {
    setPageError(true)
    setLoading(false)
  }

  function populateComplianceList(data) {
    console.log('PERMIT LIST', data)
    setPmList(data.content)
    setShownCount(data.totalElements)
    setLoading(false)
  }

  function getComplianceList() {
    ComplianceAPI.fetchComplianceList(
      fetchRecords,
      searchCriteria,
      populateComplianceList,
      onError
    )
    setLoadingCriteria(searchCriteria)
  }

  function populateApprovedSIList(data){
    setApprovedSIList(data.content)
    setSIShownCount(data.totalElements)
    setTotalApprovedSICount(data.totalElements)
    setLoading(false)

    console.log('Approved SI', data.content)
  }

  function getApprovedSIList(){
    SIApi.fetchShippingInfoList(
      fetchRecords,
      SISearchCriteria,
      populateApprovedSIList,
      onError
    )
    setLoadingCriteriaSI(SISearchCriteria)
  }

  return (
    <Fragment>
      {loading ? (
        <CngGridItem xs={12} sm={9} shouldHide={loading ? false : true}>
          <CngBackdrop loading={loading} />
        </CngGridItem>
        ) : (
          <Grid container justify='center' spacing={1}>
            <CngGridItem xs={12} sm={9}>
              <Card>
                <CardContent>
                  <CngGridItem xs={12}>
                    <ButtonTabComponent.FormBody
                      clickApprovedSI = {clickApprovedSI}
                      approvedSIClicked = {approvedSIClicked}
                      approvedSICount = {totalApprovedSICount}
                      clickPermit = {clickPermit}
                      permitCount = {totalCompliancePermit}
                      totalCount={totalCount}
                      shownCountSI={SIShownCount}
                      shownCount={shownCount}
                      sortByList={sortByList}
                      sortByListSI={sortByListSI}
                      handleSortByChange={handleSortByChange}
                      handleSortByChangeSI={handleSortByChangeSI}
                    />
                  </CngGridItem>

                  {approvedSIClicked && (
                    <CngGridItem xs={12} sm={12}>
                      <Box class='summary-list-items--container mt-25'>
                        <SIListComponent.FormBody
                          formLoading={loading}
                          shownCount={SIShownCount}
                          searchCriteria={loadingCriteriaSI}
                          siList={approvedSIList}
                          showNotification={showNotification}
                          handleClearAllButtonClick={
                            handleClearFiltreAndTextButtonClickSI
                          }
                          leftCount={getSILeftCount()}
                          refreshAction={manualRefreshSI}
                          setPageError={setPageError}
                          managePermit={true}
                        />
                      </Box>
                    </CngGridItem>
                  )}
                  
                  {(!approvedSIClicked || approvedSIClicked == null) && (
                    <CngGridItem xs={12}>
                      <Box class='summary-list-items--container'>
                        <ListComponent.FormBody
                          formLoading={loading}
                          shownCount={shownCount}
                          searchCriteria={loadingCriteria}
                          pmList={pmList}
                          showNotification={showNotification}
                          handleClearAllButtonClick={
                            handleClearFiltreAndTextButtonClick
                          }
                          leftCount={getLeftCount()}
                          refreshAction={manualRefresh}
                          setPageError={setPageError}
                        />
                      </Box>
                    </CngGridItem>
                  )}
                  
                </CardContent>
              </Card>
            </CngGridItem>

            <CngGridItem xs={12} sm={3}>
              <Grid container spacing={1}>
                {approvedSIClicked && (
                  <CngGridItem xs={12}>
                    <FreeTextSearchComponent.FormBody
                      handleInputChange={handleFilterTextSIChange}
                      filterText={filterTextSI}
                      handelFreeTextSearch={handelFreeTextSISearch}
                    />
                  </CngGridItem>
                )}

                {(!approvedSIClicked || approvedSIClicked == null) && (
                  <CngGridItem xs={12}>
                    <FreeTextSearchComponent.FormBody
                      handleInputChange={handleFilterTextChange}
                      filterText={filterText}
                      handelFreeTextSearch={handelFreeTextSearch}
                    />
                  </CngGridItem>
                )}

                {approvedSIClicked && (
                  <CngGridItem xs={12} sm={12}>
                    <SIFilterPanelComponent
                      handleClearAllButtonClick={handleClearAllButtonClickSI}
                      showArchivedChange={showArchivedChange}
                      onCarrierCheckBoxChange={onCarrierCheckBoxChange}
                      onBLCheckBoxChange={onBLCheckBoxChange}
                      dateRange={dateRangeSIField.value}
                      dateRangeChange={dateRangeSIChange}
                      carriers={carriers}
                      bls={bls} //BL Application type
                      showArchived={showArchived}
                      draft={true}
                      isCarrier
                    />
                  </CngGridItem>
                )}
                {(!approvedSIClicked || approvedSIClicked == null) && (
                  <CngGridItem xs={12}>
                    <ComplianceFilterPanelComponent
                      handleClearAllButtonClick={handleClearAllButtonClick}
                      onStatusCheckBoxChange={onStatusCheckBoxChange}
                      onDecTypeCheckBoxChange={onDecTypeCheckBoxChange}
                      onMessTypeCheckBoxChange={onMessTypeCheckBoxChange}
                      dateRange={dateRangeField.value}
                      dateRangeChange={dateRangeChange}
                      messageTypes={messageTypes}
                      decTypes={decTypes}
                      status={status}
                      draft={true}
                      showStatus
                      isCarrier
                      onMyTaskChange={onMyTaskChange}
                    />
                  </CngGridItem>
                )}

                <CngGridItem xs={12} sm={9} shouldHide={loading ? false : true}>
                  <CngBackdrop loading={loading} />
                </CngGridItem>
              </Grid>
            </CngGridItem>
            <ScrollTop>
              <Fab color='secondary' size='small' aria-label='scroll back to top'>
                <KeyboardArrowUpIcon />
              </Fab>
            </ScrollTop>
          </Grid>
        )
      } 
      
    </Fragment>
  )
}

const ManageSIFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  FormFields: FormFields
})

export default ManageSIFormProperties
