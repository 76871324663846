import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { components, useServices } from 'cng-web-lib'
import SearchForm from '../components/search/form/SearchForm'
import DeliveryOrderApiUrls from '../../../../apiUrls/ecommerce/delivery-order/DeliveryOrderApiUrls'
import { createStore } from 'redux'
import { Provider, useDispatch, useSelector } from 'react-redux'
import { DeliveryOrderTable } from '../components/search/table/DeliveryOrderTable'
import { Grid } from '@material-ui/core'
import ConfirmDialog from '../../../../components/dialog/ConfirmDialog'
import { base64toBlob, errorMsgContent } from '../components/util/Utils'
import TranslationText from '../TranslationText'

const {
  CngGridItem, table: { useDefaultNotification }
} = components


const initialState = {
  pageSize: 20,
  pageNumber: 1,
  sortType: 'desc',
  sortColumn: 'createdOn',
  totalPages: 0,
  totalRecords: 0,
  deliveryOrders: [],
  deliveryOrderRefNo: '',
  tableKey: null,
  isLoading: false,
  hasError: false,
  filters: [],
  triggerFetch: true,
  selectedRows: [],
  singlePrintRef: '',
  triggerPrint: false
}

const stateReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'SET_PAGE_SIZE':
      return {
        ...state, pageSize: payload
      }
    case 'SET_PAGE_NUMBER':
      return {
        ...state, pageNumber: payload
      }
    case 'SET_TOTAL_RECORDS':
      return {
        ...state, totalRecords: payload
      }
    case 'SET_TOTAL_PAGES':
      return {
        ...state, totalPages: payload
      }
    case 'SET_SORT_COLUMN':
      return {
        ...state, sortColumn: payload
      }
    case 'SET_SORT_TYPE':
      return {
        ...state, sortType: payload
      }
    case 'SET_DELIVERY_ORDERS':
      return {
        ...state, deliveryOrders: payload
      }
    case 'SET_FILTERS':
      return {
        ...state, filters: payload
      }
    case 'SET_LOADING':
      return {
        ...state, isLoading: payload
      }
    case 'SET_HAS_ERROR':
      return {
        ...state, hasError: payload
      }
    case 'SET_TRIGGER_FETCH':
      return {
        ...state, triggerFetch: payload
      }
    case 'SET_SELECTED_ROWS':
      return {
        ...state, selectedRows: payload
      }
    case 'SET_RESET':
      return initialState

    case 'SET_SINGLE_PRINT':
      return {
        ...state, singlePrintRef: payload
      }
    case 'SET_TRIGGER_PRINT':
      return {
        ...state, triggerPrint: payload
      }
    default:
      return state
  }
}

const store = createStore(stateReducer)

function SearchPage(props) {

  const dispatch = useDispatch()
  const { securedSendRequest } = useServices()
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [dialogContent, setDialogContent] = useState({})
  const {
    pageNumber, pageSize, sortColumn, sortType, filters, triggerFetch, singlePrintRef, selectedRows, triggerPrint
  } = useSelector(({
                     pageNumber,
                     pageSize,
                     sortColumn,
                     sortType,
                     filters,
                     triggerFetch,
                     singlePrintRef,
                     selectedRows,
                     triggerPrint
                   }) => ({
    pageNumber, pageSize, sortColumn, sortType, filters, triggerFetch, singlePrintRef, selectedRows, triggerPrint
  }))

  const {
    showNotification
  } = props

  const {
    error: showErrorNotification
  } = useDefaultNotification(showNotification)

  const {
    generateMessage,
    multipleProviderMessage,
    printedMessage,
    confirmMessage,
    warningTitle,
    errorTitle,
    generatingLabelTitle,
    shipmentProviderNotConfigureMessage
  } = TranslationText()

  useEffect(() => {
    if (triggerFetch) {
      fetchData()
      dispatch({ type: 'SET_TRIGGER_FETCH', payload: false })
    }
  }, [triggerFetch])

  useEffect(() => {
    if (singlePrintRef != '') {
      singlePrint(singlePrintRef)
      dispatch({ type: 'SET_SINGLE_PRINT', payload: '' })
    }
  }, [singlePrintRef])

  useEffect(() => {
    if (triggerPrint) {
      verifyMassPrint()
    }
  }, [triggerPrint])

  const fetchData = () => {

    const sortProperties = sortColumn && sortType ? [{
      'sortField': sortColumn, 'sortType': sortType
    }] : []

    dispatch({ type: 'SET_LOADING', payload: true })

    securedSendRequest.execute('POST', DeliveryOrderApiUrls.SEARCH, {
      'baseFilterDTO': {
        'filterType': 'AND',
        'filterProperties': filters,
        'sortProperties': sortProperties,
        'page': pageNumber - 1,
        'size': pageSize
      }
    }, (res) => {
      if (res.status == 200) {
        const data = res.data.content
        dispatch({ type: 'SET_DELIVERY_ORDERS', payload: data })
        dispatch({ type: 'SET_TOTAL_PAGES', payload: res.data.totalPages })
        dispatch({ type: 'SET_TOTAL_RECORDS', payload: res.data.totalElements })
      }
    }, (error) => showErrorNotification('Error while retrieving delivery orders.'), () => {
      dispatch({ type: 'SET_LOADING', payload: false })
    })
  }

  const singlePrint = (refNo) => {
    const onSuccess = (response) => {
      console.log(response.data)
      if (response.data != null && response.data.meta.code === 200) {
        let label = response.data.data.packages.shippingLabel
        if (label.url != null && label.url != '') {
          window.open(label.url, '_blank')
        } else {
          let base64ImageData = label.file.base64
          let extension = label.file.extension
          const contentType = `image/${extension}`

          const blob = new Blob(base64toBlob(base64ImageData), { type: contentType })
          const blobUrl = URL.createObjectURL(blob)

          window.open(blobUrl, '_blank')
        }
      } else if (response.data != null && response.data.meta.code === 403) {
        //403 Forbidden Error: Response message might in different object (as to follow CG API response)
        //Therefore UI response message directly instead getting from API response 
        errorMsgDialog(shipmentProviderNotConfigureMessage)
      } else if (response.data != null && response.data.meta.code !== 200 && response.data.meta.code !== 403) {
        errorMsgDialog(response.data.data.errorMessage)
      } else {
        errorMsgDialog('System error')
      }

    }
    const onComplete = () => {
      dispatch({ type: 'SET_LOADING', payload: false })
      dispatch({ type: 'SET_TRIGGER_FETCH', payload: true })
    }

    const onError = (error) => {
      console.log('Error... ')
      console.log(error)
      errorMsgDialog(error)
    }

    const errorMsgDialog = (error) => {
      let content = errorMsgContent(error, refNo)

      setDialogContent({
        content: content, title: errorTitle, okMsg: 'OK', cancelMsg: undefined
      })

      setConfirmDialogOpen(true)
    }

    dispatch({ type: 'SET_LOADING', payload: true })
    const reqData = { 'deliveryOrderRefNo': refNo }
    securedSendRequest.execute('POST', DeliveryOrderApiUrls.PRINT, reqData, onSuccess, onError, onComplete)
  }

  const verifyMassPrint = () => {
    let msg = ''

    // If multiple provider, then display confirmation dialog
    const provider = selectedRows.map((l) => l.lastMileShipmentProviderName)
    const uniqueProvider = Array.from(new Set(provider))
    if (uniqueProvider.length > 1) {
      msg += multipleProviderMessage
    }

    // If any of selected is pre-printed, then display confirmation dialog
    const exists = selectedRows.some(r => (r.isPrinted === true))
    if (exists) {
      msg += printedMessage
    }

    if (msg != '') {
      msg += confirmMessage

      setDialogContent({
        content: msg, title: warningTitle, okMsg: 'Yes', cancelMsg: 'Close'
      })

      setConfirmDialogOpen(true)
    } else {
      // If single print/not multiple provider/no pre-printed, direct go to Mass Print
      massPrint()
    }

    return true
  }

  const massPrint = () => {
    const ids = selectedRows.map((l) => l.id)

    const onSuccess = (response) => {
      if (response.status === 200) {
        msgDialog(generateMessage, generatingLabelTitle)
      }
    }

    const onComplete = () => {
      dispatch({ type: 'SET_LOADING', payload: false })
      dispatch({ type: 'SET_TRIGGER_PRINT', payload: false })
    }

    const onError = (error) => {
      console.log('Error... ')
      console.log(error)
    }

    const msgDialog = (msg, title) => {

      setDialogContent({
        content: msg, title: title, okMsg: 'OK', cancelMsg: undefined
      })

      setConfirmDialogOpen(true)
    }
    if (confirmDialogOpen) {
      setConfirmDialogOpen(false)
    }

    dispatch({ type: 'SET_LOADING', payload: true })

    const reqData = { 'doIds': ids }

    securedSendRequest.execute('POST', DeliveryOrderApiUrls.MASS_PRINT, reqData, onSuccess, onError, onComplete)
  }

  const massPrintCloseDialog = () => {
    dispatch({ type: 'SET_TRIGGER_PRINT', payload: false })
    setConfirmDialogOpen(false)
  }

  return (<Grid container spacing={5}>
    <CngGridItem item xs={12}>
      <SearchForm
        showNotification={showNotification}
      />
    </CngGridItem>

    <Grid item xs={12}>
      <DeliveryOrderTable />
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialogOpen}
        closeDialog={() => {
          triggerPrint ? massPrintCloseDialog() : setConfirmDialogOpen(false)
        }}
        confirmDialog={() => {
          triggerPrint ? massPrint() : setConfirmDialogOpen(false)
        }}
        cancelMsg={dialogContent.cancelMsg}
        content={dialogContent.content}
        title={dialogContent.title}
        okMsg={'OK'}
      />
    </Grid>
  </Grid>)
}


SearchPage.propTypes = {
  showNotification: PropTypes.func
}

export default (props) => (<Provider store={store}>
  <SearchPage showNotification={props.showNotification} />
</Provider>);
