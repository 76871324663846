import React, { useRef } from 'react'
import clsx from 'clsx'
import { FormControlLabel, makeStyles, Radio } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor2: (props) =>
      props.checked ? theme.palette.grey[100] : theme.palette.background.paper,
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 8,
    flex: '1 0 auto',
    flexDirection: 'row-reverse',
    gap: 16,
    padding: '4px 16px',
    margin: 0,
    width: '100%'
  },
  label: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    fontWeight: 600,
    width: '100%'
  },
  icon: {
    borderRadius: '50%',
    width: 24,
    height: 24,
    backgroundColor: theme.palette.grey[200],
    'input:disabled ~ &': {
      backgroundColor: theme.palette.grey[100]
    }
  },
  checkedIcon: {
    backgroundColor: theme.palette.primary.main,
    '&:before': {
      display: 'block',
      width: 24,
      height: 24,
      backgroundImage: 'radial-gradient(#fff, #fff 28%, transparent 32%)',
      content: '""'
    }
  }
}))

function RadioToggleButton(props) {
  const { value, label, disabled } = props
  const ref = useRef(null)
  const classes = useStyles({ checked: ref.current?.checked })

  return (
    <FormControlLabel
      value={value}
      classes={{
        root: classes.root,
        label: classes.label
      }}
      control={
        <Radio
          checkedIcon={
            <span className={clsx(classes.icon, classes.checkedIcon)} />
          }
          color='primary'
          disableRipple
          disabled={disabled}
          icon={<span className={classes.icon} />}
        />
      }
      inputRef={ref}
      label={label}
    />
  )
}

export default RadioToggleButton
