import Namespace from '../../../constants/locale/Namespace'
import { useTranslation } from 'cng-web-lib'
import EcommerceUploadKeys from "../../../constants/locale/key/EcommerceUpload";

const NAMESPACE = Namespace.ECOMMERCE_UPLOAD

const TranslationText = () => {

    const { translate } = useTranslation([NAMESPACE])

    const moduleTitle = translate(NAMESPACE, EcommerceUploadKeys.ECOMMERCE)
    const uploadedFilesTitle = translate(NAMESPACE, EcommerceUploadKeys.UPLOADED_FILE_TITLE)
    const uploadType = translate(NAMESPACE, EcommerceUploadKeys.UPLOAD_TYPE)
    const selectTemplate = translate(NAMESPACE, EcommerceUploadKeys.SELECT_TEMPLATE)
    const downloadTemplate = translate(NAMESPACE, EcommerceUploadKeys.DOWNLOAD_TEMPLATE)
    const description = translate(NAMESPACE, EcommerceUploadKeys.DESCRIPTION)

    //Uploaded files table header
    const filename = translate(NAMESPACE, EcommerceUploadKeys.FILENAME)
    const templateType = translate(NAMESPACE, EcommerceUploadKeys.TEMPLATE_TYPE)
    const uploadDate = translate(NAMESPACE, EcommerceUploadKeys.UPLOAD_DATE)
    const noOfEntries = translate(NAMESPACE, EcommerceUploadKeys.NO_OF_ENTRIES)
    const systemResponse = translate(NAMESPACE, EcommerceUploadKeys.SYSTEM_RESPONSE)
    const status = translate(NAMESPACE, EcommerceUploadKeys.STATUS)
    const action = translate(NAMESPACE, EcommerceUploadKeys.ACTION)

    //Text/Label/Message
    const deleted = translate(NAMESPACE, EcommerceUploadKeys.DELETE)
    const upload = translate(NAMESPACE, EcommerceUploadKeys.UPLOAD)
    const download = translate(NAMESPACE, EcommerceUploadKeys.DOWNLOAD)
    const uploading = translate(NAMESPACE, EcommerceUploadKeys.UPLOADING)
    const completed = translate(NAMESPACE, EcommerceUploadKeys.COMPLETED)
    const failed = translate(NAMESPACE, EcommerceUploadKeys.FAILED)
    const downloadSuccessMessage = translate(NAMESPACE, EcommerceUploadKeys.DOWNLOAD_SUCCESS_MESSAGE)
    const docMessage = translate(NAMESPACE, EcommerceUploadKeys.DOC_MESSAGE)
    const errorOccurMessage = translate(NAMESPACE, EcommerceUploadKeys.ERROR_OCCUR_MESSAGE)
    const uploadErrorMessage = translate(NAMESPACE, EcommerceUploadKeys.UPLOAD_ERROR_MESSAGE)
    const savedSuccessMessage = translate(NAMESPACE, EcommerceUploadKeys.SAVED_SUCCESS_MESSAGE)
    const searchMessage = translate(NAMESPACE, EcommerceUploadKeys.SEARCH_MESSAGE)

    const createDOTemplateLabel = translate(NAMESPACE, EcommerceUploadKeys.CREATE_DO_TEMP_LABEL)
    const createDETemplateLabel = translate(NAMESPACE, EcommerceUploadKeys.CREATE_DE_TEMP_LABEL)
    const updateDOTemplateLabel = translate(NAMESPACE, EcommerceUploadKeys.UPDATE_DO_TEMP_LABEL)
    const updateDETemplateLabel = translate(NAMESPACE, EcommerceUploadKeys.UPDATE_DE_TEMP_LABEL)
    const createDOTemplateDesc = translate(NAMESPACE, EcommerceUploadKeys.CREATE_DO_TEMP_DESC)
    const createDETemplateDesc = translate(NAMESPACE, EcommerceUploadKeys.CREATE_DE_TEMP_DESC)
    const updateDOTemplateDesc = translate(NAMESPACE, EcommerceUploadKeys.UPDATE_DO_TEMP_DESC)
    const updateDETemplateDesc = translate(NAMESPACE, EcommerceUploadKeys.UPDATE_DE_TEMP_DESC)

    return {
        moduleTitle,
        uploadedFilesTitle,
        uploadType,
        selectTemplate,
        downloadTemplate,
        description,
        filename,
        templateType,
        uploadDate,
        noOfEntries,
        systemResponse,
        status,
        action,
        deleted,
        upload,
        download,
        uploading,
        completed,
        failed,
        downloadSuccessMessage,
        docMessage,
        errorOccurMessage,
        uploadErrorMessage,
        savedSuccessMessage,
        searchMessage,
        
        createDOTemplateLabel,
        createDETemplateLabel,
        updateDOTemplateLabel,
        updateDETemplateLabel,
        createDOTemplateDesc,
        createDETemplateDesc,
        updateDOTemplateDesc,
        updateDETemplateDesc
    }
}

export default TranslationText


