import { Button, Card, Collapse, Grid, IconButton, Radio, Typography } from '@material-ui/core'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import EmptyBox from '../../view/EmptyBox'
import CountryLabel from '../../view/CountryLabel'
import SCOAdminApiUrls from '../../../../../../apiUrls/SCOAdminApiUrls'
import { components, constants, useServices } from 'cng-web-lib'

PartyTemplateItemDetail.propTypes = {
  selectedValue: PropTypes.string,
  template: PropTypes.any,
  classes: PropTypes.any,
  onChange: PropTypes.func,
  showNotification: PropTypes.func
}


const { CngGridItem } = components

const ExpandMore = styled((props) => {
  const { expand, isSelected, ...other } = props
  return <Button
    color={isSelected ? 'primary' : 'none'}
    size='small'
    style={{
      maxWidth: '36px',
      minWidth: '36px',
      maxHeight: '36px',
      minHeight: '36px',
      marginLeft: '.5rem'
    }}
    variant='contained'
  >
    <IconButton  {...other} />
  </Button>
})(({ theme, expand, isSelected }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  }),
  color: isSelected ? 'white' : '#8e98b0'
}))

const {
  filter: {
    EQUAL
  },
  NotificationType
} = constants

function PartyTemplateItemDetail(props) {

  const [expanded, setExpanded] = useState(false)
  const [stateName, setStateName] = useState('')
  const [addressType, setAddressType] = useState('')
  const { selectedValue, template, showErrorNotification } = props
  const { fetchRecords, securedSendRequest } = useServices()

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  useEffect(() => {
    if (template.countryCode && template.stateId) {
      fetchRecords.execute(
        SCOAdminApiUrls.GET_COUNTRY_STATE_LIST,
        {
          filters: [
            {
              field: 'id',
              operator: EQUAL,
              value: template.stateId
            }
          ]
        },
        (data) => {
          if (data.content && data.content[0] && data.content[0].stateName) {
            setStateName(data.content[0].stateName)
          }
        }, () => {
          showErrorNotification('Error while retrieving state details.')
        }
      )
    }


    if (template.addressTypeId) {

      securedSendRequest.execute(
        'POST',
        SCOAdminApiUrls.GET_CODE_MASTER_LIST,
        {
          'baseFilterDTO': {
            'filterType': 'AND',
            'filterProperties': [
              {
                fieldName: 'codeType',
                operatorType: 'EQUAL',
                value1: 'CEC_ADDRESS_TYPE'
              },
              {
                fieldName: 'id',
                operatorType: 'EQUAL',
                value1: template.addressTypeId
              }
            ]
          }
        },
        (res) => {
          if (res.status == 200) {
            const data = res.data.content
            setAddressType(data.filter(u => u['codeType'] === 'CEC_ADDRESS_TYPE' && u['id'] === template.addressTypeId).map(u => u['description'])[0])
          }
        },
        (error) =>
          showErrorNotification('Error while retrieving address type.')
      )
    }

  }, [expanded])


  return <>
    <Grid item xs={12} sm={12}>
      <Card variant='outlined'
            className={selectedValue === template.code ? props.classes.box_line_blue : props.classes.box_line}
            spacing={1}>
        <Grid container spacing={1}>

          <CngGridItem xs={12} sm={1}>
            <Radio
              color='primary'
              checked={template.code === selectedValue}
              checkedIcon={
                <span className={clsx(props.classes.icon, props.classes.checkedIcon)} />
              }
              onChange={props.onChange}
              value={template.code}
              name='selectedTemplate'
              inputProps={{ 'aria-label': 'A' }}
            />
          </CngGridItem>

          <CngGridItem xs={12} sm={3} style={{ marginTop: 12 }}>
            <Typography className='page-content-groupLabel'> {template.code}</Typography>
          </CngGridItem>

          <CngGridItem xs={12} sm={3} style={{ marginTop: 12 }}>
            <Typography className='boldLabel'>
              {(template.firstName || template.lastName) ? [template.firstName, template.lastName].join(' ') :
                <EmptyBox />}
            </Typography>
          </CngGridItem>

          <CngGridItem xs={12} sm={4} style={{ marginTop: 12 }}>
            <Typography className='boldLabel'>{template.address}</Typography>
          </CngGridItem>

          <CngGridItem xs={12} sm={1} style={{ textAlign: 'right' }}>
            <ExpandMore
              expand={expanded}
              isSelected={selectedValue === template.code}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label='show more'
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </CngGridItem>

          <Collapse in={expanded} timeout='auto' unmountOnExit style={{ width: '100%' }}>

            <CngGridItem xs={12} sm={12}>

              <Grid container spacing={1}>

                <CngGridItem xs={12} sm={1}>
                  <EmptyBox />
                </CngGridItem>

                <CngGridItem xs={12} sm={3}>
                  <Typography variant='caption' color='textSecondary'>Party template ID</Typography>
                  <Typography variant='body2' noWrap={true}>{template.code ? template.code :
                    <EmptyBox />}</Typography>
                </CngGridItem>

                <CngGridItem xs={12} sm={3}>
                  <Typography variant='caption' color='textSecondary'>First name</Typography>
                  <Typography variant='body2' noWrap={true}>{template.firstName ? template.firstName :
                    <EmptyBox />}</Typography>
                </CngGridItem>

                <CngGridItem xs={12} sm={3}>
                  <Typography variant='caption' color='textSecondary'>Last name</Typography>
                  <Typography variant='body2' noWrap={true}>{template.lastName ? template.lastName :
                    <EmptyBox />}</Typography>
                </CngGridItem>

                <CngGridItem xs={12} sm={2}>
                  <EmptyBox />
                </CngGridItem>

                <CngGridItem xs={12} sm={1}>
                  <EmptyBox />
                </CngGridItem>

                <CngGridItem xs={12} sm={3}>
                  <Typography variant='caption' color='textSecondary'>Address Type</Typography>
                  <Typography variant='body2' noWrap={true}>
                    {addressType ? addressType :
                      <EmptyBox />}</Typography>
                </CngGridItem>

                <CngGridItem xs={12} sm={6}>
                  <Grid container spacing={1}>

                    <CngGridItem xs={12} sm={6}>
                      <Typography variant='caption' color='textSecondary'>Contact</Typography>
                      <Typography variant='body2'
                                  noWrap={true}>{template.phoneNo ? template.phoneNo :
                        <EmptyBox />}</Typography>
                    </CngGridItem>

                    <CngGridItem xs={12} sm={6}>
                      <Typography variant='caption' color='textSecondary'>Email
                        address</Typography>
                      <Typography variant='body2' noWrap={true}>{template.email ? template.email :
                        <EmptyBox />}</Typography>
                    </CngGridItem>

                    <CngGridItem xs={12} sm={6}>
                      <Typography variant='caption' color='textSecondary'>Company
                        name</Typography>
                      <Typography variant='body2'
                                  noWrap={true}>{template.companyName ? template.companyName :
                        <EmptyBox />}</Typography>
                    </CngGridItem>

                    <CngGridItem xs={12} sm={6}>
                      <Typography variant='caption' color='textSecondary'>Fax no</Typography>
                      <Typography variant='body2' noWrap={true}>{template.faxNo ? template.faxNo :
                        <EmptyBox />}</Typography>
                    </CngGridItem>

                    <CngGridItem xs={12} sm={6}>
                      <Typography variant='caption' color='textSecondary'>Country</Typography>
                      <Typography variant='body2'>
                        <CountryLabel countryCode={template.countryCode}
                                      showErrorNotification={showErrorNotification} />
                      </Typography>
                    </CngGridItem>

                    <CngGridItem xs={12} sm={6}>
                      <Typography variant='caption' color='textSecondary'>State /
                        Province</Typography>
                      <Typography variant='body2'>{(stateName) ? stateName :
                        <EmptyBox />}</Typography>
                    </CngGridItem>

                    <CngGridItem xs={12} sm={6}>
                      <Typography variant='caption' color='textSecondary'>Other state /
                        Province</Typography>
                      <Typography variant='body2'
                                  noWrap={true}>{template.otherState ? template.otherState :
                        <EmptyBox />}</Typography>
                    </CngGridItem>

                    <CngGridItem xs={12} sm={6}>
                      <Typography variant='caption' color='textSecondary'>City</Typography>
                      <Typography variant='body2' noWrap={true}>{template.city ? template.city :
                        <EmptyBox />}</Typography>
                    </CngGridItem>

                    <CngGridItem xs={12} sm={6}>
                      <Typography variant='caption' color='textSecondary'>Street</Typography>
                      <Typography variant='body2'
                                  noWrap={true}>{template.address ? template.address :
                        <EmptyBox />}</Typography>
                    </CngGridItem>

                    <CngGridItem xs={12} sm={6}>
                      <Typography variant='caption' color='textSecondary'>Postal / Zip
                        code</Typography>
                      <Typography variant='body2'>{template.postCode ? template.postCode :
                        <EmptyBox />}</Typography>
                    </CngGridItem>

                  </Grid>
                </CngGridItem>
              </Grid>
            </CngGridItem>
          </Collapse>
        </Grid>
      </Card>
    </Grid>
  </>
}

export default PartyTemplateItemDetail
