import { Yup } from 'cng-web-lib'

function DoUploadValidationSchema() {
  const mandatoryErrMsg = 'Required/Mandatory.'

  return Yup.object({

    documents: Yup.array(
      Yup.object({
        docType: Yup.string().required(mandatoryErrMsg).nullable()
      })
    ).nullable()
  })
}

export default DoUploadValidationSchema


