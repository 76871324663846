import React from 'react'
import PropTypes from 'prop-types'
import { components } from 'cng-web-lib'
import { Box, Card, Grid, Paper, Typography } from '@material-ui/core'
import CloseDialogIconButton from '../../../../../components/iconbutton/CloseDialogIconButton'
import EmptyBox from './EmptyBox'


ViewItemDialog.propTypes = {
  isDialogOpen: PropTypes.bool,
  closeDialog: PropTypes.func,
  itemData: PropTypes.object
}

const { CngDialog, CngGridItem, button: { CngPrimaryButton } } = components

function ViewItemDialog(props) {
  const { isDialogOpen, closeDialog, itemData, salesOrderCurrency, itemUOMs } = props
  const currencySymbolPrefix = salesOrderCurrency && salesOrderCurrency.symbol ? salesOrderCurrency.symbol + ' ' : ''

  return (
    <CngDialog
      customDialogContent={
        <Paper square>
          <div style={{ overflow: 'hidden' }}>
            <Grid container xs={12} sm={12} spacing={3}>
              <CngGridItem xs={12} sm={12}>
                <Card style={{ margin: '1rem' }}>
                  <Box className='view-card-header' style={{ padding: '-1rem !important' }}>
                    <Grid container xs={12} sm={12}>
                      <CngGridItem xs={12} sm={4}>
                        {'Product Information'}
                      </CngGridItem>
                      <CngGridItem xs={12} sm={8} style={{ textAlign: 'right' }}>
                        <Typography
                          noWrap={true}>{'Item ref. no.: '}{itemData && itemData.refNo ? itemData.refNo : null}</Typography>
                      </CngGridItem>
                    </Grid>
                  </Box>
                  <Box>
                    <Grid container xs={12} sm={12}>
                      <CngGridItem xs={12} sm={4}>
                      </CngGridItem>
                      <CngGridItem xs={12} sm={8}>
                        <Grid container xs={12} sm={12}>
                          <CngGridItem xs={12} sm={12}>
                            <Box className='view-card-content'>
                              <Box className='view-card-content-label'>
                                {'Product Name Or Description'}
                              </Box>
                              <Box className='view-card-content-value'>
                                <Typography
                                  noWrap={true}>{itemData && itemData.productName ? itemData.productName :
                                  <EmptyBox />}</Typography>
                              </Box>
                            </Box>
                          </CngGridItem>
                          <CngGridItem xs={12} sm={6}>
                            <Box className='view-card-content'>
                              <Box className='view-card-content-label'>
                                {'Unit Price'}
                              </Box>
                              <Box className='view-card-content-value'>
                                <Typography>{itemData && itemData.price ? currencySymbolPrefix + itemData.price :
                                  <EmptyBox />}</Typography>
                              </Box>
                            </Box>
                          </CngGridItem>
                          <CngGridItem xs={12} sm={6}>
                            <Box className='view-card-content'>
                              <Box className='view-card-content-label'>
                                {'Declared price'}
                              </Box>
                              <Box className='view-card-content-value'>
                                <Typography>{itemData && itemData.declaredPrice ? currencySymbolPrefix + itemData.declaredPrice :
                                  <EmptyBox />}</Typography>
                              </Box>
                            </Box>
                          </CngGridItem>
                          <CngGridItem xs={12} sm={6}>
                            <Box className='view-card-content'>
                              <Box className='view-card-content-label'>
                                {'Quantity'}
                              </Box>
                              <Box className='view-card-content-value'>
                                <Typography>{itemData && itemData.quantity ? itemData.quantity :
                                  <EmptyBox />}</Typography>
                              </Box>
                            </Box>
                          </CngGridItem>
                          <CngGridItem xs={12} sm={6}>
                            <Box className='view-card-content'>
                              <Box className='view-card-content-label'>
                                {'Quantity UOM'}
                              </Box>
                              <Box className='view-card-content-value'>
                                <Typography>{itemData && itemData.uom ? itemUOMs.filter(uom => uom['code'] === itemData.uom).map(uom => uom['name']) :
                                  <EmptyBox />}</Typography>
                              </Box>
                            </Box>
                          </CngGridItem>
                        </Grid>
                      </CngGridItem>

                    </Grid>
                  </Box>
                  <Box className='view-card-header' style={{ padding: '-1rem !important' }}>
                    <Grid container xs={12} sm={12}>
                      <CngGridItem xs={12} sm={12}>
                        {'Other Information'}
                      </CngGridItem>
                    </Grid>
                  </Box>
                  <Box>
                    <Grid container xs={12} sm={12}>
                      <CngGridItem xs={12} sm={4}>
                      </CngGridItem>
                      <CngGridItem xs={12} sm={8}>
                        <Grid container xs={12} sm={12}>
                          <CngGridItem xs={12} sm={6}>
                            <Box className='view-card-content'>
                              <Box className='view-card-content-label'>
                                {'Sales order item no.'}
                              </Box>
                              <Box className='view-card-content-value'>
                                <Typography
                                  noWrap={true}>{itemData && itemData.salesOrderItemNo ? itemData.salesOrderItemNo :
                                  <EmptyBox />}</Typography>
                              </Box>
                            </Box>
                          </CngGridItem>
                          <CngGridItem xs={12} sm={6}>
                            <Box className='view-card-content'>
                              <Box className='view-card-content-label'>
                                {'HS code ( 6 digits)'}
                              </Box>
                              <Box className='view-card-content-value'>
                                <Typography>{itemData && itemData.internationalHSCode ? itemData.internationalHSCode :
                                  <EmptyBox />}</Typography>
                              </Box>
                            </Box>
                          </CngGridItem>
                          <CngGridItem xs={12} sm={6}>
                            <Box className='view-card-content'>
                              <Box className='view-card-content-label'>
                                {'Destination HS code'}
                              </Box>
                              <Box className='view-card-content-value'>
                                <Typography>{itemData && itemData.exportHSCode ? itemData.exportHSCode :
                                  <EmptyBox />}</Typography>
                              </Box>
                            </Box>
                          </CngGridItem>
                          <CngGridItem xs={12} sm={6}>
                            <Box className='view-card-content'>
                              <Box className='view-card-content-label'>
                                {'Destination CA product code'}
                              </Box>
                              <Box className='view-card-content-value'>
                                <Typography
                                  noWrap={true}>{itemData && itemData.exportCAProductCode ? itemData.exportCAProductCode :
                                  <EmptyBox />}</Typography>
                              </Box>
                            </Box>
                          </CngGridItem>
                          <CngGridItem xs={12} sm={6}>
                            <Box className='view-card-content'>
                              <Box className='view-card-content-label'>
                                {'Origin HS code'}
                              </Box>
                              <Box className='view-card-content-value'>
                                <Typography>{itemData && itemData.importHSCode ? itemData.importHSCode :
                                  <EmptyBox />}</Typography>
                              </Box>
                            </Box>
                          </CngGridItem>
                          <CngGridItem xs={12} sm={6}>
                            <Box className='view-card-content'>
                              <Box className='view-card-content-label'>
                                {'Origin CA product code'}
                              </Box>
                              <Box className='view-card-content-value'>
                                <Typography
                                  noWrap={true}>{itemData && itemData.importCAProductCode ? itemData.importCAProductCode :
                                  <EmptyBox />}</Typography>
                              </Box>
                            </Box>
                          </CngGridItem>
                        </Grid>
                      </CngGridItem>

                    </Grid>
                  </Box>
                </Card>
              </CngGridItem>
              <Grid container xs={12} sm={12} style={{ textAlign: 'right' }} spacing={3}>
                <CngGridItem xs={12} sm={12} style={{ textAlign: 'right', marginBottom: '2rem' }}>
                  <hr style={{ borderTop: '1px solid #f4f4fc', width: '100%', marginLeft: '1rem' }} />
                  <CngPrimaryButton
                    onClick={closeDialog}
                  >
                    Okay
                  </CngPrimaryButton>
                </CngGridItem>
              </Grid>
            </Grid>
          </div>
        </Paper>
      }
      dialogTitle={
        <>
          {'View Item'}
          <CloseDialogIconButton
            onClick={closeDialog}
          />
        </>
      }
      open={isDialogOpen}
      fullWidth
      maxWidth='md'
    />
  )
}

export default ViewItemDialog
