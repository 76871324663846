import { Yup } from 'cng-web-lib'
import ValidationMessageTranslationText from './ValidationMessageTranslationText'

function useComplianceContainerValidationSchema(){
    const validationMessageTranslation = ValidationMessageTranslationText()


    return Yup.object({
            shippingInfoComplianceContainers: Yup.array(
                Yup.object({
                    ctnNo: Yup.string()
                        .required(validationMessageTranslation.requiredContNumber)
                        .nullable(),
                    ctnTypeSize: Yup.string()
                        .required(validationMessageTranslation.requiredContTypeSize)
                        .nullable(),
                    ctnSealNo: Yup.string()
                        .required(validationMessageTranslation.requiredContSealNo)
                        .nullable(),
                    ctnWtValue: Yup.lazy((value)=> value ===''? 
                        Yup.string()
                        .nullable()
                        .required(validationMessageTranslation.requiredContWeight) 
                        :
                        Yup.number()
                        .nullable()
                        .required(validationMessageTranslation.requiredContWeight)
                        .integer(validationMessageTranslation.nonDecWeight)
                        .min(1,validationMessageTranslation.minContWeight)
                        .max(999,validationMessageTranslation.maxContWeight) ),
                })
            )
        })
}

export default useComplianceContainerValidationSchema