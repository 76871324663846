import { Yup } from 'cng-web-lib'

const ValidationSchemaForItems = () => {

  const requiredErrorMessage = 'Required/Mandatory'
  const mustNonBeNegativeNumber = 'This must not be a negative number'
  const maxLengthShouldBe255 = 'This cannot be more than 255 characters.'
  const maxLengthShouldBe6 = 'This cannot be more than 6 characters.'
  const maxLengthShouldBe20 = 'This cannot be more than 20 characters.'
  const maxSizeofGeneralNumeric = 'This must be lower than or equal to 9999999.99'
  const mustBeIn2DecimalPoints = 'Must be in 2 decimal points.'

  const genericMaxValueCheckForNumber = value => value <= 9999999.99

  const shouldBeNonNegativeNumber = Yup.mixed()
    .test('nonNegative', mustNonBeNegativeNumber, value => value ? !isNaN(value) && value >= 0 : true)
    .test('maxCheck', maxSizeofGeneralNumeric, value => value ? genericMaxValueCheckForNumber(value) : true)

  const shouldBeNonNegativeNumberWithTwoDecimalPoints = Yup.mixed()
    .test('nonNegative', mustNonBeNegativeNumber, value => value ? !isNaN(value) && value >= 0 : true)
    .test('requiredTwoDecimalPoint', mustBeIn2DecimalPoints, value => value ? /^\d+(\.\d{2})$/.test(value) : true)
    .test('maxCheck', maxSizeofGeneralNumeric, value => value ? genericMaxValueCheckForNumber(value) : true)

  return Yup.object({
    refNo: Yup.string().max(255, maxLengthShouldBe255).required(requiredErrorMessage),
    price: shouldBeNonNegativeNumberWithTwoDecimalPoints,
    declaredPrice: shouldBeNonNegativeNumberWithTwoDecimalPoints,
    quantity: shouldBeNonNegativeNumber,
    salesOrderItemNo: Yup.string().nullable().max(255, maxLengthShouldBe255),
    internationalHSCode: Yup.string().nullable().max(6, maxLengthShouldBe6),
    exportHSCode: Yup.string().nullable().max(20, maxLengthShouldBe20),
    importHSCode: Yup.string().nullable().max(20, maxLengthShouldBe20),
    exportCAProductCode: Yup.string().nullable().max(255, maxLengthShouldBe255),
    importCAProductCode: Yup.string().nullable().max(255, maxLengthShouldBe255)
  })

}


export default ValidationSchemaForItems

