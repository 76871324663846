import React from 'react'
import clsx from 'clsx'
import { FormControl, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core'
import classNames from 'classnames'
import Pagination from '@material-ui/lab/Pagination'

const useMuiTableStyles = makeStyles((theme) => ({
  toolbar: {
    '& h1, h2, h3, h4, h5, h6, .title': {
      color: theme.palette.component.cngMuiTable.toolbarTitle
    },

    '& .sub-title': {
      color: theme.palette.component.cngMuiTable.toolbarSubTitle
    },

    '& .highlight': {
      backgroundColor: theme.palette.component.cngMuiTable.toolbarHighlightFill,

      '& h1, h2, h3, h4, h5, h6, .title': {
        color: theme.palette.component.cngMuiTable.toolbarHighlightText
      }
    },

    '& .toolbar-search': {
      '&  > div:first-child': {
        backgroundColor: `${theme.palette.component.textFormControl.normalFill} !important`,
        border: `1px ${theme.palette.component.textFormControl.normalBorder} solid !important`,

        '&:hover': {
          backgroundColor: `${theme.palette.component.textFormControl.hoverFill} !important`,
          borderColor: `${theme.palette.component.textFormControl.hoverBorder} !important`
        },
        '&:active': {
          backgroundColor: `${theme.palette.component.textFormControl.activeFill} !important`,
          border: `1px ${theme.palette.component.textFormControl.activeBorder} solid !important`
        },
        '&:focus, &.Mui-focused': {
          border: `1px ${theme.palette.component.textFormControl.activeBorder} solid !important`
        }
      }
    }
  },
  paginationList: {
    '& li': {
      '&:first-of-type': {
        '& button': {
          backgroundColor: `${theme.palette.component.pagination.btnNavNormalFill} !important`,
          color: `${theme.palette.component.pagination.btnNavNormalText} !important`,

          '&:hover': {
            backgroundColor: `${theme.palette.component.pagination.btnNavHoverFill} !important`
          }
        }
      },
      '&:last-of-type': {
        '& button': {
          backgroundColor: `${theme.palette.component.pagination.btnNavNormalFill} !important`,
          color: `${theme.palette.component.pagination.btnNavNormalText} !important`,

          '&:hover': {
            backgroundColor: `${theme.palette.component.pagination.btnNavHoverFill} !important`
          }
        }
      },
      '& button': {
        backgroundColor: `transparent`,
        color: `${theme.palette.component.pagination.btnNormalText} !important`,
        '&:hover': {
          backgroundColor: `${theme.palette.component.pagination.btnHoverFill} !important`
        },
        '&.Mui-selected': {
          backgroundColor: `${theme.palette.component.pagination.btnActiveFill} !important`,
          color: `${theme.palette.primary.contrastText} !important`
        },
        '& svg': {
          color: `${theme.palette.component.pagination.btnNavNormalText} !important`
        }
      }
    }
  },
  paginationDropdown: {
    backgroundColor: `${theme.palette.component.dropdown.normalFill} !important`,
    border: `1px ${theme.palette.component.dropdown.normalBorder} solid !important`,

    '&:hover': {
      backgroundColor: `${theme.palette.component.dropdown.hoverFill} !important`
    },
    '&:active': {
      backgroundColor: `${theme.palette.component.dropdown.activeFill} !important`,
      border: `1px ${theme.palette.component.dropdown.activeBorder} solid !important`
    },
    '&:focus': {
      backgroundColor: `${theme.palette.component.dropdown.activeFill} !important`,
      border: `1px ${theme.palette.component.dropdown.activeBorder} solid !important`
    },
    '& .Mui-focused': {
      backgroundColor: `${theme.palette.component.dropdown.activeFill} !important`,
      border: `1px ${theme.palette.component.dropdown.activeBorder} solid !important`
    },
    '& .dropdown-arrow': {
      color: `${theme.palette.component.dropdown.normalArrowFill} !important`
    },
    '& .ng-form-control-select-placeholder': {
      color: `${theme.palette.component.dropdown.placeholderText} !important`
    }
  }
}))


const useCngSelectStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: `${theme.palette.component.dropdown.normalFill}`,
    border: `1px ${theme.palette.component.dropdown.normalBorder} solid`,
    borderRadius: 6,
    transition: 'backgroundColor 0.3s ease-in-out',

    '&::before, &::after': {
      borderBottom: 0
    },

    '&:hover': {
      backgroundColor: `${theme.palette.component.dropdown.hoverFill} !important`,

      '&:before, &:after': {
        borderBottom: 0
      },
      '&.MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: 0
      }
    },
    '&:active': {
      backgroundColor: `${theme.palette.component.dropdown.activeFill} !important`,
      border: `1px ${theme.palette.component.dropdown.activeBorder} solid !important`
    },
    '&:focus': {
      backgroundColor: `${theme.palette.component.dropdown.activeFill} !important`,
      border: `1px ${theme.palette.component.dropdown.activeBorder} solid !important`
    },
    '& .Mui-focused': {
      backgroundColor: `${theme.palette.component.dropdown.activeFill} !important`,
      border: `1px ${theme.palette.component.dropdown.activeBorder} solid !important`
    },
    '& .dropdown-arrow': {
      color: `${theme.palette.component.dropdown.normalArrowFill} !important`
    },
    '& .ng-form-control-select-placeholder': {
      color: `${theme.palette.component.dropdown.placeholderText} !important`,
      fontSize: 12,
      fontWeight: 700,
      padding: '5px 32px 5px 8px'
    },
    '&.small': {
      minWidth: 80,

      '& .dropdown-arrow': {
        right: 0
      }
    },

    '&.xtra-small': {
      minWidth: 40,

      '& .dropdown-arrow': {
        right: 0
      }
    }
  }
}))


function TablePagination(props, refresh) {

  let pageSize = props.rowsPerPage
  let totalPages = Math.ceil(props.count / pageSize)
  let pageNumber = props.page+1
  

  const tableClasses = useMuiTableStyles()
  const selectStyles = useCngSelectStyles()


  return (
    <div className={clsx('ng-mattbl-cust-pagination')}>
      <div className={'label-field'}>
        <InputLabel id='per-page' className={'label'}>
          Items per page
        </InputLabel>
        <FormControl variant='filled'>
          <Select
            labelId='per-page'
            id='per-page'
            value={pageSize}
            onChange={props.onChangeRowsPerPage}
            className={classNames(
              tableClasses.paginationDropdown,
              selectStyles.root,
              'ng-form-control-select',
              'xtra-small'
            )}
            classes={{
              selectMenu: 'ng-form-control-select-placeholder',
              icon: 'dropdown-arrow'
            }}
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className={'flex-grow'}></div>
      <div className={'ng-pagination-controls'}>
        <Pagination
          count={totalPages}
          defaultPage={1}
          boundaryCount={2}
          page={pageNumber}
          onChange={(event, page) => {
            props.onChangePage(event, page-1)}
          }
          variant='outlined'
          shape='rounded'
          classes={{
            root: clsx(tableClasses.paginationList, 'ng-pagination-list')
          }}
        />
      </div>
    </div>
  )
}


export default TablePagination
