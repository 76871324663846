import { components} from 'cng-web-lib'
import { Grid, Typography, Box, Card, RadioGroup, FormControlLabel, Radio } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'
import BackButton from 'src/components/button/BackButton.js'
import TranslationText from '../TranslatedText'

const {
  CngGridItem,
  button: { CngPrimaryButton },
} = components


const useStyles = makeStyles(theme => ({
  box_line: {
    border: '1px solid #F5F5FA',
    marginLeft: theme.spacing(0.75),
    padding: theme.spacing(0.75),
    width: '100%'
  },
  box_line_blue: {
    border: '1px solid #5E81F4',
    marginLeft: theme.spacing(0.75),
    padding: theme.spacing(0.75),
    width: '100%'
  },
  checked_button: {
    marginLeft: '15px',
    padding: '3px',
    backgroundColor: '#5e81f4 !important',
    borderRadius: '10px',
    color: 'white !important',
  },
  check_button: {
    marginLeft: '15px',
    padding: '3px',
    backgroundColor: '#ececec',
    borderRadius: '10px',
    color: 'grey',
  },
  textColor: {
    color: '#8996AF'
  }

}))

function DownloadTemplateDialogForm(props) {
  return (
    <FormBody
      closeDialog={props.closeDialog}
    />
  )
}

function FormBody({ closeDialog }) {
  const classes = useStyles()
  const translatedTextsObject = TranslationText();

  const templateList = [
    { value: 'createDo', label: translatedTextsObject.createDOTemplateLabel, desc: translatedTextsObject.createDOTemplateDesc, url: '' },
    { value: 'createDoEvent', label: translatedTextsObject.createDETemplateLabel, desc: translatedTextsObject.createDETemplateDesc, url: '' },
    { value: 'updateDo', label: translatedTextsObject.updateDOTemplateLabel, desc: translatedTextsObject.updateDOTemplateDesc, url: '' },
    { value: 'updateDoEvent', label: translatedTextsObject.updateDETemplateLabel, desc: translatedTextsObject.updateDETemplateDesc, url: '' },
  ]

  const DEFAULT_VALUE = 'createDo'
  const [value, setValue] = useState(DEFAULT_VALUE)

  const handleChange = (event) => {
    setValue(event.target.value)
  }

  const downloadTemplate = () => {
    let fileName = '';
    let filePath = '';
    if (value === 'createDo') {
      fileName = 'Create_Delivery_Order_template.xlsx'
    } else if (value === 'createDoEvent') {
      fileName = 'Create_Delivery_Event_template.xlsx'
    } else if (value === 'updateDo') {
      fileName = 'Update_Delivery_Order_template.xlsx'
    } else if (value === 'updateDoEvent') {
      fileName = 'Update_Delivery_Event_template.xlsx'
    }

    filePath = `${process.env.PUBLIC_URL}/static/ecommerce/${fileName}`
    if (fileName !== '' && filePath !== '') {
      let a = document.createElement('a')
      a.href = filePath
      a.setAttribute('download', fileName)
      a.click()
    }

  }

  return (
    <Grid>
      <Card variant='outlined' spacing={2} style={{ margin: '22px' }}>
        <Grid container spacing={1} style={{ backgroundColor: '#eff2f04d', padding: '14px 20px' }}>
          <CngGridItem xs={1} sm={1}>
            <Typography variant='body2' ></Typography>
          </CngGridItem>
          <CngGridItem xs={4} sm={4}>
            <Typography variant='body2' className={classes.textColor}>{translatedTextsObject.templateType}</Typography>
          </CngGridItem>
          <CngGridItem xs={7} sm={7}>
            <Typography variant='body2' className={classes.textColor}>{translatedTextsObject.description}</Typography>
          </CngGridItem>
        </Grid>

        <Grid item xs={12} container spacing={2} style={{ marginTop: '8px', marginBottom: '8px' }}>
          {
            templateList.map((type) => {
              return (
                <>
                  <Grid item xs={12} sm={12} key={type}>
                    <Card variant='outlined' className={value === type.value ? classes.box_line_blue : classes.box_line} spacing={1}>
                      <Grid container spacing={1} >

                        <CngGridItem xs={1} sm={1}>
                          <RadioGroup name='selectedTemplate' value={value} onChange={handleChange}>
                            <FormControlLabel value={type.value} control={<Radio />} />
                          </RadioGroup>
                        </CngGridItem>
                        <CngGridItem xs={4} sm={4} style={{ marginTop: 12 }}>
                          <Grid><Typography variant='body2' className={classes.textColor}> {type.label}</Typography></Grid>
                        </CngGridItem>
                        <CngGridItem xs={7} sm={7} style={{ marginTop: 12 }}>
                          <Grid><Typography variant='body2'>{type.desc}</Typography></Grid>
                        </CngGridItem>
                      </Grid>
                    </Card>
                  </Grid>
                </>
              )
            })
          }

        </Grid>
      </Card>
      <Box display='flex' flexDirection={'row-reverse'} my={3} mx={3}>

        <CngPrimaryButton style={{ marginLeft: '8px' }} onClick={downloadTemplate}>
          {translatedTextsObject.downloadTemplate}
        </CngPrimaryButton>

        <BackButton onClick={closeDialog} />
      </Box>
    </Grid>
  )
}


export default DownloadTemplateDialogForm
