import React from 'react'
import { useHistory } from 'react-router-dom'
import ManagePermitFormProperties from './ManagePermitFormProperties'
import { components } from 'cng-web-lib'
import pathMap from 'src/paths/PathMap_ShippingInstruction'

const {
  form: { CngViewForm }
} = components

function ManageSIPage({ showNotification }) {
  const history = useHistory()

  function onSubmit(data) {
    console.log('submit', data)

    history.push('/')
    history.replace({ pathname: pathMap.MY_SI, state: data })
  }

  return (
    // use CngViewForm here for customizable button set
    <CngViewForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      history={history}
      showNotification={showNotification}
      bodySection={
        <ManagePermitFormProperties.FormFields
          disabled={false}
          showNotification={showNotification}
        />
      }
      formikProps={{
        ...ManagePermitFormProperties.formikProps,
        onSubmit: onSubmit
      }}
    />
  )
}

export default ManageSIPage
