import { Yup } from 'cng-web-lib'
import ValidationMessageTranslationText from './ValidationMessageTranslationText'


function useCompliancePermitTypeValidationSchema(){
    const validationMessageTranslation = ValidationMessageTranslationText()

    return Yup.object({
        uniqueCrNo: Yup.string()
            .required(validationMessageTranslation.requiredMessage)
            .nullable(),
        carType: Yup.string()
            .required(validationMessageTranslation.requiredMessage)
            .nullable(),
        decType: Yup.string()
            .required(validationMessageTranslation.requiredMessage)
            .nullable(),
    })
    
}

export default useCompliancePermitTypeValidationSchema