import React from 'react'
import ValidationSchema from './ValidationSchema'
import { components } from 'cng-web-lib'
import { Grid } from '@material-ui/core'
import MainOrderDetails from '../MainOrderDetails'
import { useFormContext } from 'react-hook-form'
import ViewDetailedPanel from '../ViewDetailedPanel'
import PackageAndInvoiceDetails from '../PackageAndInvoiceDetails'
import ItemsDetails from '../item/ItemsDetails'
import { convertFormDataForAPI } from '../../util/Utils'
import AddressDetails from '../AddressDetails'
import StepperButtonSection from '../StepperButtonSection'

const {
  CngGridItem
} = components


function Fields({ ...props }) {

  const {
    activeStep,
    actionType,
    showNotification,
    steps,
    handleNext,
    handleBack,
    salesOrderCurrency,
    fetchCurrencyDetails,
    partyTemplates,
    updatePartyTemplate,
    deliveryOrderItems,
    setDeliveryOrderItems
  } = props
  const { getValues } = useFormContext()
  const { formState } = useFormContext()
  const getDeliveryOrder = () => {
    const formData = getValues()
    return convertFormDataForAPI(formData)
  }

  return (<Grid container spacing={3}>

      <Grid item xs={12}>
      </Grid>

      <CngGridItem xs={12} style={{ display: (activeStep === 0 ? 'block' : 'none') }}>
        <MainOrderDetails actionType={actionType} showNotification={showNotification}
                          fetchCurrencyDetails={fetchCurrencyDetails} />
      </CngGridItem>

      <CngGridItem xs={12} style={{ display: (activeStep === 0 ? 'block' : 'none') }}>
        <PackageAndInvoiceDetails actionType={actionType} showNotification={showNotification} />
      </CngGridItem>

      <CngGridItem xs={12} style={{ display: (activeStep === 0 ? 'block' : 'none') }}>
        <AddressDetails partyType={'shipper'}
                        actionType={actionType}
                        showNotification={showNotification}
                        partyTemplate={partyTemplates.shipper} updatePartyTemplate={updatePartyTemplate} />
      </CngGridItem>

      <CngGridItem xs={12} style={{ display: (activeStep === 0 ? 'block' : 'none') }}>
        <AddressDetails partyType={'consignee'} actionType={actionType} showNotification={showNotification}
                        partyTemplate={partyTemplates.consignee} />
      </CngGridItem>

      <CngGridItem xs={12} style={{ display: (activeStep === 1 ? 'block' : 'none') }}>
        <ItemsDetails showNotification={showNotification} deliveryOrderItems={deliveryOrderItems}
                      setDeliveryOrderItems={setDeliveryOrderItems}
                      actionType={actionType}
                      salesOrderCurrency={salesOrderCurrency} />
      </CngGridItem>

      <CngGridItem xs={12} style={{ display: (activeStep === 2 ? 'block' : 'none') }}>
        <ViewDetailedPanel showNotification={showNotification} deliveryOrder={getDeliveryOrder()}
                           salesOrderCurrency={salesOrderCurrency} partyTemplates={partyTemplates} />
      </CngGridItem>

      <CngGridItem xs={12}>
        <StepperButtonSection activeStep={activeStep} steps={steps} handleNext={handleNext}
                              handleBack={handleBack}
                              actionType={actionType}
                              formState={formState} />
      </CngGridItem>

    </Grid>

  )
}

const DEFAULT_INITIAL_VALUES = {
  deliveryOrderRefNo: '',
  shipmentProvider: '',
  deliveryOrderStatus: 'Created',
  trackingNo: '',
  otherRef: [],
  originCountry: '',
  destinationCountry: '',
  salesOrderNo: '',
  currency: '',
  grandTotal: '',
  declaredAmount: '',
  invoiceNo: '',
  invoiceDate: '',
  weight: '',
  weightUOM: '',
  volWeight: '',
  volWeightUOM: '',
  length: '',
  width: '',
  height: '',
  dimensionUOM: '',
  packageType: ''
}

const hookFormProps = {
  initialValues: { ...DEFAULT_INITIAL_VALUES }, makeValidationSchema: ValidationSchema
}

const FormProperties = Object.freeze({
  hookFormProps: hookFormProps, Fields: Fields
})

export default FormProperties
