import React from 'react'
import { Card, CardContent } from '@material-ui/core'
import { Grid } from '@mui/material'
import StepperForm from '../components/add-edit/StepperForm'

function EditPage(props) {
  const { showNotification, match } = props
  return (<>
    <Card>
      <CardContent>
        <Grid container>
          <StepperForm showNotification={showNotification} actionType={'edit'}
                       deliveryOrderRefNo={match.params.deliveryOrderRefNo} />
        </Grid>
      </CardContent>
    </Card>
  </>)
}

export default EditPage
